import React, { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const Dictaphone = (props) => {
  const { transcript, resetTranscript, finalTranscript } = useSpeechRecognition()

  const [t, set_t] = useState(false);
  const [listening, setListening] = useState(props.listening);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null
  }

  
  if (props.listening == true && listening == false){

    try{

      SpeechRecognition.startListening({ continuous: true });
      setListening(true);
    }
    catch {
      console.log("");
    }
  }

  if (props.listening == false && listening == true){

    try{
      
      SpeechRecognition.abortListening()
      setListening(false);
    }
    catch {
      console.log("");
    }
  }

  if (t != finalTranscript.length){
  
    
    props.change(finalTranscript.substring(t, finalTranscript.length));
    set_t(finalTranscript.length)
  }




  

  

  return (
    <div>
      
    </div>
  )
}
export default Dictaphone