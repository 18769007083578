import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import { Redirect } from "react-router-dom";
 
export default class AutoLogout extends Component {
  constructor(props) {
    super(props)
    this.state = {
        logout: false
    }
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)

  }

  handleOnAction (event) {
    console.log('user did something', event)
  }
 
  handleOnActive (event) {
    console.log('user is active', event)
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  handleOnIdle (event) {
    console.log('user is idle for 30 mins')
    // console.log('last active', this.idleTimer.getLastActiveTime())
    this.setState({logout: true})
  }
 
  render() {
    return (
        <>
        <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1800000}
            onIdle={this.handleOnIdle}
            debounce={250}
        />
        {this.state.logout && <Redirect to='/logout' />}
        </>
    )
  }
 
}