import { CognitoUserPool } from "amazon-cognito-identity-js";

// PRODUCTION
const poolData = {
	UserPoolId: "us-east-2_WMP1mBeSc",
	ClientId: "3t6c0ijh7i1g4ngla0krrvh22b",
};

// STAGING
// const poolData = {
//   UserPoolId: 'us-east-2_R4MEv4RfJ',
//   ClientId: '377rbdag7af51rad93k0qrmt0l'
// };

export default new CognitoUserPool(poolData);
