import React, { Component } from "react";

import {
    Col,
    Card,
    CardBody,
    Media,
    CardHeader,
    Row,
    Container,
    Label,
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./Image.css";
import AuscultationWidget from "./AuscultationWidget";

class ImageRow1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fit: 36,
            or_b: true,
            d_b: true,
            t1_b: true,
            o1_b: true,
            o2_b: true,
            strep_b: true,
            flu_b: true,
            t1:
                "https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
                this.props.mrn +
                "&type=left_ear",
            t2:
                "https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
                this.props.mrn +
                "&type=right_ear",
            o1:
                "https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
                this.props.mrn +
                "&type=left_eye",
            o2:
                "https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
                this.props.mrn +
                "&type=right_eye",
            d:
                "https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
                this.props.mrn +
                "&type=skin",

            or:
                "https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
                this.props.mrn +
                "&type=throat",

            strep_image:
                "https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
                this.props.mrn +
                "&type=strep",
            flu_image:
                "https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
                this.props.mrn +
                "&type=flu",
        };
    }
    componentDidMount() {
        this.setState({ fit: this.state.t1.offsetHeight });
        console.log("updated!");
    }
    render() {
        const images = this.props.imgs;

        const labels = this.props.labels;

        return (
            <React.Fragment>
                {this.state.or_b === true && (
                    <Col
                        xl={2}
                        lg={3}
                        md={3}
                        sm={6}
                        className={
                            this.state.fit < 40
                                ? "prevent-split"
                                : "page-break o-break"
                        }
                    >
                        <Card style={{ borderRadius: 12 }}>
                            <Row>
                                <Col>
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Oropharynx</b>
                                        </span>
                                    </div>

                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onError={() => {
                                                        this.setState({
                                                            or_b: false,
                                                        });
                                                        this.props.error(
                                                            "or_b"
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        this.props.click("or");
                                                    }}
                                                    src={this.state.or}
                                                    style={{
                                                        width: "100%",
                                                        height: "19vh",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}
                {this.state.d_b === true && (
                    <Col
                        xl={2}
                        lg={3}
                        md={3}
                        sm={6}
                        className="page-break d-break"
                    >
                        <Card style={{ borderRadius: 12 }}>
                            <Row>
                                <Col>
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Dermatological</b>
                                        </span>
                                    </div>

                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onError={() => {
                                                        this.setState({
                                                            d_b: false,
                                                        });
                                                        this.props.error("d_b");
                                                    }}
                                                    onClick={() => {
                                                        this.props.click("d");
                                                    }}
                                                    src={this.state.d}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "19vh",
                                                        width: "100%",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}
                {this.state.t1_b === true && (
                    <Col xl={4} lg={6} md={6} sm={12}>
                        <Card style={{ borderRadius: 12 }}>
                            <Row>
                                <Col>
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Left Tymphanic Membrane</b>
                                        </span>
                                    </div>

                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onError={() => {
                                                        this.setState({
                                                            t1_b: false,
                                                        });
                                                        this.props.error(
                                                            "t1_b"
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        this.props.click("t1");
                                                    }}
                                                    src={this.state.t1}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "19vh",
                                                        width: "100%",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                                <Col className="border-left page-break">
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Right Tymphanic Membrane</b>
                                        </span>
                                    </div>

                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onClick={() => {
                                                        this.props.click("t2");
                                                    }}
                                                    src={this.state.t2}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "19vh",
                                                        width: "100%",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}

                {this.state.o1_b === true && this.state.o2_b === true && (
                    <Col xl={4} lg={6} md={6} sm={12} className="e-break">
                        <Card style={{ borderRadius: 12 }}>
                            <Row>
                                <Col>
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Left Eye</b>
                                        </span>
                                    </div>

                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onError={() => {
                                                        this.setState({
                                                            o1_b: false,
                                                        });
                                                        this.props.error(
                                                            "o1_b"
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        this.props.click("o1");
                                                    }}
                                                    src={this.state.o1}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "19vh",
                                                        width: "100%",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                                <Col className="border-left prevent-split">
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Right Eye</b>
                                        </span>
                                    </div>

                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onError={() => {
                                                        this.setState({
                                                            o2_b: false,
                                                        });
                                                        this.props.error(
                                                            "o2_b"
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        this.props.click("o2");
                                                    }}
                                                    src={this.state.o2}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "19vh",
                                                        width: "100%",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}

                {this.state.o1_b === true && this.state.o2_b == false && (
                    <Col xl={4} lg={6} md={6} sm={12}>
                        <Card style={{ borderRadius: 12 }}>
                            <Row>
                                <Col className="border-left prevent-split">
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Ocular Image</b>
                                        </span>
                                    </div>
                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onError={() => {
                                                        this.setState({
                                                            o1_b: false,
                                                        });
                                                        this.props.error(
                                                            "o1_b"
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        this.props.click("o1");
                                                    }}
                                                    src={this.state.o1}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "19vh",
                                                        width: "100%",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}
                {this.state.strep_b == true && (
                    <Col
                        xl={2}
                        lg={3}
                        md={3}
                        sm={6}
                        className="page-break s-break"
                    >
                        <Card style={{ borderRadius: 12 }}>
                            <Row>
                                <Col>
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Strep</b>
                                        </span>
                                    </div>

                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onError={() => {
                                                        this.setState({
                                                            strep_b: false,
                                                        });
                                                        this.props.error(
                                                            "strep_b"
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        this.props.click(
                                                            "strep"
                                                        );
                                                    }}
                                                    src={this.state.strep_image}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "19vh",
                                                        width: "100%",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}

                {this.state.flu_b == true && (
                    <Col xl={2} lg={3} md={3} sm={6} className="prevent-split">
                        <Card style={{ borderRadius: 12 }}>
                            <Row>
                                <Col>
                                    <div
                                        className="text-truncate"
                                        style={{
                                            paddingBottom: "1.2vh",
                                            paddingTop: "1.2vh",
                                            paddingLeft: "1vh",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#343a40",
                                                marginLeft: "1vh",
                                            }}
                                        >
                                            <b>Flu</b>
                                        </span>
                                    </div>

                                    <CardBody className="border-top py-3">
                                        <Media>
                                            <Media
                                                body
                                                className="overflow-hidden"
                                            >
                                                <img
                                                    onError={() => {
                                                        this.setState({
                                                            flu_b: false,
                                                        });
                                                        this.props.error(
                                                            "flu_b"
                                                        );
                                                    }}
                                                    onClick={() => {
                                                        this.props.click("flu");
                                                    }}
                                                    src={this.state.flu_image}
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "19vh",
                                                        width: "100%",
                                                        borderRadius: 5,
                                                    }}
                                                ></img>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}

                {/* <AuscultationWidget /> */}
            </React.Fragment>
        );
    }
}

export default ImageRow1;
