import React, { useCallback, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { 
  Alert,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  UncontrolledTooltip,
  Button,
  Modal,
  Label,
  Row,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import base_url from "../base_url"

import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');
 
const LinkBankAccount = () => {
      const data = {
        columns: [
          // {
          //   dataField: 'id',
          //   text: 'Name'
          // },
          {
            dataField: "date",
            text: "Visit Date",
            sort: "desc"
          },
          {
            dataField: "patientName",
            text: "Patient Name"
          },
          // {
          //   dataField: "orderId",
          //   text: "Order ID"
          // },
        
          // {
          //   dataField: "billingName",
          //   text: "Billing Name"
          // },
          {
            dataField: "time",
            text: "Visit Time"
          },
          // {
          //   dataField: "status",
          //   text: "Payment Status"
          // },
        ],
        rows: [
          {
            id : 1,
            orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1572</Link>,
            date: "04 Apr, 2020",
            patientName: "Walter Brown",
            time: "3:30 PM",
            status : <div className="badge badge-soft-success font-size-12">Paid</div>,
          },
          {
          id : 2,
            orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1571</Link> ,
            date: "03 Apr, 2020",
            patientName: "Jimmy Barker",
            time: "3:30 PM",
            status : <div className="badge badge-soft-warning font-size-12">unpaid</div>,
          },
          {
            id : 3,
            orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1570</Link>,
            date: "03 Apr, 2020",
            patientName: "Donald Bailey",
            time: "3:30 PM",
            status : <div className="badge badge-soft-success font-size-12">Paid</div>,
          },
          {
            id : 4,
            orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1569</Link>,
            date: "02 Apr, 2020",
            patientName: "Paul Jones",
            time: "3:30 PM",
            status : <div className="badge badge-soft-success font-size-12">Paid</div>,
          },
        ]

    };

    const [linkToken, setLinkToken] = React.useState("");
    const [modalVisible, setModalVisible] = React.useState(false)
    const [modalError, setModalError] = React.useState("")
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.log('[error]', error);
        setModalError(error.message)
      } else {
        console.log('[PaymentMethod]', paymentMethod);
      }
    };

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList :
        [ {
          text: '10', value: 10,
        }, {
          text: '25', value: 25
        }, {
          text: '50', value: 50
        }, {
          text: 'All Time', value: data.rows.length
        } ]

      };

      const selectRow = {
      mode: 'checkbox',
      clickToSelect: true
    };

    const onSuccess = useCallback((token, metadata) => {
      console.log("Token", token)
      console.log("Metadata", metadata.accounts[0].id)

      // const temp_url = "http://127.0.0.1:5000"
      
      let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }

      if (base_url == "http://127.0.0.1:5000" || base_url == "http://3.135.192.56:8080"){
          headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }

      }
      else {
          headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") }
      }

      fetch(base_url + '/link-account', {
        method: 'post', headers: headers,
        body: JSON.stringify({public_token: token, account_id: metadata.accounts[0].id})
      }).then((Response) => Response.json()).then((Result) => {
          console.log(Result)
      }).catch((e) => {
        console.log(e);
      });

    }, []);
   
    const config = {
      token: linkToken,
      onSuccess,
      // ...
    };

    const { open, ready, error } = usePlaidLink(config);

    useEffect(() => {

      // const temp_url = "http://127.0.0.1:5000"
      
      let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
      if (base_url == "http://127.0.0.1:5000" || base_url == "http://3.135.192.56:8080"){
          headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }

      }
      else {
          headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") }
      }

      fetch(base_url + '/get-link-token', {
          method: 'post', headers: headers,
      }).then((Response) => Response.json()).then((Result) => {
          console.log(Result)
          setLinkToken(Result.link_token)
      });

      // console.log(window.sessionStorage.getItem("item_id"))
      // console.log(window.sessionStorage.getItem("sub_id"))

    }, [])

    return (
      <React.Fragment>
          <Col lg={12}>
              <Card>
                  <CardBody>
                      <Dropdown className="float-right">
                        <div className="text-center mt-0">
                          {(window.sessionStorage.getItem("item_id") === null || window.sessionStorage.getItem("item_id") === "" || window.sessionStorage.getItem("sub_id") === null || window.sessionStorage.getItem("sub_id") === "") ? 
                            (<Button style={{marginRight: 15}} color="primary" className="mb-4" onClick={() => open()} disabled={true}>Link Bank Account</Button>) : 
                            <Button style={{marginRight: 15}} color="primary" className="mb-4" disabled={true}>Bank Account Linked</Button>}

                          <Button disabled={true} style={{marginRight: 15}} color="primary" className="mb-4" onClick={() => setModalVisible(true)}>Link Credit Card</Button>
                          <Button disabled={true} style={{marginRight: 15}} color="primary" className="mb-4" onClick={() => console.log("bruh")}>Manual Invoicing</Button>
                          {/* <Button color="primary" className="mb-4" onClick={() => console.log("bruh")} disabled={!ready}>Link Credit Card</Button> */}
                        </div>
                        
                      </Dropdown>

                      <h4 className="card-title mb-4">Visits</h4>

                      {/* <BootstrapTable
                        keyField='id'
                        data={ data.rows }
                        columns={ data.columns }
                        pagination={ paginationFactory(options) }
                      /> */}
                  </CardBody>
              </Card>

              <Modal
                isOpen={modalVisible}
                // toggle={this.tog_static}
                backdrop="static"
                centered
                size="lg"
              >
                <ModalHeader toggle={() => {setModalVisible(false); setModalError("")}}>
                    Link Credit Card
                </ModalHeader>
                <ModalBody>
                    <AvForm
                        style={{ marginLeft: "3.5vh" }}
                    >
                        <Row>
                            <Col lg={12}>
                                <CardElement
                                  options={{
                                    style: {
                                      base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                          color: '#aab7c4',
                                        },
                                      },
                                      invalid: {
                                        color: '#9e2146',
                                      },
                                    },
                                  }}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <br />
                        </Row>
                        <ModalFooter>
                            {modalError !== "" && <Alert color="danger">{modalError}</Alert>}
                            <Button
                              color="primary"
                              type="submit"
                              onClick={handleSubmit}
                              style={{marginLeft: 10}}
                            >
                                Link
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
              </Modal>
          </Col>
      </React.Fragment>
    )
 
}

export default LinkBankAccount;