import React, { Component } from 'react';
import { Col, Card, CardBody, Media, Row } from "reactstrap";


class AuscultationWidget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            play: false,
            play1: false,
            play2: false,
            play6: false,
            size: 10,
            size1: 10,
            size2: 10,
            size3: 10,
            size4: 10,
            size5: 10,
            size6: 12,
            currFront: ['Right Front Lung', 'Left Front Lung'],
            currBack: ['Left Upper Back Lung', 'Right Upper Back Lung', 'Left Lower Back Lung', 'Right Lower Back Lung'],
            duration: 0,
            duration1: 0,
            duration2: 0,
            duration6: 0,
            showPlay6: '',
            showPause6: 'none',
            showPlay5: '',
            showPause5: 'none',
            showPlay4: '',
            showPause4: 'none',
            showPlay3: '',
            showPause3: 'none',
            showPlay2: '',
            showPause2: 'none',
            showPlay1: '',
            showPause1: 'none',
            showPlay: '',
            showPause: 'none',
            curr2: 0,
            curr1: 0,
            playSize: 20,
            playTop: 0,
            ctrlSize: 16,
            dot: 10,
            dotB: 13,
            dotP: 12,
            dotP6: 12,
            mbleft: 0,
            timeT: 10,
            props1: [],
            props2: [props.src, props.src2, props.src3, props.src4, props.src5, props.src6],
            playing: true,
            pulmoleft: "30%",
            dotColor: ['grey'],
            dotColor1: ['grey'],
            dotColor2: ['grey'],
            dotColor3: ['grey'],
            dotColor4: ['grey'],
            dotColor5: ['grey'],
            dotColor6: ['grey'],
        }

        this.togglePlay2 = this.togglePlay2.bind(this);
        this.togglePlay6 = this.togglePlay6.bind(this);
        this.togglePause2 = this.togglePause2.bind(this);
        this.togglePause6 = this.togglePause6.bind(this);
        this.handleRangeChange2 = this.handleRangeChange2.bind(this);
        this.next2 = this.next2.bind(this);
        this.back2 = this.back2.bind(this);
        this.audio2 = [new Audio(props.src), new Audio(props.src2), new Audio(props.src3), new Audio(props.src4), new Audio(props.src5), new Audio(props.src6)]
        this.audio6 = new Audio(props.src7)

    }
    resize = () => {
        if ((window.innerWidth < 1233 && window.innerWidth > 765) || window.innerWidth < 350) {
            this.setState({ pulmoleft: "40%", playSize: 12, playTop: -7, ctrlSize: 8, size: 5.5, size1: 5.5, size2: 5.5, size3: 5.5, size4: 5.5, size5: 6.5, size6: 5.5, dot: 5.5, dotB: 8, dotP: 6.5, mbleft: -7, timeT: 7, })
        } else if (window.innerWidth < 1450 && window.innerWidth > 1232) {
            this.setState({ pulmoleft: "35%", playSize: 15, playTop: -6, ctrlSize: 11, size: 6.5, size1: 6.5, size2: 6.5, size3: 6.5, size4: 6.5, size5: 7.5, size6: 6.5, dot: 6.5, dotB: 8.5, dotP: 7.5, mbleft: 0, timeT: 7.5, })
        } else {
            this.setState({ pulmoleft: "30%", playSize: 20, playTop: 0, ctrlSize: 16, size: 10, size1: 10, size2: 10, size3: 10, size4: 10, size5: 10, size6: 12, dot: 10, dotB: 13, dotP: 12, mbleft: 0, timeT: 10, })
        }
    }
    componentDidMount() {

        this.currentTimeInterval = null;
        this.slider6.value = this.state.duration6;
        window.addEventListener('resize', this.resize)
        this.audio6.onloadedmetadata = function() {
            this.setState({ default: this.audio6.duration });
        }.bind(this);
        this.setState({ size: this.state.dotP, dotColor: 'blue', dotColor6: 'blue'})
        this.audio6.onplay = () => {
            this.currentTimeInterval = setInterval(() => {
                this.setState({duration6: this.audio6.currentTime});
                this.slider6.value = this.audio6.currentTime;
            }, 0.1);

        };

        this.audio6.onpause = () => {
            clearInterval(this.currentTimeInterval);
            if (this.audio6.duration === this.audio6.currentTime) { this.togglePause6() }

        };
        this.slider6.onchange = (e) => {
            clearInterval(this.currentTimeInterval);
            this.audio6.currentTime = e.target.value;
        };


        this.slider2.value = this.state.duration2;

        this.audio2[this.state.curr2].onloadedmetadata = function() {
            this.setState({ default: this.audio2[this.state.curr2].duration });
        }.bind(this);

        this.audio2[this.state.curr2].onplay = () => {
            this.currentTimeInterval = setInterval(() => {
                this.state.duration2 = this.audio2[this.state.curr2].currentTime;
                this.slider2.value = this.audio2[this.state.curr2].currentTime;
            }, 0.1);

        };

        this.audio2[this.state.curr2].onpause = () => {
            clearInterval(this.currentTimeInterval);
            if (this.audio2[this.state.curr2].duration === this.audio2[this.state.curr2].currentTime) { this.togglePause2() }

        };
        this.slider2.onchange = (e) => {
            clearInterval(this.currentTimeInterval);
            this.audio2[this.state.curr2].currentTime = e.target.value;
        };
    }


    togglePlay2 = () => {
        this.setState({ play2: !this.state.play2, showPlay2: 'none', showPause2: '' }, () => {
            this.audio2[this.state.curr2].play();
        });

        this.state.curr2 === 0 ? this.setState({ size: this.state.dotB, dotColor: 'blue' }) : this.state.curr2 === 1 ? this.setState({ size1: this.state.dotB, dotColor1: 'blue' }) : this.state.curr2 === 2 ? this.setState({ size2: this.state.dotB, dotColor2: 'blue' }) : this.state.curr2 === 3 ? this.setState({ size3: this.state.dotB, dotColor3: 'blue' }) : this.state.curr2 === 4 ? this.setState({ size4: this.state.dotB, dotColor4: 'blue' }) : this.setState({ size5: this.state.dotB, dotColor5: 'blue,' })
        this.currentTimeInterval = setInterval(() => {
            this.slider2.value = this.audio2[this.state.curr2].currentTime;
            if (this.audio2[this.state.curr2].currentTime === this.audio2[this.state.curr2].duration) {
                this.togglePause2();
            }
        }, 0.2);


    }

    togglePlay6 = () => {

        this.setState({ play6: !this.state.play6, showPlay6: 'none', showPause6: '', size6: this.state.dotB }, () => {
            this.audio6.play();
        });
    }

    togglePause2 = () => {

        this.setState({ play2: !this.state.play2, showPlay2: '', showPause2: 'none' }, () => {
            this.audio2[this.state.curr2].pause();
        });
        this.state.curr2 === 0 ? this.setState({ size: this.state.dotP }) : this.state.curr2 === 1 ? this.setState({ size1: this.state.dotP }) : this.state.curr2 === 2 ? this.setState({ size2: this.state.dotP }) : this.state.curr2 === 3 ? this.setState({ size3: this.state.dotP }) : this.state.curr2 === 4 ? this.setState({ size4: this.state.dotP }) : this.setState({ size5: this.state.dotP })
    }

    togglePause6 = () => {  

        this.setState({ play6: !this.state.play6, showPlay6: '', showPause6: 'none', size6: 12 }, () => {
            this.audio6.pause();
        });
    }

    // fastForward2 = () => {
    //     this.audio2[this.state.curr2].currentTime = this.audio2[this.state.curr2].currentTime + 2;
    //     this.setState({ play2: true, showPlay2: 'none', showPause2: '' }, () => {
    //         this.state.play2 ? this.audio2[this.state.curr2].play() : this.audio2[this.state.curr2].pause();
    //     });
    // }
    // goBackward2 = () => {
    //     this.audio2[this.state.curr2].currentTime = this.audio2[this.state.curr2].currentTime - 2;
    //     this.setState({ play2: true, showPlay2: 'none', showPause2: '' }, () => {
    //         this.state.play2 ? this.audio2[this.state.curr2].play() : this.audio2[this.state.curr2].pause();
    //     });
    // }

    // fastForward6 = () => {
    //     this.audio6.currentTime = this.audio6.currentTime + 1;
    //     this.setState({ play6: true, showPlay6: 'none', showPause6: '', size6: this.state.dotB }, () => {
    //         this.state.play6 ? this.audio6.play() : this.audio6.pause();
    //     });
    // }
    // goBackward6 = () => {
    //     this.audio6.currentTime = this.audio6.currentTime - 1;
    //     this.setState({ play6: true, showPlay6: 'none', showPause6: '', size6: this.state.dotB }, () => {
    //         this.state.play6 ? this.audio6.play() : this.audio6.pause();
    //     });
    // }

    handleRangeChange6 = (e) => {
        this.setState({ duration6: e.target.value });
        this.audio6.currentTime = e.target.value;
        this.togglePause6();
    }


    handleRangeChange2 = (e) => {
        this.setState({ duration2: e.target.value });
        this.audio2[this.state.curr2].currentTime = e.target.value;
        this.togglePause2();
    }

    next2 = () => {
        this.audio2[this.state.curr2].pause();
        this.togglePause2();
        this.state.curr2 === 0 && this.state.props2[this.state.curr2 + 1] ?
            (this.setState({ curr2: this.state.curr2 + 1, size1: this.state.dotP, size: this.state.dot, dotColor: 'grey', dotColor1: 'blue' })) :
            this.state.curr2 === 1 && this.state.props2[this.state.curr2 + 1] ?
            (this.setState({ curr2: this.state.curr2 + 1, size2: this.state.dotP, size1: this.state.dot, dotColor1: 'grey', dotColor2: 'blue' })) :
            this.state.curr2 === 2 && this.state.props2[this.state.curr2 + 1] ?
            (this.setState({ curr2: this.state.curr2 + 1, size3: this.state.dotP, size2: this.state.dot, dotColor2: 'grey', dotColor3: 'blue' })) :
            this.state.curr2 === 3 && this.state.props2[this.state.curr2 + 1] ?
            (this.setState({ curr2: this.state.curr2 + 1, size4: this.state.dotP, size3: this.state.dot, dotColor3: 'grey', dotColor4: 'blue' })) :
            this.state.curr2 === 4 && this.state.props2[this.state.curr2 + 1] ?
            (this.setState({ curr2: this.state.curr2 + 1, size5: this.state.dotP, size4: this.state.dot, dotColor4: 'grey', dotColor5: 'blue' })) :
            this.state.curr2 === 5 ?
            (this.setState({ curr2: 0, size: this.state.dotP, size5: this.state.dot, dotColor5: 'grey', dotColor: 'blue' })) : (this.setState({ curr2: 0, size: this.state.dotP, size5: this.state.dot, dotColor5: 'grey', dotColor: 'blue' }))

    }
    back2 = () => {
        this.audio2[this.state.curr2].pause();
        this.togglePause2();
        this.state.curr2 === 0 && this.state.props2[5] ?
            (this.setState({ curr2: 5, size5: this.state.dotP, size: this.state.dot, dotColor5: 'blue', dotColor: 'grey' })) :
            this.state.curr2 === 1 && this.state.props2[this.state.curr2 - 1] ?
            (this.setState({ curr2: this.state.curr2 - 1, size: this.state.dotP, size1: this.state.dot, dotColor: 'blue', dotColor1: 'grey' })) :
            this.state.curr2 === 2 && this.state.props2[this.state.curr2 - 1] ?
            (this.setState({ curr2: this.state.curr2 - 1, size1: this.state.dotP, size2: this.state.dot, dotColor1: 'blue', dotColor2: 'grey' })) :
            this.state.curr2 === 3 && this.state.props2[this.state.curr2 - 1] ?
            (this.setState({ curr2: this.state.curr2 - 1, size2: this.state.dotP, size3: this.state.dot, dotColor2: 'blue', dotColor3: 'grey' })) :
            this.state.curr2 === 4 && this.state.props2[this.state.curr2 - 1] ?
            (this.setState({ curr2: this.state.curr2 - 1, size3: this.state.dotP, size4: this.state.dot, dotColor3: 'blue', dotColor4: 'grey' })) :
            this.state.curr2 === 5 && this.state.props2[this.state.curr2 - 1] ?
            (this.setState({ curr2: this.state.curr2 - 1, size4: this.state.dotP, size5: this.state.dot, dotColor4: 'blue', dotColor5: 'grey' })) : (this.setState({ curr2: this.state.curr2 - 1, size4: this.state.dotP, size5: this.state.dot, dotColor4: 'blue', dotColor5: 'grey' }))

    }

    render() {
        const front = window.location.origin + "/front.png";
        const back = window.location.origin + "/back.png";

        return (
            <React.Fragment>
                <Col xl={4} lg={8} md={8} >
                    <Card style={{ borderRadius: 16 }}>
                        <Row>

                            <Col>
                                <div className="text-truncate" style={{ paddingBottom: "1.2vh", paddingTop: "1.2vh", paddingLeft: "1vh" }}>

                                    <span style={{ color: "#343a40", marginLeft: "1vh" }}><b>Front Pulmonary Fields</b></span>
                                </div>
                                <CardBody className="border-top py-3">
                                    <Media style={{display: 'block'}}>
                                        <Media body className="overflow-hidden">
                                            {/* <img src={front} style={{ width: "80%" }}></img> */}
                                               {/* <div style={{ backgroundImage: "url(" + front + ")", width: "100%", contain: '' }}></div> */}
                                            <div style={{ width: "100%"}}>
                                                <img src={front} style={{ width: "100%", zIndex: 0 }}></img>
                                                 <div style={{ width: this.state.size, height: this.state.size, borderRadius: 100, backgroundColor: this.state.dotColor, zIndex: 9, position: 'absolute', top: this.state.pulmoleft, left: '35%', display: this.state.props2[0]? '':'none'  }} />
                                            <div style={{ width: this.state.size1, height: this.state.size1, borderRadius: 100, backgroundColor: this.state.dotColor1, zIndex: 9, position: 'absolute', top: this.state.pulmoleft, left: '60%', display: this.state.props2[1]? '':'none'  }} />
                                            </div>
                                        </Media>
                                       
                                    </Media>
                                </CardBody>

                            </Col>
                            <Col className="border-left">
                                <div className="text-truncate" style={{ paddingBottom: "1.2vh", paddingTop: "1.2vh", paddingLeft: "1vh" }}>

                                    <span style={{ color: "#343a40", marginLeft: "1vh" }}><b>Back Pulmonary Fields</b></span>
                                </div>
                                <CardBody className="border-top py-3">
                                    <Media style={{display: 'block', marginLeft: this.state.mbleft}}>
                                        <Media body className="overflow-hidden">
                                                                <div style={{ width: "100%"}}>
                                            <img src={back} style={{ width: "100%", zIndex: 0 }}></img>
                                            <div style={{ width: this.state.size2, height: this.state.size2, borderRadius: 70, backgroundColor: this.state.dotColor2, zIndex: 9, position: 'absolute', top: '66%', left: '40%', display: this.state.props2[2]? '':'none'  }} />
                                            <div style={{ width: this.state.size3, height: this.state.size3, borderRadius: 70, backgroundColor: this.state.dotColor3, zIndex: 9, position: 'absolute', top: '66%', left: '56%', display: this.state.props2[3]? '':'none'  }} />

                                            <div style={{ width: this.state.size4, height: this.state.size4, borderRadius: 70, backgroundColor: this.state.dotColor4, zIndex: 9, position: 'absolute', top: '77%', left: '30%', display: this.state.props2[4]? '':'none'  }} />
                                            <div style={{ width: this.state.size5, height: this.state.size5, borderRadius: 70, backgroundColor: this.state.dotColor5, zIndex: 9, position: 'absolute', top: '77%', left: '65%', display: this.state.props2[5]? '':'none'  }} />
                        
                                            </div>
                                        </Media>
                                       
                                    </Media>
                                </CardBody>



                            </Col>


                        </Row>
                        <Col>

                                        <div style={{ width: "50%", position: 'relative', top: -15, left: "25%"}}>
                                        <div className="p-3" style={{paddingBottom : '0px !important'}}>
                                              <span className="float-left text-primary" style={{marginTop: '', fontSize: this.state.timeT, position: 'relative', top: 6}}>0:00</span>
                                          <span className="float-right" style={{marginTop: '', fontSize: this.state.timeT, position: 'relative', top: 6}}>{(this.audio2[this.state.curr2].duration/60) ? ((this.audio2[this.state.curr2].duration/60).toString().slice(0,1) + ":"+(Math.floor(this.audio2[this.state.curr2].duration % 60) / 100).toString().slice(2,4)) : "0:00"}</span>
                                          <p><input ref={(slider) => { this.slider2 = slider }}
                                                type="range"
                                                name="points"
                                                step="1"
                                                min="0" 
                                                id="sliderId"
                                                className="inputRange"
                                                value = {this.state.duration2}
                                                max={Math.ceil(this.audio2[this.state.curr2].duration)-1}
                                                style={{width : '100%', height: 3}}
                                                onChange={this.handleRangeChange2} /> </p>
                                        </div>

                                        <div className="pl-3 pr-3" style={{textAlign:'center', marginTop: -20, width: '100%', minWidth: 92}}>
                                         <i className="float-left pr-1 fa fa-step-backward text-primary " onClick={this.back2} style={{fontSize: this.state.ctrlSize, cursor: 'pointer'}}></i>
                                         <i className="fa fa-play-circle fa-lg text-primary" onClick={this.togglePlay2} style={{display: this.state.showPlay2, position: 'relative', fontSize: this.state.playSize, top: this.state.playTop, cursor: 'pointer'}}></i>
                                         <i className="fa fa-stop-circle fa-lg text-primary" onClick={this.togglePause2} style={{display: this.state.showPause2, position: 'relative', fontSize: this.state.playSize, top: this.state.playTop, cursor: 'pointer'}}></i>
                                         <i className="float-right pl-1 fa fa-step-forward text-primary " onClick={this.next2} style={{fontSize: this.state.ctrlSize, cursor: 'pointer'}}></i>
                                        </div> 
                                        </div>  
                        </Col>
                    </Card>

                </Col>

                <Col xl={2} lg={4} md={4} >
                    <Card style={{ borderRadius: 16 }}>
                        <Row>

                            <Col>
                                <div className="text-truncate" style={{ paddingBottom: "1.2vh", paddingTop: "1.2vh", paddingLeft: "1vh" }}>

                                    <span style={{ color: "#343a40", marginLeft: "1vh" }}><b>Cardiac Fields</b></span>
                                </div>
                                <CardBody className="border-top py-3">
                                    <Media style={{display:'block'}}>
                                        <Media body className="overflow-hidden">
                                        <div  style={{width: "100%"}}>
                                            <img src={front} style={{ width: "100%", zIndex: 0 }}></img>
                                            <div style={{ width: this.state.size6, height: this.state.size6, borderRadius: 700, backgroundColor: this.state.dotColor6, zIndex: 9, position: 'absolute', top: '22%', left: '60%', display: this.props.src7 ? '':'none'  }} />
                                        </div>    
                                        </Media>
                                        <div>
                                        <div className="p-3" style={{paddingBottom : '0px !important'}}>
                                          <span className="float-left text-primary" style={{marginTop: '', fontSize: this.state.timeT, position: 'relative', top: 6}}>0:00</span>
                                          <span className="float-right" style={{marginTop: '', fontSize: this.state.timeT, position: 'relative', top: 6}}> {(this.audio6.duration/60)? (this.audio6.duration/60).toString().slice(0,1) + ":"+(Math.floor(this.audio6.duration % 60) / 100).toString().slice(2,4) : "0:00"}</span>
                                          <p><input ref={(slider6) => { this.slider6 = slider6 }}
                                                type="range"
                                                name="points"
                                                step="1"
                                                min="0" 
                                                id="sliderId"
                                                className="inputRange"
                                                value = {this.state.duration6}
                                                max={Math.ceil(this.audio6.duration)}
                                                style={{width : '100%', height: 3}}
                                                onChange={this.handleRangeChange6} /> </p>
                                        </div>

                                        <div className="pl-3 pr-3" style={{textAlign:'center', marginTop: -20, width: '100%', minWidth: 92}}>
                                         {/* <i className="float-left fa fa-undo text-primary " onClick={this.goBackward6} style={{fontSize: this.state.ctrlSize, cursor: 'pointer'}}></i> */}
                                         <i className="fa fa-play-circle fa-lg text-primary" onClick={this.togglePlay6} style={{display: this.state.showPlay6, position: 'relative', fontSize: this.state.playSize, top: this.state.playTop, cursor: 'pointer'}}></i>
                                         <i className="fa fa-stop-circle fa-lg text-primary" onClick={this.togglePause6} style={{display: this.state.showPause6, position: 'relative', fontSize: this.state.playSize, top: this.state.playTop, cursor: 'pointer'}}></i>
                                         {/* <i className="float-right fa fa-redo text-primary " onClick={this.fastForward6} style={{fontSize: this.state.ctrlSize, cursor: 'pointer'}}></i> */}
                                        </div> 
                                        </div>

                                    </Media>
                                </CardBody>



                            </Col>


                        </Row>
                    </Card>



                </Col>




            </React.Fragment>
        );
    }
}

export default AuscultationWidget;