import React, { Component } from "react";
import { Button, CardTitle, CardText, Alert, Row, Col, Card, CardBody, CardHeader, TabContent, TabPane, NavItem, NavLink, Label, Input, Form, FormGroup, Progress, Container } from "reactstrap";

import CheckoutForm from './Payment';
import { AvForm, AvField } from "availity-reactstrap-validation";
import PlacesAutocomplete from "react-places-autocomplete";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "./calendar.css";
import base_url from "../base_url";


import classnames from 'classnames';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import logo from "../../assets/images/logo-full.png";

import {
    Elements,
    CardElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Lottie from "react-lottie";
import animationData from "./loading-animation.json";

// import ReactGA from 'react-ga';
// ReactGA.initialize("G-VRN19QMKQS");

const stripePromise = loadStripe('pk_live_51HDCXXDM8fNsPiHlrsVEtVgtWkXDpDSlb2CF9tL3AqzLmL2a5D3yR6r57fzF2PQtihjZnZAUtHIaLrQcf1EqYJSN00uFSxqtxP');
// const stripePromise = loadStripe('pk_test_51HDCXXDM8fNsPiHl5YHgUPaWxr2xbCCZDzbqCxlDhfdnUojSsCiXj8vERAIlXk87yvOzOrPwkEGgbHvRfu54Xe6t00mkSmAAuH');

const raceOptions = [
	{
		label: "Race/Ethnicity",
		options: [
			{ label: "White", value: "White" },
			{ label: "Black or African American", value: "Black or African American" },
            { label: "American Indian or Alaska Native", value: "American Indian or Alaska Native" },
            { label: "Asian", value: "Asian" },
            { label: "Native Hawaiian or Other Pacific Islander", value: "Native Hawaiian or Other Pacific Islander" },
            { label: "Hispanic or Latino", value: "Hispanic or Latino" },
		]
	},
];

const symptomOptions = [
	{
		label: "Symptoms",
		options: [
			{ label: "Contact with and (suspected) exposure", value: "Contact with and (suspected) exposure" },
			{ label: "Fever or chills", value: "Fever or chills" },
            { label: "Cough", value: "Cough" },
            { label: "Shortness of breath / difficulty breathing", value: "Shortness of breath / difficulty breathing" },
            { label: "Fatigue", value: "Fatigue" },
            { label: "Muscle / body aches", value: "Muscle / body aches" },
            { label: "Loss of taste or smell", value: "Loss of taste or smell" },
            { label: "Sore throat", value: "Sore throat" },
            { label: "Congestion or runny nose", value: "Congestion or runny nose" },
            { label: "Nausea or vomiting", value: "Nausea or vomiting" },
		]
	},
];

class FormWizard extends Component {
    constructor(props) {
        super(props);
        this.state = {

            breadcrumbItems: [],
            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 25,

            price: 0,
            selected_1: false,
            selected_2: false,

            patient_name: "",
            phone: "",
            address: "",
            apartment_number: "",
            receipt_email: "",
            consumer_notes: "",
            age: "",
            sex: "",
            sex_string: "",
            test_selected: "",
            selectedRace: null,
            selectedSymptom: null,
            customchk: false,

            name_missing: false,
            address_missing: false,
            phone_missing: false,
            time_missing: false,
            receipt_missing: false,
            age_missing: false,
            sex_missing: false,
            race_missing: false,

            current_date: "",
            current_month: "",
            current_year: "",
            visit_date: 0,
            visit_month: 0,
            visit_year: 0,
            date_string: "",
            time_string: "",
            visit_time: "",

            clientSecret:"",

            nurse_time: "",
            nurse_time_range: "",
            nurse_email: "",
            default_date: new Date(),
            nurseTimeDisabled: true,
            selectedGroup: null,
            optionGroup: [],
            isSubmit: false,

            errorMessage: false,
            card_complete: false,
            checkout_error: false,

            success: false,
            loading_animation: false,
            mrn: "",
            payment_id: "",

            hightlight_tos: false,

        };
        this.toggleTabProgress.bind(this);
        this.handleChange.bind(this);
        this.handleSelect.bind(this);
        this.handleDefault.bind(this);
        this.handleDateClick.bind(this);
        this.handleSelectGroup.bind(this);
        this.updateNurseTimes.bind(this);
        this.cardCompleted.bind(this);
        this.showSuccess.bind(this);
        this.loadingAnimation.bind(this);
        this.checkoutError.bind(this);
        this.addPatient.bind(this);
        this.handleSelectRace.bind(this);
        this.handleSelectSymptom.bind(this);
    }


    componentDidMount() {



        var today = new Date();
        var dd = parseInt(String(today.getDate()).padStart(2, "0"));
        var mm = parseInt(String(today.getMonth() + 1).padStart(2, "0"));
        var yyyy = parseInt(today.getFullYear());
        // var hours = today.getHours().toString();
        // var minutes = today.getMinutes().toString();

        let temp_string = today = mm + "/" + dd + "/" + yyyy;
        this.setState({ visit_date: dd, visit_month: mm, visit_year: yyyy, date_string: temp_string });
        this.setState({ current_date: dd, current_month: mm, current_year: yyyy })
    }

    toggleTabProgress(tab) {
        if (tab === 3 && (this.state.patient_name === "" || this.state.address === "" || this.state.nurse_time === "" || this.state.phone === "" || this.state.sex === "" || this.state.age === "" || (this.state.selectedRace === null) )) {
            // this.setState({ errorMessage: true })
            if (this.state.patient_name === ""){
                this.setState({name_missing: true})
            }
            if (this.state.address === ""){
                this.setState({address_missing: true})
            }
            if (this.state.nurse_time === ""){
                this.setState({time_missing: true})
            }
            if (this.state.phone === ""){
                this.setState({phone_missing: true})
            }
            if (this.state.sex === ""){
                this.setState({sex_missing: true})
            }
            if (this.state.age === ""){
                this.setState({age_missing: true})
            }
            if (this.state.selectedRace === null){
                this.setState({race_missing: true})
            }
        }
        else if (tab === 4 && (this.state.receipt_email === "" || this.state.card_complete === false)) {
            // this.setState({ errorMessage: true })
            if (this.state.receipt_email === ""){
                this.setState({receipt_missing: true})
            }
        }
        else {

            if (this.state.activeTabProgress !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTabProgress: tab,
                        errorMessage: false,
                        checkout_error: false,
                    });

                    if (tab === 1) { this.setState({ progressValue: 25 }) }
                    if (tab === 2) { this.setState({ progressValue: 50 }) }
                    if (tab === 3) {this.setState({ progressValue: 75 });}
                    if (tab === 4) {this.setState({ progressValue: 100 });}
                }
                else if (tab === 5) {
                    if (this.state.customchk === true){
                        
                        this.setState({ loading_animation: true });

                        let headers = {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        };

                        fetch(base_url + "/create-payment-intent", {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify({email: this.state.receipt_email, items: [{ id: "xl-tshirt" }]})
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(data => {
                            console.log(data)
                            this.setState({payment_id: data.id})
                            this.setState({clientSecret: data.clientSecret});
                            this.setState({ isSubmit: true });
                            this.setState({ hightlight_tos: false });
                        });
                    }
                    else {
                        this.setState({hightlight_tos: true})
                    }
                }
            }
        }


    }

    handleChange = (address) => {
        this.setState({address_missing: false})
        this.setState({ nurseTimeDisabled: true });
        this.setState({ selectedGroup: null });
        this.setState({ address });
    };

    updateNurseTimes = (address, visit_date, visit_month, visit_year) => {
        this.setState({ selectedGroup: null });
        this.setState({ address });

        var today = new Date();
        var dd = parseInt(String(today.getDate()).padStart(2, "0"));
        var mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
        var yyyy = parseInt(today.getFullYear());
        var h = today.getHours().toString();
        var m = today.getMinutes().toString();

        if (m.length === 1) {
            m = "0" + m;
        }

        var curr_time = parseInt(h + m);

        if (
            visit_month !== mm ||
            visit_date !== dd ||
            visit_year !== yyyy
        ) {
            curr_time = 0;
        }

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        fetch(base_url + "/consumer-get-valid-times", {
            method: "post",
            headers: headers,

            body: JSON.stringify({
                address: address,
                current_date: visit_date,
                current_month: visit_month,
                current_year: visit_year,
                current_time: curr_time,
            }),
        })
            .then((Response) => Response.json())
            .then((Result) => {
                console.log(Result);
                this.setState({ optionGroup: Result.data });
                this.setState({ nurseTimeDisabled: false });
            });
    };

    handleSelect = (address) => {
        this.setState({ selectedGroup: null });
        this.setState({ address });

        var today = new Date();
        var dd = parseInt(String(today.getDate()).padStart(2, "0"));
        var mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
        var yyyy = parseInt(today.getFullYear());
        var h = today.getHours().toString();
        var m = today.getMinutes().toString();

        if (m.length === 1) {
            m = "0" + m;
        }

        var curr_time = parseInt(h + m);

        if (
            this.state.visit_month !== mm ||
            this.state.visit_date !== dd ||
            this.state.visit_year !== yyyy
        ) {
            curr_time = 0;
        }

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        fetch(base_url + "/consumer-get-valid-times", {
            method: "post",
            headers: headers,

            body: JSON.stringify({
                address: address,
                current_date: this.state.visit_date,
                current_month: this.state.visit_month,
                current_year: this.state.visit_year,
                current_time: curr_time,
            }),
        })
            .then((Response) => Response.json())
            .then((Result) => {
                this.setState({ optionGroup: Result.data });
                this.setState({ nurseTimeDisabled: false });
            });
    };

    handleDateClick = (date) => {
        if (date.date < new Date()) {
            this.setState({ default_date: new Date() });

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + "/" + dd + "/" + yyyy;
            this.setState({ date_string: today });
        } else {
            this.setState({ default_date: date.date });

            var dd = String(date.date.getDate()).padStart(2, "0");
            var mm = String(date.date.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = date.date.getFullYear();

            var today = mm + "/" + dd + "/" + yyyy;
            this.setState({ date_string: today });
        }

        this.setState({ visit_date: date.date.getDate() });
        this.setState({ visit_month: date.date.getMonth() + 1 });
        this.setState({ visit_year: date.date.getFullYear() });

        this.updateNurseTimes(this.state.address, date.date.getDate(), date.date.getMonth() + 1, date.date.getFullYear())
    };

    handleDefault = (date) => {
        this.setState({ nurseTimeDisabled: true });
        this.setState({ selectedGroup: null });
        let dateTime = { date: date };
        this.handleDateClick(dateTime);
    };

    handleSelectGroup = (selectedGroup) => {
        this.setState({time_missing: false})
        let time = selectedGroup.value.time;
        if (time % 100 === 0) {
            time += 100;
        } else {
            if (time % 100 >= 15) {
                time += 45;
                time += 40;
            } else {
                time += 45;
            }
        }

        let minutes = time % 100;
        let hours = parseInt(time / 100);

        if (hours < 10) {
            hours = "0" + hours.toString();
        }
        if (minutes < 10) {
            minutes = "0" + minutes.toString();
        }
        let timeString = hours.toString() + ":" + minutes.toString();

        // console.log(typeof(timeString), time)

        this.setState({ time_string: timeString });
        this.setState({ visit_time: time });

        console.log(selectedGroup.value.email)

        this.setState({ nurse_time: selectedGroup.value.time });
        this.setState({ nurse_time_range: selectedGroup.label });
        this.setState({ nurse_email: selectedGroup.value.email });
        this.setState({ selectedGroup });
    };

    cardCompleted = (complete) => {
        this.setState({ card_complete: complete })
    }

    showSuccess = (success, tab) => {
        this.setState({ success: success, activeTabProgress: tab })
    }

    loadingAnimation = (loading_animation) => {
        this.setState({ loading_animation: loading_animation })
    }

    checkoutError = (checkout_error) => {
        this.setState({ checkout_error: checkout_error })
    }

    addPatient = () => {

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        fetch(base_url + "/consumer-add", {
            method: "post",
            headers: headers,
            body: JSON.stringify(this.state),
        })
        .then((Response) => Response.json())
        .then((Result) => {

            this.loadingAnimation(false);
            if (Result.status === "success"){
                this.showSuccess(true, 5);
            }
            else {
                this.checkoutError(true)
            }
        });
    }

    handleSelectRace = selectedRace => {
        this.setState({ selectedRace, race_missing: false });
    };
    
    handleSelectSymptom = selectedSymptom => {
        this.setState({ selectedSymptom });
	};

    render() {
        // return (<Elements stripe={stripePromise}>
        //     <CheckoutForm/>
        //     </Elements>);

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };



        return (
            <Elements stripe={stripePromise}>
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid={true} style={{ backgroundColor: 'transparent' }}>

                            {this.state.loading_animation &&
                                <Lottie
                                    style={{
                                        position: 'absolute',
                                        zIndex: 10,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        marginTop: '20%'
                                    }}
                                    options={defaultOptions}
                                    height={200}
                                    width={200}
                                />}
                            <Row>
                                <Col lg="1"></Col>
                                <Col lg="10">
                                    {/* <Breadcrumbs title="Schedule Appointment" breadcrumbItems={this.state.breadcrumbItems} /> */}
                                    <Card>
                                        <CardHeader style={{ backgroundColor: 'white', textAlign: 'center' }}>
                                            <img className="pt-4" alt="Next Medical" width="200" src={logo} />
                                        </CardHeader>
                                        <CardBody>
                                            {/* <h4 className="card-title mb-4">Wizard with progressbar</h4> */}

                                            <div id="progrss-wizard" className="twitter-bs-wizard">
                                                <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills" hidden={this.state.success}>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTabProgress === 1 })}
                                                            style={{ cursor: "default" }}
                                                        // onClick={() => { this.toggleTabProgress(1); }}
                                                        >
                                                            <span className="step-number">01</span>
                                                            <span className="step-title">Select Tests</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTabProgress === 2 })}
                                                            style={{ cursor: "default" }}
                                                        // onClick={() => { this.toggleTabProgress(2); }} 
                                                        >
                                                            <span className="step-number">02</span>
                                                            <span className="step-title">Patient Information</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTabProgress === 3 })}
                                                            style={{ cursor: "default" }}
                                                        // onClick={() => { this.toggleTabProgress(3); }} 
                                                        >
                                                            <span className="step-number">03</span>
                                                            <span className="step-title">Billing</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTabProgress === 4 })}
                                                            style={{ cursor: "default" }}
                                                        // onClick={() => { this.toggleTabProgress(1); }}
                                                        >
                                                            <span className="step-number">04</span>
                                                            <span className="step-title">Confirm</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>

                                                <div id="bar" className="mt-4" hidden={this.state.success}>
                                                    <Progress color="primary" striped animated value={this.state.progressValue} />
                                                </div>
                                                <TabContent activeTab={this.state.activeTabProgress} className="twitter-bs-wizard-tab-content">
                                                    <TabPane tabId={1}>
                                                        <AvForm style={{ marginLeft: "0vh" }}>
                                                            <Row>
                                                                <br />
                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <h5>Select Tests</h5>
                                                                        <p className="text-muted">Please select from our available at-home care options.</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <br />
                                                            </Row>

                                                            <Row style={{ justifyContent: 'center' }}>
                                                                {this.state.selected_1 == false && (
                                                                <Col lg={4}>

                                                                
                                                                    <Card body className="text-center" style={{justifyContent: 'center', alignItems: 'center'}}>
                                                                        <CardTitle className="mt-0"><h5>COVID-19 RAPID ANTIGEN TEST</h5></CardTitle>
                                                                        <p>For the fastest possible results.</p>
                                                                        <p style={{fontSize: 20}} className="mt-2 mb-3">$199</p>
                                                                        
                                                                        <Col className="text-center mt-1" style={{backgroundColor: 'transparent', alignItems: 'center'}}>
                                                                            <Row style={{display: 'inline-block'}}>
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">Same-Day Testing</CardText>
                                                                                </Row> 
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">Results in 15 minutes</CardText>
                                                                                </Row> 
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">Ensure Ease of Mind</CardText>
                                                                                </Row> 
                                                                            </Row>
                                                                        </Col>
                                                                        <Button onClick={() => {this.setState({selected_1: true});this.state.price += 199}} style={{width: '50%'}} color="primary" className="btn btn-primary waves-effect waves-light mt-3">Select</Button>
                                                                    </Card>
                                                                </Col>     )}

                                                                {this.state.selected_1 == true && (
                                                                <Col lg={4}>

                                                                
                                                                    <Card body className="text-center" style={{justifyContent: 'center', alignItems: 'center'}}>
                                                                        <CardTitle className="mt-0"><h5>COVID-19 RAPID ANTIGEN TEST</h5></CardTitle>
                                                                        <CardText>For the fastest possible results.</CardText>
                                                                        <p style={{fontSize: 20}} className="mt-2 mb-3">$199</p>
                                                                        <Col className="text-center mt-1" style={{backgroundColor: 'transparent', alignItems: 'center'}}>
                                                                            <Row style={{display: 'inline-block'}}>
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">Same-Day Testing</CardText>
                                                                                </Row> 
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">Results in 15 minutes</CardText>
                                                                                </Row> 
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">Ensure Ease of Mind</CardText>
                                                                                </Row> 
                                                                            </Row>
                                                                        </Col>
                                                                        <Button onClick={() => {this.setState({selected_1: false});this.state.price -= 199}} style={{width: '50%'}} color="success" className="btn btn-primary waves-effect waves-light mt-3">Unselect</Button>
                                                                    </Card>
                                                                </Col>     )}  

                                                                {this.state.selected_2 == false && (
                                                                <Col lg={4}>
                                                                    <Card body className="text-center" style={{justifyContent: 'center', alignItems: 'center'}}>
                                                                        <CardTitle className="mt-0"><h5>COVID-19 PCR TEST</h5></CardTitle>
                                                                        <CardText>For the most accurate result.</CardText>
                                                                        <p style={{fontSize: 20}} className="mt-2 mb-3">$199</p>
                                                                        <Col className="text-center mt-1" style={{backgroundColor: 'transparent', alignItems: 'center'}}>
                                                                            <Row style={{display: 'inline-block'}}>
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">Same-Day Testing</CardText>
                                                                                </Row> 
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">{"Results in < 48 hours"}</CardText>
                                                                                </Row> 
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">For International Travel</CardText>
                                                                                </Row> 
                                                                            </Row>
                                                                        </Col>
                                                                        <Button onClick={() => {this.setState({selected_2: true});this.state.price += 199}} style={{width: '50%'}} color="primary" className="btn btn-primary waves-effect waves-light mt-3">Select</Button>
                                                                    </Card>
                                                                </Col>  )}  

                                                                {this.state.selected_2 == true && (
                                                                <Col lg={4}>
                                                                    <Card body className="text-center" style={{justifyContent: 'center', alignItems: 'center'}}>
                                                                        <CardTitle className="mt-0"><h5>COVID-19 PCR TEST</h5></CardTitle>
                                                                        <CardText>For the most accurate result.</CardText>
                                                                        <p style={{fontSize: 20}} className="mt-2 mb-3">$199</p>
                                                                        <Col className="text-center mt-1" style={{backgroundColor: 'transparent', alignItems: 'center'}}>
                                                                            <Row style={{display: 'inline-block'}}>
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">Same-Day Testing</CardText>
                                                                                </Row> 
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">{"Results in < 48 hours"}</CardText>
                                                                                </Row> 
                                                                                <Row style={{alignItems: 'center'}}>
                                                                                    <i style={{fontSize: 24}} className="mdi mdi-check text-success"></i>
                                                                                    <CardText className="ml-2">For International Travel</CardText>
                                                                                </Row> 
                                                                            </Row>
                                                                        </Col>
                                                                        <Button onClick={() => {this.setState({selected_2: false});this.state.price -= 199}} style={{width: '50%'}} color="success" className="btn btn-primary waves-effect waves-light mt-3">Unselect</Button>
                                                                    </Card>
                                                                </Col>  )}                                                       
                                                            </Row>

                                                            <Row>
                                                                <br />
                                                            </Row>

                                                            {/* <Row>
                                                                <br />
                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <Link to="https://tel:+1-516-607-1899" color="primary" className="btn btn-primary waves-effect waves-light mt-3">Call Now: (516) 670-1899</Link>
                                                                        <p className="text-muted mt-2">Please select from our available at-home care options.</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row> */}

                                                            <Row style={{ marginLeft: "3.5vh", marginRight: "3.5vh", justifyContent: 'center' }}>
                                                                <Col lg={6}>
                                                                    <Alert hidden={!this.state.checkout_error} color="danger">There was an error with the checkout process. Please try again.</Alert>
                                                                </Col>
                                                            </Row>

                                                        </AvForm>
                                                    </TabPane>
                                                    <TabPane tabId={2}>
                                                        <AvForm
                                                            style={{ marginLeft: "0vh" }}
                                                            onValidSubmit={() => console.log("Valid submit")}
                                                            autoComplete="off"
                                                        >
                                                            <Row>
                                                                <br />
                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <h5>Schedule Your Appointment</h5>
                                                                        <p className="text-muted">Please fill out the information to schedule your appointment. This information needs to match your passport/ID.</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <br />
                                                            </Row>
                                                            <Row style={{ marginLeft: "3.5vh", marginRight: "3.5vh" }}>
                                                                
                                                                <Col lg={3}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="validationCustom01">Name</Label>
                                                                        <AvField
                                                                            name="name"
                                                                            style={this.state.name_missing === true ? {borderColor: '#ff3d60'} : {borderColor: '#ced4da'}}
                                                                            placeholder="John Appleseed"
                                                                            type="text"
                                                                            errorMessage="Enter Name"
                                                                            onChange={(event) => {
                                                                                this.setState({
                                                                                    patient_name: event.target.value,
                                                                                    name_missing: false,
                                                                                });
                                                                            }}
                                                                            className="form-control"
                                                                            validate={{
                                                                                required: {
                                                                                    value: true,
                                                                                    errorMessage: "Please enter the full name",
                                                                                },
                                                                            }}
                                                                            autoComplete="none"
                                                                            value={this.state.patient_name}
                                                                            id="validationCustom01"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg={4}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="validationCustom04">Address</Label>
                                                                        <PlacesAutocomplete
                                                                            value={this.state.address}
                                                                            onChange={this.handleChange}
                                                                            onSelect={this.handleSelect}
                                                                        >
                                                                            {({
                                                                                getInputProps,
                                                                                suggestions,
                                                                                getSuggestionItemProps,
                                                                                loading,
                                                                            }) => (
                                                                                <div>
                                                                                    <input
                                                                                        style={this.state.address_missing === true ? {borderColor: '#ff3d60'} : {borderColor: '#ced4da'}}
                                                                                        {...getInputProps({
                                                                                            placeholder:
                                                                                                "150 Greenwich St, New York, NY 10006",
                                                                                            className: "form-control",
                                                                                            autoComplete: "nofill",
                                                                                            onBlur: (event) =>
                                                                                                this.handleSelect(event.target.value),
                                                                                        })}
                                                                                    />
                                                                                    <div className="autocomplete-dropdown-container" style={{position: 'absolute', zIndex: 10}}>
                                                                                        {loading && <div>Loading...</div>}
                                                                                        {suggestions.map((suggestion) => {
                                                                                            const className = suggestion.active
                                                                                                ? "suggestion-item--active"
                                                                                                : "suggestion-item";
                                                                                            // inline style for demonstration purpose
                                                                                            const style = suggestion.active
                                                                                                ? {
                                                                                                    backgroundColor: "lightgrey",
                                                                                                    cursor: "pointer",
                                                                                                    padding: 10,
                                                                                                    borderBottom: "1px solid #ced4da",
                                                                                                    borderLeft: "1px solid #ced4da",
                                                                                                    borderRight: "1px solid #ced4da",
                                                                                                }
                                                                                                : {
                                                                                                    backgroundColor: "#ffffff",
                                                                                                    cursor: "pointer",
                                                                                                    padding: 10,
                                                                                                    borderBottom: "1px solid #ced4da",
                                                                                                    borderLeft: "1px solid #ced4da",
                                                                                                    borderRight: "1px solid #ced4da",
                                                                                                };
                                                                                            return (
                                                                                                <div
                                                                                                    {...getSuggestionItemProps(
                                                                                                        suggestion,
                                                                                                        {
                                                                                                            className,
                                                                                                            style,
                                                                                                        }
                                                                                                    )}
                                                                                                >
                                                                                                    <span>
                                                                                                        {suggestion.description}
                                                                                                    </span>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </PlacesAutocomplete>
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg={2}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="validationCustom01">Apt #</Label>
                                                                        <AvField
                                                                            name="apartment_number"
                                                                            autoComplete="nofill"
                                                                            placeholder="422"
                                                                            type="text"
                                                                            errorMessage="Enter Apartment #"
                                                                            onChange={(event) =>
                                                                                this.setState({
                                                                                    apartment_number: event.target.value,
                                                                                })
                                                                            }
                                                                            className="form-control"
                                                                            validate={{
                                                                                required: {
                                                                                    value: false,
                                                                                },
                                                                            }}
                                                                            value={this.state.apartment_number}
                                                                            id="validationCustom01"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg={3}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="validationCustom03">
                                                                            Phone Number
                                                                        </Label>
                                                                        <AvField
                                                                            style={this.state.phone_missing === true ? {borderColor: '#ff3d60'} : {borderColor: '#ced4da'}}
                                                                            name="phonenumber"
                                                                            placeholder="5165879925"
                                                                            type="number"
                                                                            onChange={(event) =>
                                                                                this.setState({
                                                                                    phone: event.target.value,
                                                                                    phone_missing: false,
                                                                                })
                                                                            }
                                                                            errorMessage="Enter Phone Number"
                                                                            className="form-control"
                                                                            value={this.state.phone}
                                                                            validate={{
                                                                                required: {
                                                                                    value: true,
                                                                                    errorMessage: "Please enter a phone number",
                                                                                },
                                                                                minLength: {
                                                                                    value: 10,
                                                                                    errorMessage:
                                                                                        "The phone number must be must 10 digits",
                                                                                },
                                                                                maxLength: {
                                                                                    value: 10,
                                                                                    errorMessage:
                                                                                        "The phone number must be must 10 digits",
                                                                                },
                                                                            }}
                                                                            id="validationCustom03"
                                                                            autoComplete="nofill"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>


                                                            </Row>
                                                            <Row>
                                                                <br />
                                                            </Row>
                                                            <Row style={{ marginLeft: "3.5vh", marginRight: "3.5vh" }}>
                                                                <Col lg={3}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="validationCustom01">Date</Label>
                                                                        <DatePicker
                                                                            minDate={new Date()}
                                                                            className="form-control"
                                                                            value={this.state.default_date}
                                                                            selected={this.state.default_date}
                                                                            onChange={this.handleDefault}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <FormGroup className="select2-container">
                                                                        <Label>Visit Time (Enter address for times)</Label>
                                                                        <Select
                                                                            isDisabled={this.state.nurseTimeDisabled}
                                                                            styles={{
                                                                                menuPortal: (base) => ({
                                                                                    ...base,
                                                                                    zIndex: 9999,
                                                                                }),
                                                                                control: (provided, state) => ({
                                                                                    ...provided,
                                                                                    borderColor: this.state.time_missing ? '#ff3d60' : '#ced4da',
                                                                                }),
                                                                            }}
                                                                            menuPortalTarget={document.body}
                                                                            value={this.state.selectedGroup}
                                                                            onChange={this.handleSelectGroup}
                                                                            options={this.state.optionGroup}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg={3}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="validationCustom03">Age</Label>
                                                                        <AvField
                                                                            style={this.state.age_missing === true ? {borderColor: '#ff3d60'} : {borderColor: '#ced4da'}}
                                                                            name="age"
                                                                            placeholder="10"
                                                                            type="number"
                                                                            onChange={(event) =>
                                                                                this.setState({
                                                                                    age: event.target.value,
                                                                                    age_missing: false
                                                                                })
                                                                            }
                                                                            errorMessage="Enter Age"
                                                                            className="form-control"
                                                                            value={this.state.age}
                                                                            validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "Please enter an age.",
                                                                            },
                                                                            }}
                                                                            id="validationCustom03"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg={3}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="validationCustom07">Sex</Label>
                                                                        <select
                                                                        style={this.state.sex_missing === true ? {borderColor: '#ff3d60'} : {borderColor: '#ced4da'}}
                                                                        className="custom-select"
                                                                        value={this.state.sex_string}
                                                                        onChange={(event) => {
                                                                            if (event.target.value === "Male") {
                                                                                this.setState({sex: 0, sex_string: "Male"});
                                                                                this.setState({sex_missing: false})
                                                                            } else if (event.target.value === "Female") {
                                                                                this.setState({sex: 1, sex_string: "Female"});
                                                                                this.setState({sex_missing: false})
                                                                            }
                                                                            else if (event.target.value === "Other"){
                                                                                this.setState({sex: 2, sex_string: "Other"});
                                                                                this.setState({sex_missing: false})
                                                                            }
                                                                            else {
                                                                                this.setState({sex: "", sex_string: "Select Sex"});
                                                                            }
                                                                        }}
                                                                        >
                                                                        <option>Select Sex</option>
                                                                        <option>Male</option>
                                                                        <option>Female</option>
                                                                        <option>Other</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                
                                                                {/* <Col lg={6}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="validationCustom01">Anything else you'd like us to know?</Label>
                                                                        <AvField

                                                                            name="name"
                                                                            placeholder="Recently had fever and flu symptoms."
                                                                            type="text"
                                                                            onChange={(event) => {
                                                                                this.setState({
                                                                                    consumer_notes: event.target.value,
                                                                                });
                                                                            }}
                                                                            className="form-control"
                                                                            validate={{
                                                                                required: {
                                                                                    value: false,
                                                                                },
                                                                            }}
                                                                            autoComplete={false}
                                                                            value={this.state.consumer_notes}
                                                                            id="validationCustom01"
                                                                        />
                                                                    </FormGroup>
                                                                </Col> */}

                                                            </Row>
                                                            <Row>
                                                                <br />
                                                            </Row>

                                                            <Row style={{ marginLeft: "3.5vh", marginRight: "3.5vh" }}>
                                                                <Col lg={6}>
                                                                    <FormGroup className="select2-container">
                                                                        <Label className="control-label">What is your race / ethnicity?</Label>
                                                                        <Select
                                                                            styles={{
                                                                                menuPortal: (base) => ({
                                                                                    ...base,
                                                                                    zIndex: 9999,
                                                                                }),
                                                                                control: (provided, state) => ({
                                                                                    ...provided,
                                                                                    borderColor: this.state.race_missing ? '#ff3d60' : '#ced4da',
                                                                                }),
                                                                            }}
                                                                            value={this.state.selectedRace}
                                                                            isMulti={true}
                                                                            onChange={this.handleSelectRace}
                                                                            options={raceOptions}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg={6}>
                                                                    <FormGroup className="select2-container">
                                                                        <Label className="control-label">Symptoms</Label>
                                                                        <Select
                                                                            value={this.state.selectedSymptom}
                                                                            isMulti={true}
                                                                            onChange={this.handleSelectSymptom}
                                                                            options={symptomOptions}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                            </Row>
                                                            

                                                            <Row style={{ marginLeft: "3.5vh", marginRight: "3.5vh", justifyContent: 'center' }}>
                                                                <Col lg={6}>
                                                                    <Alert hidden={!this.state.errorMessage} color="danger">Please complete all the required fields.</Alert>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                    </TabPane>
                                                    <TabPane tabId={3}>
                                                        <div>
                                                            <AvForm style={{ marginLeft: "0vh" }}>
                                                                <Row>
                                                                    <br />
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <FormGroup style={{ textAlign: 'center' }}>
                                                                            <h5>Payment Information</h5>
                                                                            <p className="text-muted">Next Medical’s COVID-19 tests cost $199 each. We will provide you with a form to give to your insurance company to request full or partial reimbursement. Your card will not be charged until after you confirm your appointment.</p>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <br />
                                                                </Row>
                                                                <Row style={{ justifyContent: 'center' }}>

                                                                    <Col lg={4}>
                                                                        <FormGroup>
                                                                            <Label htmlFor="validationCustom07">Credit Card Information</Label>
                                                                            <CheckoutForm clientSecret={this.state.clientSecret} email={this.state.receipt_email} name={this.state.patient_name} isSubmit={this.state.isSubmit} cardCompleted={this.cardCompleted} showSuccess={this.showSuccess} loadingAnimation={this.loadingAnimation} checkoutError={this.checkoutError} addPatient={this.addPatient} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <br />
                                                                </Row>
                                                                <Row style={{ justifyContent: 'center' }}>
                                                                    <Col lg={4}>
                                                                        <FormGroup>
                                                                            <Label htmlFor="validationCustom01">Email for Receipt</Label>
                                                                            <AvField
                                                                                name="email"
                                                                                style={this.state.receipt_missing === true ? {borderColor: '#ff3d60'} : {borderColor: '#ced4da'}}
                                                                                placeholder="name@email.com"
                                                                                type="email"
                                                                                errorMessage="Please enter a valid email"
                                                                                onChange={(event) => {
                                                                                    this.setState({
                                                                                        receipt_email: event.target.value,
                                                                                        receipt_missing: false,
                                                                                    });
                                                                                }}
                                                                                className="form-control"
                                                                                validate={{
                                                                                    required: {
                                                                                        email: { value: true },
                                                                                        errorMessage: "Please enter an email ",
                                                                                    },
                                                                                }}
                                                                                autoComplete="none"
                                                                                value={this.state.receipt_email}
                                                                                id="validationCustom01"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <br />
                                                                </Row>
                                                                <Row style={{ justifyContent: 'center' }}>
                                                                    <Col lg={4}>
                                                                        <Alert hidden={!this.state.errorMessage} color="danger">Please complete all the required fields.</Alert>
                                                                    </Col>
                                                                </Row>
                                                            </AvForm>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId={4}>
                                                        <AvForm style={{ marginLeft: "0vh" }}>
                                                            <Row>
                                                                <br />
                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <h5>Confirm Details</h5>
                                                                        <p className="text-muted">Please confirm that the information for your appointment is accurate.</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <br />
                                                            </Row>

                                                            <Row style={{ justifyContent: 'center' }}>
                                                                <Col lg={3}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <h6>Date</h6>
                                                                        <p>{this.state.date_string}</p>
                                                                        <br />
                                                                        <h6>Name</h6>
                                                                        <p>{this.state.patient_name}</p>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <h6>Time</h6>
                                                                        <p>{this.state.nurse_time_range}</p>
                                                                        <br />
                                                                        <h6>Phone</h6>
                                                                        <p>({this.state.phone.slice(0,3)}) {this.state.phone.slice(3,6)}-{this.state.phone.slice(6,10)}</p>
                                                                        
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <h6>Care Option</h6>
                                                                        {this.state.selected_1 == true && this.state.selected_2 == false && (
                                                                        <p>Covid Rapid Antigen Test</p>)}

                                                                        {this.state.selected_1 == false && this.state.selected_2 == true && (
                                                                        <p>Covid PCR Test</p>)}

                                                                        {this.state.selected_1 == true && this.state.selected_2 == true && (
                                                                        <p>Covid Rapid Antigen Test, Covid PCR Test</p>)}
                                                                        <br />
                                                                        <h6>Address</h6>
                                                                        <p>{this.state.address}</p>
                                                                        
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <h6>Cost</h6>
                                                                        <p>${this.state.price}</p>
                                                                        <br />
                                                                        <h6>Apartment</h6>
                                                                        <p>{this.state.apartment_number === "" ? "N/A" : this.state.apartment_number}</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <br />
                                                                <br />
                                                                <br />
                                                            </Row>

                                                            <Row style={{ justifyContent: 'center' }}>
                                                                <Col lg={6}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                    <div className="custom-control custom-checkbox mb-3">
                                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => this.setState({hightlight_tos: false})} checked={this.state.customchk} />
                                                                        <Label style={this.state.hightlight_tos === true ? {color: '#ff3d60'} : {color: '#505d69'}} className="custom-control-label" onClick={() => { this.setState({ customchk: !this.state.customchk, hightlight_tos: false }) }} >I have read and agree to the terms of service (https://www.joinnextmed.com/terms).</Label>
                                                                    </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <br />
                                                            </Row>

                                                            <Row style={{ marginLeft: "3.5vh", marginRight: "3.5vh", justifyContent: 'center' }}>
                                                                <Col lg={6}>
                                                                    <Alert hidden={!this.state.checkout_error} color="danger">There was an error with the checkout process. Please try again.</Alert>
                                                                </Col>
                                                            </Row>

                                                        </AvForm>
                                                    </TabPane>
                                                    <TabPane tabId={5}>
                                                        <div className="row justify-content-center">
                                                            <Col lg="6">
                                                                <div className="text-center">
                                                                    <div className="mb-4">
                                                                        <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                        <AvForm style={{ marginLeft: "0vh" }}>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <FormGroup style={{ textAlign: 'center' }}>
                                                                        <h3>Success!</h3>
                                                                        <br></br>
                                                                        <p className="text-muted">Please check your email for your receipt. You will also receive a text message regarding your appointment details.</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <br />
                                                            </Row>

                                                        </AvForm>
                                                    </TabPane>
                                                </TabContent>
                                                <ul className="pager wizard twitter-bs-wizard-pager-link" hidden={this.state.success}>
                                                    <li hidden={this.state.activeTabProgress === 1 } className={this.state.loading_animation ? "previous disabled" : "previous"}><Link to="#" style={{ backgroundColor: '#0D55B0' }} onClick={() => { this.toggleTabProgress(this.state.activeTabProgress - 1); }}>Previous</Link></li>
                                                    <li hidden={this.state.activeTabProgress === 1 && this.state.price === 0} className={this.state.loading_animation ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTabProgress(this.state.activeTabProgress + 1); }}>{this.state.activeTabProgress === 4 ? "Confirm" : "Next"}</Link></li>
                                                </ul>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </React.Fragment>
            </Elements>
        );
    }
}

export default FormWizard;
