import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media, Button } from "reactstrap";
import { Popover, PopoverBody } from "reactstrap";
// Import Breadcrumb

import { Redirect } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import ChiefComplaintWidget from "./ChiefComplaintWidget";
import VitalsWidget3 from "./VitalsWidget3";
// import AuscultationWidget from "./AuscultationWidget";
import ImageRow1 from "./ImageRow1";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { PDFExport } from "@progress/kendo-react-pdf";
import "./exportPdf.css";

import VerticalLayout from "../../components/VerticalLayout/";
import HorizontalLayout from "../../components/HorizontalLayout/";

import base_url from "../base_url";

import AutoLogout from "../AutoLogout";

class Dashboard extends Component {
	pdfExport;
	getLayout = () => {
		let layoutCls = VerticalLayout;

		switch ("vertical") {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

	constructor(props) {
		super(props);
		this.state = {
			photoIndex: 0,
			t1:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=left_ear",
			t2:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=right_ear",
			o1:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=left_eye",
			o2:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=right_eye",
			d:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=skin",
			ecg:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=ecg",
			or:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=throat",
			spirometry_image:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=spirometry",
			strep_image:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=strep",
			flu_image:
				"https://zf7ivuv18l.execute-api.us-east-2.amazonaws.com/dev/get-dashboard?mrn=" +
				this.props.match.params.mrn +
				"&type=flu",

			ecg_b: true,
			spirometry_b: true,
			or_b: true,
			d_b: true,
			t1_b: true,
			o1_b: true,
			o2_b: true,
			strep_b: true,
			flu_b: true,

			ecg_index: 0,
			spirometry_index: 0,
			or_index: 0,
			d_index: 0,
			t1_index: 0,
			o1_index: 0,
			o2_index: 0,
			strep_index: 0,
			flu_index: 0,

			isOpen: false,
			labels: ["NaN", "NaN", "NaN", "NaN", "NaN"],
			images: ["NaN", "NaN", "NaN", "NaN", "NaN"],

			popoverOpen: false,
			patient_name: "",
			patient_age: "",
			patient_sex: "",
			complaint: "",
			notes: "",
			date: "",
			time: "",

			height: "",
			weight: "",
			bmi: "",
			height_percentile: "",
			weight_percentile: "",
			bmi_percentile: "",
			blood_pressure: "",
			spo2: "",
			heart_rate: "",
			respiratory_rate: "",
			temperature: "",
			requested_test: [],
			requested_test_length: 0,
			sound: "",

			breadcrumbItems: [
				// { title: "Home", link: "#" },
				// { title: "Dashboard", link: "#" },
			],
			vitals: [
				{
					icon: "ri-ruler-line",
					title: "Height",
					value: "",
					rate: "",
					desc: "",
					color: "",
					hidden: false,
				},
				{
					icon: "mdi mdi-weight",
					title: "Weight",
					value: "",
					rate: "",
					desc: "",
					color: "",
					hidden: false,
				},
				{
					icon: "ri-bar-chart-fill",
					title: "BMI",
					value: "",
					rate: "",
					desc: "",
					color: "",
					hidden: false,
				},
				{
					icon: "mdi mdi-blood-bag",
					title: "Blood Pressure",
					value: "",
					rate: "",
					desc: "",
					color: "",
					hidden: false,
				},
				{
					icon: "mdi mdi-gauge",
					title: "SPO2",
					value: "",
					rate: "",
					desc: "",
					color: "",
					hidden: false,
				},
				{
					icon: "ri-heart-fill",
					title: "Heart Rate",
					value: "",
					rate: "",
					desc: "",
					color: "",
					hidden: false,
				},
				{
					icon: "mdi mdi-lungs",
					title: "Respiratory Rate",
					value: "",
					rate: "",
					desc: "",
					color: "",
					hidden: false,
				},
				{
					icon: "mdi mdi-thermometer",
					title: "Temperature",
					value: "",
					rate: "",
					desc: "",
					color: "",
					hidden: false,
				},
			],
		};
		this.toggle = this.toggle.bind(this);
		this.getLayout = this.getLayout.bind(this);
		this.handleImageError = this.handleImageError.bind(this);
		this.handleImageClick = this.handleImageClick.bind(this);
	}

	toggle() {
		this.setState({ popoverOpen: !this.state.popoverOpen });
	}

	handleImageError(k) {
		console.log(k);

		this.state[k.toString()] = false;
	}

	handleImageClick(k) {
		const t1 = this.state.t1;
		const t2 = this.state.t2;
		const o1 = this.state.o1;
		const o2 = this.state.o2;
		const or = this.state.or;
		const dd = this.state.d;
		const spirometry = this.state.spirometry_image;
		const ecg = this.state.ecg;
		const flu_image = this.state.flu_image;
		const strep_image = this.state.strep_image;
		let labels = [];
		let images = [];

		if (this.state.or_b === true) {
			images.push(or);
			labels.push("Oropharynx");
			this.setState({ or_index: images.length - 1 });
		}

		if (this.state.d_b === true) {
			images.push(dd);
			labels.push("Dermatological");
			this.setState({ d_index: images.length - 1 });
		}

		if (this.state.t1_b === true) {
			images.push(t1);

			labels.push("Left Ear");
			this.setState({ t1_index: images.length - 1 });

			images.push(t2);

			labels.push("Right Ear");
			this.setState({ t2_index: images.length - 1 });
		}

		console.log(this.state.o1_b);
		console.log(this.state.o2_b);

		if (this.state.o1_b === true && this.state.o2_b === true) {
			images.push(o1);

			labels.push("Left Eye");
			this.setState({ o1_index: images.length - 1 });

			images.push(o2);

			labels.push("Right Eye");
			this.setState({ o2_index: images.length - 1 });
		} else if (this.state.o1_b === true && this.state.o2_b === false) {
			images.push(o1);
			labels.push("Ocular Images");
			this.setState({ o1_index: images.length - 1 });
		}

		if (this.state.strep_b === true) {
			images.push(strep_image);
			labels.push("Strep");
			this.setState({ strep_index: images.length - 1 });
		}

		if (this.state.flu_b === true) {
			images.push(flu_image);
			labels.push("Flu");
			this.setState({ flu_index: images.length - 1 });
		}

		if (this.state.ecg_b === true) {
			images.push(ecg);
			labels.push("Electrocardiogram");
			this.setState({ ecg_index: images.length - 1 });
		}

		if (this.state.spirometry_b === true) {
			images.push(spirometry);
			labels.push("Spirometry");
			this.setState({ spirometry_index: images.length - 1 });
		}

		console.log(images);
		console.log(labels);

		this.setState({ images: images });
		this.setState({ labels: labels });

		console.log(this.state.images);
		this.setState({ photoIndex: this.state[k + "_index"] });
		this.setState({ isOpen: true });
	}

	componentDidMount() {
		const isLoggedIn = window.sessionStorage.getItem("isLoggedIn");
		if (isLoggedIn !== "true") {
			return <Redirect to="/login" />;
		}
		const d = {
			email: window.sessionStorage.getItem("email"),
			mrn: this.props.match.params.mrn,
		};

		let headers = {
			Accept: "application/json",
			"Content-Type": "application/json",
		};

		if (
			base_url === "http://127.0.0.1:5000" ||
			base_url === "http://3.128.30.125:8080"
		) {
			headers = {
				Accept: "application/json",
				"Content-Type": "application/json",
			};
		} else {
			headers = {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: window.sessionStorage.getItem("token"),
			};
		}

		fetch(base_url + "/get-db-data", {
			method: "post",
			headers: headers,

			body: JSON.stringify(d),
		})
			.then((Response) => Response.json())
			.then((Result) => {
				if (Result.status === "success") {
					this.setState({
						patient_name: Result.data["patient_name"],
					});

					console.log(Result.data);

					this.setState({ live: Result.data["live"] });

					this.setState({ patient_age: Result.data["age"] });
					
					this.setState({
						complaint: Result.data["chief_complaint"],
					});
					this.setState({
						notes: Result.data["physical_exam_notes"],
					});

					if (Result.data["sex"] === 0) {
						this.setState({ patient_sex: "Male" });
					} else {
						this.setState({ patient_sex: "Female" });
					}
					let tempDate =
						Result.data["visit_month"].toString() +
						"/" +
						Result.data["visit_date"].toString() +
						"/" +
						Result.data["visit_year"].toString();
					let tempTime = Result.data["visit_time"];
					let tempString = "";
					if (tempTime >= 1200 && tempTime < 2400) {
						if (parseInt(tempTime.toString().slice(0, 2)) === 12) {
							tempString =
								tempTime.toString().slice(0, 2) +
								":" +
								tempTime.toString().slice(2, 4) +
								" pm";
						} else {
							tempString =
								parseInt(tempTime.toString().slice(0, 2)) -
								12 +
								":" +
								tempTime.toString().slice(2, 4) +
								" pm";
						}
					} else {
						tempString =
							tempTime.toString().slice(0, 2) +
							":" +
							tempTime.toString().slice(2, 4) +
							" am";
					}

					this.setState({ date: tempDate, time: tempString });

					let height = "";

					height = Result.data["height"] + " in";

					var keyword = "randomtext";
					let height_percentile = Math.round(
						Result.data["height_percentile"] * 100
					);
					let height_text = "Percentile";
					let height_hidden =
						Result.data["height"] === 0 ||
						Result.data["height"] === "NaN"
							? true
							: false;

					if (Result.data["height_percentile"] === -1) {
						height_percentile = "NaN";
						keyword = "";
						height_text = "";
					}

					// let height_percentile = 14
					if (
						height_percentile === 1 ||
						height_percentile === 21 ||
						height_percentile === 31 ||
						height_percentile === 41 ||
						height_percentile === 51 ||
						height_percentile === 61 ||
						height_percentile === 71 ||
						height_percentile === 81 ||
						height_percentile === 91
					) {
						keyword = "st";
					} else if (
						height_percentile === 2 ||
						height_percentile === 22 ||
						height_percentile === 32 ||
						height_percentile === 42 ||
						height_percentile === 52 ||
						height_percentile === 62 ||
						height_percentile === 72 ||
						height_percentile === 82 ||
						height_percentile === 92
					) {
						keyword = "nd";
					} else if (
						height_percentile === 3 ||
						height_percentile === 23 ||
						height_percentile === 33 ||
						height_percentile === 43 ||
						height_percentile === 53 ||
						height_percentile === 63 ||
						height_percentile === 73 ||
						height_percentile === 83 ||
						height_percentile === 93
					) {
						keyword = "rd";
					} else {
						if (keyword !== "") {
							keyword = "th";
						}
					}
					height_percentile = height_percentile + keyword;

					keyword = "randomtext";
					let weight = Result.data["weight"] + " lbs";
					let weight_percentile = Math.round(
						Result.data["weight_percentile"] * 100
					);
					let weight_text = "Percentile";
					let weight_hidden =
						Result.data["weight"] === 0 ||
						Result.data["weight"] === "NaN"
							? true
							: false;

					if (Result.data["weight_percentile"] === -1) {
						weight_percentile = "NaN";
						keyword = "";
						weight_text = "";
					}

					//let weight_percentile = 82

					if (weight_percentile % 10 === 1) {
						keyword = "st";
					} else if (weight_percentile % 10 === 2) {
						keyword = "nd";
					} else if (weight_percentile % 10 === 3) {
						keyword = "rd";
					} else {
						if (keyword !== "") {
							keyword = "th";
						}
					}

					weight_percentile = weight_percentile + keyword;

					keyword = "randomtext";
					let bmi = (
						(Result.data["weight"] * 703.0) /
						(Result.data["height"] * Result.data["height"])
					).toFixed(1);
					let bmi_percentile = Math.round(
						Result.data["bmi_percentile"] * 100
					);
					console.log("BMI PERCENTILE", bmi_percentile);
					let bmi_text = "Percentile";
					let bmi_hidden =
						Result.data["height"] === 0 ||
						Result.data["weight"] === 0
							? true
							: false;

					if (
						Result.data["height_percentile"] === -1 ||
						Result.data["weight_percentile"] === -1 ||
						Result.data["bmi_percentile"] === -1
					) {
						bmi_percentile = "NaN";
						keyword = "";
						bmi_text = "";
					}

					if (bmi_percentile > 100) {
						bmi_percentile = 99.99;
					}

					let bmi_color = "";

					if (bmi_percentile % 10 === 1) {
						keyword = "st";
					} else if (bmi_percentile % 10 === 2) {
						keyword = "nd";
					} else if (bmi_percentile % 10 === 3) {
						keyword = "rd";
					} else {
						if (bmi_text !== "") {
							keyword = "th";
						}
					}

					if (bmi_percentile < 5) {
						bmi_color = "warning";
					} else if (bmi_percentile < 85 && bmi_percentile > 5) {
						bmi_color = "success";
					} else if (bmi_percentile < 95 && bmi_percentile > 85) {
						bmi_color = "warning";
					} else {
						bmi_color = "danger";
					}

					if (bmi_percentile === "NaN") {
						bmi_color = "secondary";
					}

					bmi_percentile = bmi_percentile + keyword;

					let blood_pressure = Result.data["blood_pressure"];
					let blood_pressure_subtitle = "";
					let blood_pressure_text = "";
					let blood_pressure_color = "";
					// Need to know what this looks
					let bp_arr = blood_pressure.split("/");
					let systolic = parseInt(bp_arr[0]);
					let diastolic = parseInt(bp_arr[1]);

					let blood_pressure_hidden =
						systolic === 0 || diastolic === 0 ? true : false;

					if (systolic <= 120 && diastolic <= 80) {
						blood_pressure_color = "success";
						blood_pressure_subtitle = "Normal";
					} else if (
						systolic > 120 &&
						systolic <= 129 &&
						diastolic <= 80
					) {
						blood_pressure_color = "warning";
						blood_pressure_subtitle = "Elevated";
					} else if (
						(systolic >= 130 && systolic <= 139) ||
						(diastolic > 80 && diastolic <= 89)
					) {
						blood_pressure_color = "danger";
						blood_pressure_subtitle = "High Blood Pressure Stage 1";
					} else if (
						(systolic >= 140 && systolic <= 179) ||
						(diastolic >= 90 && diastolic <= 119)
					) {
						blood_pressure_color = "danger";
						blood_pressure_subtitle = "High Blood Pressure Stage 2";
					} else if (systolic >= 180 || diastolic >= 120) {
						blood_pressure_color = "danger";
						blood_pressure_subtitle = "Hypertensive crisis";
					}

					let spo2 = Result.data["spo2"];
					let spo2_subtitle = "";
					let spo2_text = "";
					let spo2_color = "";

					let spo2_hidden =
						Result.data["spo2"] === 0 || Result.data["spo2"] === ""
							? true
							: false;

					if (spo2 <= 90) {
						spo2_subtitle = "Low Oxygen";
						spo2_color = "danger";
					} else if (spo2 > 90 && spo2 < 94) {
						spo2_subtitle = "Below Average Oxygen";
						spo2_color = "warning";
					} else {
						spo2_subtitle = "Adequate Oxygen";
						spo2_color = "success";
					}

					spo2 = spo2 + "%";

					let heart_rate = Result.data["heart_rate"];
					let heart_rate_subtitle = "";
					let heart_rate_text = "";
					let heart_rate_color = "";

					let heart_rate_hidden =
						Result.data["heart_rate"] === 0 ||
						Result.data["heart_rate"] === ""
							? true
							: false;

					if (this.state.patient_sex === "Male") {
						if (heart_rate < 69) {
							heart_rate_subtitle = "Good";
							heart_rate_color = "success";
						} else if (heart_rate > 70 && heart_rate < 75) {
							heart_rate_subtitle = "Normal";
							heart_rate_color = "success";
						} else if (heart_rate > 75 && heart_rate < 81) {
							heart_rate_subtitle = "Below average";
							heart_rate_color = "warning";
						} else {
							heart_rate_subtitle = "Poor";
							heart_rate_color = "danger";
						}
					} else if (this.state.patient_sex === "Female") {
						if (heart_rate < 73) {
							heart_rate_subtitle = "Good";
							heart_rate_color = "success";
						} else if (heart_rate > 74 && heart_rate < 78) {
							heart_rate_subtitle = "Average";
							heart_rate_color = "warning";
						} else if (heart_rate > 79 && heart_rate < 84) {
							heart_rate_subtitle = "Below average";
							heart_rate_color = "warning";
						} else {
							heart_rate_subtitle = "Poor";
							heart_rate_color = "danger";
						}
					}

					heart_rate = heart_rate + " bpm";

					let respiratory_rate = Result.data["respiratory_rate"];
					let respiratory_rate_subtitle = "";
					let respiratory_rate_text = "";
					let respiratory_color = "";

					let respiratory_rate_hidden =
						Result.data["respiratory_rate"] === 0 ||
						Result.data["respiratory_rate"] === ""
							? true
							: false;

					if (respiratory_rate >= 12 && respiratory_rate <= 20) {
						respiratory_rate_subtitle = "Normal";
						respiratory_color = "success";
					} else if (respiratory_rate < 12) {
						respiratory_rate_subtitle = "Bradypnoea";
						respiratory_color = "danger";
					} else {
						respiratory_rate_subtitle = "Tachypnoea";
						respiratory_color = "danger";
					}

					respiratory_rate = respiratory_rate + " bpm";

					let temperature = Result.data["temperature"];
					let temperature_subtitle = "";
					let temperature_text = "";
					let temperature_color = "";

					let temperature_hidden =
						Result.data["temperature"] === 0 ||
						Result.data["temperature"] === ""
							? true
							: false;

					if (temperature < 100) {
						temperature_subtitle = "Normal";
						temperature_color = "success";
					} else if (temperature > 100 && temperature < 103) {
						temperature_subtitle = "Mild Fever";
						temperature_color = "warning";
					} else {
						temperature_subtitle = "High Fever";
						temperature_color = "danger";
					}

					temperature = temperature + "°";

					let vitals_arr = [];
					vitals_arr.push({
						value: height,
						rate: height_percentile,
						desc: height_text,
						color: "secondary",
						hidden: height_hidden,
					});

					vitals_arr.push({
						value: weight,
						rate: weight_percentile,
						desc: weight_text,
						color: "secondary",
						hidden: weight_hidden,
					});

					vitals_arr.push({
						value: bmi,
						rate: bmi_percentile,
						desc: "Percentile",
						color: bmi_color,
						hidden: bmi_hidden,
					});

					vitals_arr.push({
						value: blood_pressure,
						rate: blood_pressure_subtitle,
						desc: blood_pressure_text,
						color: blood_pressure_color,
						hidden: blood_pressure_hidden,
					});

					vitals_arr.push({
						value: spo2,
						rate: spo2_subtitle,
						desc: spo2_text,
						color: spo2_color,
						hidden: spo2_hidden,
					});

					vitals_arr.push({
						value: heart_rate,
						rate: heart_rate_subtitle,
						desc: heart_rate_text,
						color: heart_rate_color,
						hidden: heart_rate_hidden,
					});

					vitals_arr.push({
						value: respiratory_rate,
						rate: respiratory_rate_subtitle,
						desc: respiratory_rate_text,
						color: respiratory_color,
						hidden: respiratory_rate_hidden,
					});

					vitals_arr.push({
						value: temperature,
						rate: temperature_subtitle,
						desc: temperature_text,
						color: temperature_color,
						hidden: temperature_hidden,
					});

					let tests_arr = [];

					if (Result.data["urine"] === 1) {
						tests_arr.push("Urine");
					}
					if (Result.data["strep"] === 1) {
						tests_arr.push("Strep");
					}
					if (Result.data["flu"] === 1) {
						tests_arr.push("Flu");
					}
					if (Result.data["covid"] === 1) {
						tests_arr.push("COVID");
					}
					if (Result.data["ecg"] === 1) {
						tests_arr.push("ECG");
					}
					if (Result.data["viral"] === 1) {
						tests_arr.push("Viral");
					}
					if (Result.data["spirometry"] === 1) {
						tests_arr.push("Spirometry");
					}
					if (Result.data["blood"] === 1) {
						tests_arr.push("Blood");
					}

					this.setState({ requested_test_length: tests_arr.length });

					if (tests_arr.length === 0) {
						tests_arr.push("No Tests Requested");
					}

					this.setState({ requested_test: tests_arr });

					var vitals = this.state.vitals;
					for (let i = 0; i < vitals.length; i++) {
						vitals[i]["value"] = vitals_arr[i]["value"];
						vitals[i]["rate"] = vitals_arr[i]["rate"];
						vitals[i]["desc"] = vitals_arr[i]["desc"];
						vitals[i]["color"] = vitals_arr[i]["color"];
						vitals[i]["hidden"] = vitals_arr[i]["hidden"];
					}

					this.setState({ vitals: vitals });
					console.log(this.state.o2);
				} else {
					this.props.history.push("/appointments");
				}
			});
	}

	exportPDF = () => {
		this.pdfExport.save();
		console.log("export to pdf done!");
	};

	render() {
		const images = this.state.images;
		const labels = this.state.labels;
		const t1 = this.state.t1;
		const t2 = this.state.t2;
		const o1 = this.state.o1;
		const o2 = this.state.o2;
		const or = this.state.or;
		const dd = this.state.d;
		const spirometry = this.state.spirometry_image;
		const ecg = this.state.ecg;
		const flu_image = this.state.flu_image;
		const strep_image = this.state.strep_image;
		const Layout = this.getLayout();
		const isLoggedIn = window.sessionStorage.getItem("isLoggedIn");
		if (isLoggedIn !== "true") {
			return <Redirect to="/login" />;
		}

		const popoverOpen = this.state.popoverOpen;

		return (
			<Layout>
				<React.Fragment>
					{this.state.isOpen && (
						<Lightbox
							imageCaption={
								<div style={{ fontSize: "200%" }}>
									{this.state.labels[this.state.photoIndex]}
								</div>
							}
							mainSrc={this.state.images[this.state.photoIndex]}
							nextSrc={
								this.state.images[
									(this.state.photoIndex + 1) % images.length
								]
							}
							prevSrc={
								this.state.images[
									(this.state.photoIndex +
										this.state.images.length -
										1) %
										this.state.images.length
								]
							}
							onCloseRequest={() =>
								this.setState({
									isOpen: false,
								})
							}
							onMovePrevRequest={() =>
								this.setState({
									photoIndex:
										(this.state.photoIndex +
											this.state.images.length -
											1) %
										this.state.images.length,
								})
							}
							onMoveNextRequest={() =>
								this.setState({
									photoIndex:
										(this.state.photoIndex + 1) %
										this.state.images.length,
								})
							}
						/>
					)}
					<AutoLogout />

					<div className="page-content">
						<Container fluid>
							<PDFExport
								paperSize={"A4"}
								fileName={
									this.state.patient_name +
									" - " +
									this.props.match.params.mrn
								}
								scale={0.6}
								margin={{
									top: 20,
									left: 10,
									right: 10,
									bottom: 10,
								}}
								title={this.state.patient_name}
								subject="Patient Details"
								forcePageBreak=".page-break"
								landscape={false}
								ref={(r) => (this.pdfExport = r)}
							>
								<div className="top" ref={this.bodyRef}>
									<Breadcrumbs
										title="Patient Information"
										breadcrumbItems={
											this.state.breadcrumbItems
										}
									/>

									<Col
										md={12}
										style={{
											paddingLeft: 0,
											paddingRight: 0,
										}}
									>
										<Card style={{ borderRadius: 12 }}>
											<CardBody>
												<Media
													style={{
														backgroundColor:
															"transparent",
													}}
												>
													<Media
														body
														className="overflow-hidden"
														style={{
															backgroundColor:
																"transparent",
														}}
													>
														<p className="text-truncate font-size-14 mb-2">
															Name
														</p>
														<h5 className="mt-3">
															{
																this.state
																	.patient_name
															}
														</h5>
													</Media>
													<Media
														body
														className="overflow-hidden"
													>
														<p className="text-truncate font-size-14 mb-2">
															Age
														</p>
														<h5 className="mt-3">
															{
																this.state
																	.patient_age
															}{" "}
															yrs old
														</h5>
													</Media>
													<Media
														body
														className="overflow-hidden"
													>
														<p className="text-truncate font-size-14 mb-2">
															Sex
														</p>
														<h5 className="mt-3">
															{
																this.state
																	.patient_sex
															}
														</h5>
													</Media>
													<Media
														body
														className="overflow-hidden"
														style={{
															marginRight: 50,
														}}
													>
														<p className="text-truncate font-size-14 mb-2">
															Date
														</p>
														<h5 className="mt-3">
															{this.state.date}{" "}
															{this.state.time}
														</h5>

														{/* <h5 className="mb-0"> 10:30am</h5> */}
													</Media>
													<Media
														body
														className="overflow-hidden"
														style={{
															marginRight: 50,
														}}
													>
														<p className="text-truncate font-size-14 mb-2">
															Tests
														</p>
														<div>
															<Button
																color="light"
																id="Popover1"
																onMouseMove={() => {
																	this.setState(
																		{
																			popoverOpen: true,
																		}
																	);
																}}
																onMouseLeave={() => {
																	this.setState(
																		{
																			popoverOpen: false,
																		}
																	);
																}}
															>
																{
																	this.state
																		.requested_test_length
																}{" "}
																Tests Requested
															</Button>
															<Popover
																placement="bottom"
																isOpen={
																	popoverOpen
																}
																target="Popover1"
															>
																<PopoverBody
																	style={{
																		fontSize:
																			"120%",
																		backgroundColor:
																			"#EFEFEF",
																	}}
																>
																	<ul
																		style={{
																			marginRight:
																				"2vh",
																			paddingLeft: 20,
																			paddingBottom: 0,
																			marginBottom: 0,
																		}}
																	>
																		{this.state.requested_test.map(
																			(
																				test,
																				key
																			) => (
																				<li>
																					{
																						test
																					}
																				</li>
																			)
																		)}
																		{/* <li>Spirometry</li>
                                                                <li>ECG</li>
                                                                <li>COVID</li> */}
																	</ul>
																</PopoverBody>
															</Popover>
														</div>
													</Media>
													{/* <Button style={{ alignSelf: 'center', backgroundColor: '#0D55B0', borderColor: '#0D55B0' }} color="primary" type="button" className="waves-effect waves-light mr-3">
                                            Request Labs <i className="ri-file-text-line align-middle ml-2"></i>
                                        </Button> */}
													<Button
														style={{
															alignSelf: "center",
															backgroundColor:
																"#0D55B0",
															borderColor:
																"#0D55B0",
														}}
														color="primary"
														onClick={() =>
															this.exportPDF()
														}
														type="button"
														className="waves-effect waves-light mr-3"
													>
														Export to PDF{" "}
														<i className="ri-share-forward-2-fill align-middle ml-2"></i>
													</Button>
													{/* <div className="text-primary">
                                            <i className={"ri-briefcase-4-line" + " font-size-24"}></i>
                                        </div> */}
												</Media>
											</CardBody>
										</Card>
									</Col>

									<Row>
										<Col xl={4}>
											<Row>
												<Col xl={12}>
													<ChiefComplaintWidget
														size={12}
														title="Chief Complaint"
														mrn={
															this.props.match
																.params.mrn
														}
														content={
															this.state.complaint
														}
													/>
													<ChiefComplaintWidget
														size={12}
														title="Physical Exam Notes"
														mrn={
															this.props.match
																.params.mrn
														}
														content={
															this.state.notes
														}
													/>
												</Col>
											</Row>
										</Col>

										<Col xl={8}>
											<Row>
												<VitalsWidget3
													reports={this.state.vitals}
												/>
											</Row>
										</Col>
									</Row>

									<Row>
										<Col xl={12}>
											<Row>
												<ImageRow1
													imgs={images}
													mrn={
														this.props.match.params
															.mrn
													}
													error={
														this.handleImageError
													}
													click={
														this.handleImageClick
													}
												/>
												{/* <AuscultationWidget {...props} /> */}
												{/* <AuscultationWidget /> */}

												{this.state.ecg_b === true && (
													<Col
														xl={6}
														lg={6}
														md={6}
														sm={12}
													>
														<Card
															style={{
																borderRadius: 12,
															}}
														>
															<div
																className="text-truncate"
																style={{
																	paddingBottom:
																		"1.2vh",
																	paddingTop:
																		"1.2vh",
																	paddingLeft:
																		"1vh",
																}}
															>
																<span
																	style={{
																		color:
																			"#343a40",
																		marginLeft:
																			"1vh",
																	}}
																>
																	<b>
																		Electrocardiogram
																	</b>
																</span>
															</div>
															<CardBody className="border-top py-3">
																<Media>
																	<Media
																		body
																		className="overflow-hidden"
																	>
																		<img
																			onClick={() =>
																				this.setState(
																					{
																						isOpen: true,
																						photoIndex: this
																							.state
																							.ecg_index,
																					}
																				)
																			}
																			alt="Electrocardiogram Image"
																			onError={() => {
																				this.setState(
																					{
																						ecg_b: false,
																					}
																				);
																			}}
																			src={
																				ecg
																			}
																			style={{
																				height:
																					"24vh",
																				width:
																					"100%",
																			}}
																		></img>
																	</Media>
																</Media>
															</CardBody>
														</Card>
													</Col>
												)}

												{this.state.live == 1 && (
													<Col
														xl={6}
														lg={6}
														md={6}
														sm={12}
													>
														<Card
															style={{
																borderRadius: 12,
															}}
														>
															<div
																className="text-truncate"
																style={{
																	paddingBottom:
																		"1.2vh",
																	paddingTop:
																		"1.2vh",
																	paddingLeft:
																		"1vh",
																}}
															>
																<span
																	style={{
																		color:
																			"#343a40",
																		marginLeft:
																			"1vh",
																	}}
																>
																	<b>
																		Doxy
																		Live
																		Visit
																	</b>
																</span>
															</div>
															<CardBody className="border-top py-3">
																<Media>
																	<iframe
																		allow="camera; microphone"
																		src="https://doxy.me/sign-in"
																		height="400"
																		width="900"
																		title="Doxy Live Visit"
																	></iframe>
																</Media>
															</CardBody>
														</Card>
													</Col>
												)}

												{/* <AuscultationWidget {...props} /> */}

												{this.state.spirometry_b ===
													true && (
													<Col
														xl={6}
														lg={6}
														md={6}
														sm={12}
													>
														<Card
															style={{
																borderRadius: 12,
															}}
														>
															<div
																className="text-truncate"
																style={{
																	paddingBottom:
																		"1.2vh",
																	paddingTop:
																		"1.2vh",
																	paddingLeft:
																		"1vh",
																}}
															>
																<span
																	style={{
																		color:
																			"#343a40",
																		marginLeft:
																			"1vh",
																	}}
																>
																	<b>
																		Spirometry
																	</b>
																</span>
															</div>
															<CardBody className="border-top py-3">
																<Media>
																	<Media
																		body
																		className="overflow-hidden"
																	>
																		<img
																			onClick={() =>
																				this.setState(
																					{
																						isOpen: true,
																						photoIndex: this
																							.state
																							.spirometry_index,
																					}
																				)
																			}
																			alt="Spirometry Image"
																			src={
																				spirometry
																			}
																			onError={() => {
																				this.setState(
																					{
																						spirometry_b: false,
																					}
																				);
																			}}
																			style={{
																				height:
																					"53vh",
																				width:
																					"100%",
																			}}
																		></img>
																	</Media>
																</Media>
															</CardBody>
														</Card>
													</Col>
												)}
											</Row>
										</Col>
									</Row>
								</div>
							</PDFExport>
						</Container>
					</div>
				</React.Fragment>
			</Layout>
		);
	}
}

export default Dashboard;
