import React, { useState, useEffect } from "react";
import base_url from "../base_url";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  
  const [done, setDone] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#505d69"
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    props.cardCompleted(event.complete)
    setError(event.error ? event.error.message : "");
  };

  

  const handleSubmit = async ev => {
    
    setProcessing(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (props.clientSecret === undefined){
      props.checkoutError(true)
      props.loadingAnimation(false)
      return;
    }

    const payload = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    })
  
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      console.log(error)
      setProcessing(false);
      props.checkoutError(true)
      props.loadingAnimation(false)
    } else {
      console.log("SUCCESS")
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      props.addPatient()
    }
  };

  if (props.isSubmit == true && done == false){
      setDone(true);
      handleSubmit();
    }

  return (
    <>
      <CardElement className="form-control" id="card-element" options={cardStyle} onChange={handleChange} />
      
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert" style={{color: '#fa755a', marginTop: 5}}>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      
    </>
  );
}
