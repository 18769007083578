// import React from "react";
import base_url from "./base_url"
import useSWR from 'swr'


export default function SwrRequest (props) {
  const fetcher = url => {
    let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    if (base_url === "http://127.0.0.1:5000"){
        headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
  
    }
    else {
        headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") }
    }
  
    fetch(base_url + '/get-table', {
      method: 'post', headers: headers,
      body: JSON.stringify({ doctor_email: window.sessionStorage.getItem("email") })
    })
    .then(r => r.json())
    .then(d => {
      // console.log("Data sent."); 
      props.updateDetails(d);
    })
  }

  // const { data, error } = useSWR("/api/data", fetcher, { refreshInterval: 1000 })
  useSWR("/api/data", fetcher, { refreshInterval: 1000 })
  // props.updateDetails(data)

  return null;
}