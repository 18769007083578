import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";

import { Row, Col, Button, Modal, ModalBody, ModalHeader, Label, FormGroup, ModalFooter } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

import base_url from "../../pages/base_url";

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            sendvideo_modal: false,
        };
    }

    sendTextMessage = () => {
        // console.log(this.state.phone)

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        if (base_url === "http://127.0.0.1:5000") {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
        } else {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: window.sessionStorage.getItem("token"),
            };
        }

        fetch(base_url + "/send-message", {
            method: "post",
            headers: headers,
            body: JSON.stringify({ phone: this.state.phone }),
        })
            .then((Response) => Response.json())
            .then((Result) => {
                // console.log(Result);
                this.setState({ phone: "", sendvideo_modal: false });
            })
            .catch((e) => {
                console.log(e);
            });
    };

    setActiveTab = (num) => {
        this.setState({ activeTab: num });
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {/* <li className="menu-title">{this.props.t('Menu')}</li> */}

                        <li>
                            <Link to="/appointments" className=" waves-effect">
                                <i
                                    style={
                                        window.location.href.includes(
                                            "/appointments"
                                        )
                                            ? { color: "white" }
                                            : { color: "#8590a5" }
                                    }
                                    className="ri-table-fill"
                                ></i>
                                <span
                                    className="ml-1"
                                    style={
                                        window.location.href.includes(
                                            "/appointments"
                                        )
                                            ? { color: "white" }
                                            : { color: "#8590a5" }
                                    }
                                >
                                    Appointments
                                </span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/patients" className=" waves-effect">
                                <i
                                    style={
                                        window.location.href.includes(
                                            "/patients"
                                        )
                                            ? { color: "white" }
                                            : { color: "#8590a5" }
                                    }
                                    className="ri-team-fill"
                                ></i>
                                <span
                                    className="ml-1"
                                    style={
                                        window.location.href.includes(
                                            "/patients"
                                        )
                                            ? { color: "white" }
                                            : { color: "#8590a5" }
                                    }
                                >
                                    Patients
                                </span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/calendar" className=" waves-effect">
                                <i
                                    style={
                                        window.location.href.includes(
                                            "/calendar"
                                        )
                                            ? { color: "white" }
                                            : { color: "#8590a5" }
                                    }
                                    className="ri-calendar-fill"
                                ></i>
                                <span
                                    className="ml-1"
                                    style={
                                        window.location.href.includes(
                                            "/calendar"
                                        )
                                            ? { color: "white" }
                                            : { color: "#8590a5" }
                                    }
                                >
                                    Schedule
                                </span>
                            </Link>
                        </li>

                        {window.sessionStorage.getItem("type") === "admin" && (
                            <li>
                                <Link to="/admin" className=" waves-effect">
                                    <i
                                        style={
                                            window.location.href.includes(
                                                "/admin"
                                            )
                                                ? { color: "white" }
                                                : { color: "#8590a5" }
                                        }
                                        className="ri-admin-fill"
                                    ></i>
                                    <span
                                        className="ml-1"
                                        style={
                                            window.location.href.includes(
                                                "/admin"
                                            )
                                                ? { color: "white" }
                                                : { color: "#8590a5" }
                                        }
                                    >
                                        {/* {this.props.t("Admin")} */}
                                        Admin
                                    </span>
                                </Link>
                            </li>
                        )}

                        <li>
                            <Link onClick={() => this.setState({sendvideo_modal: true})} className=" waves-effect">
                                <i
                                    style={{ color: "#8590a5" }}
                                    className="ri-send-plane-fill"
                                ></i>
                                <span
                                    className="ml-1"
                                    style={{ color: "#8590a5" }}
                                >
                                    Send Video
                                </span>
                            </Link>
                        </li>

                        <Modal
                            isOpen={this.state.sendvideo_modal}
                            toggle={this.tog_static}
                            backdrop="static"
                            centered
                            size="md"
                            
                        >
                            <ModalHeader toggle={() => this.setState({ sendvideo_modal: false })}>
                                Send Video
                            </ModalHeader>
                            <ModalBody>
                                <AvForm style={{ marginLeft: "3.5vh" }} onValidSubmit={this.sendTextMessage}>
                                    <Row>
                                        <Col lg={11}>
                                            <FormGroup>
                                                <Label htmlFor="email">Phone Number</Label>
                                                <InputMask
                                                    mask="(999) 999-9999"
                                                    placeholder="Enter Phone Number"
                                                    value={this.state.phone}
                                                    className="form-control"
                                                    style={this.state.phone ? {fontSize: 16, color: '#505d69', height: '40px'} : {fontSize: 14, color: '#505d69', height: '40px'}}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            phone: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    {(inputProps) => (
                                                        <MaterialInput
                                                            {...inputProps}
                                                            type="tel"
                                                            disableUnderline
                                                        />
                                                    )}
                                                </InputMask>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row><br /></Row>
                                    <ModalFooter>
                                        <Button color="primary">Send Video</Button>
                                    </ModalFooter>

                                </AvForm>

                            </ModalBody>
                        </Modal>

                        {/* <li style={{ marginTop: "58vh" }}>
                            <Row
                                style={{
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    backgroundColor: "transparent",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Col lg={8}>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        placeholder="Enter Phone"
                                        value={this.state.phone}
                                        className="form-control input-color"
                                        style={{
                                            backgroundColor: "#DDDDDD",
                                            marginLeft: "0.5vh",
                                            width: "15vh",
                                        }}
                                        onChange={(e) => {
                                            this.setState({
                                                phone: e.target.value,
                                            });
                                        }}
                                    >
                                        {(inputProps) => (
                                            <MaterialInput
                                                {...inputProps}
                                                type="tel"
                                                disableUnderline
                                            />
                                        )}
                                    </InputMask>
                                    <Button
                                        onClick={this.sendTextMessage}
                                        style={{
                                            backgroundColor: "#0D55B0",
                                            borderColor: "#0D55B0",
                                            width: "15vh",
                                            marginLeft: "0.5vh",
                                            marginTop: "1.5vh",
                                        }}
                                    >
                                        Send Video
                                    </Button>
                                </Col>
                            </Row>
                        </li> */}

                        {/* <Col lg={3}>
                            <FormGroup>
                                <Label htmlFor="validationCustom03">Phone Number</Label>
                                <AvField
                                    name="phonenumber"
                                    placeholder="5165879925"
                                    type="number"
                                    onChange={event => this.setState({ "phone": event.target.value })}
                                    errorMessage="Enter Phone Number"
                                    className="form-control"
                                    value = {this.state.phone}
                                    validate={{ 
                                        required: { value: true, errorMessage: 'Please enter a phone number'}, 
                                        minLength: {value: 10, errorMessage: 'The phone number must be must 10 digits'},
                                        maxLength: {value: 10, errorMessage: 'The phone number must be must 10 digits'}
                                    }}
                                    id="validationCustom03"
                                    autoComplete="nofill"
                                />
                            </FormGroup>
                        </Col> */}

                        {/* <li>
                            <Link to="apps-chat" className=" waves-effect">
                                <i className="ri-chat-1-line"></i>
                                <span className="ml-1">{this.props.t('Chat')}</span>
                            </Link>
                        </li> */}

                        {/* <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-store-2-line"></i>
                                <span className="ml-1">{this.props.t('Ecommerce')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="ecommerce-customers">{this.props.t('Customers')}</Link></li>
                                <li><Link to="ecommerce-add-product">{this.props.t('Add Product')}</Link></li>
                            </ul>
                        </li> */}

                        {/* <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-mail-send-line"></i>
                                <span className="ml-1">{this.props.t('Email')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="email-inbox">{this.props.t('Inbox')}</Link></li>
                                <li><Link to="email-read">{this.props.t('Read Email')}</Link></li>
                            </ul>
                        </li> */}

                        {/* <li>
                            <Link to="apps-kanban-board" className=" waves-effect">
                                <i className="ri-artboard-2-line"></i>
                                <span className="ml-1">{this.props.t('Kanban Board')}</span>
                            </Link>
                        </li> */}

                        {/* <li className="menu-title">{this.props.t('Pages')}</li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-account-circle-line"></i>
                                <span className="ml-1">{this.props.t('Authentication')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="auth-login">{this.props.t('Login')}</Link></li>
                                <li><Link to="auth-register">{this.props.t('Register')}</Link></li>
                                <li><Link to="auth-recoverpw">{this.props.t('Recover Password')}</Link></li>
                                <li><Link to="auth-lock-screen">{this.props.t('Lock Screen')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-profile-line"></i>
                                <span className="ml-1">{this.props.t('Utility')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="pages-starter">{this.props.t('Starter Page')}</Link></li>
                                <li><Link to="pages-maintenance">{this.props.t('Maintenance')}</Link></li>
                                <li><Link to="pages-comingsoon">{this.props.t('Coming Soon')}</Link></li>
                                <li><Link to="pages-timeline">{this.props.t('Timeline')}</Link></li>
                                <li><Link to="pages-faqs">{this.props.t('FAQs')}</Link></li>
                                <li><Link to="pages-pricing">{this.props.t('Pricing')}</Link></li>
                                <li><Link to="pages-404">{this.props.t('Error 404')}</Link></li>
                                <li><Link to="pages-500">{this.props.t('Error 500')}</Link></li>
                            </ul>
                        </li>

                        <li className="menu-title">{this.props.t('Components')}</li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-pencil-ruler-2-line"></i>
                                <span className="ml-1">{this.props.t('UI Elements')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="ui-alerts">{this.props.t('Alerts')}</Link></li>
                                <li><Link to="ui-buttons">{this.props.t('Buttons')}</Link></li>
                                <li><Link to="ui-cards">{this.props.t('Cards')}</Link></li>
                                <li><Link to="ui-carousel">{this.props.t('Carousel')}</Link></li>
                                <li><Link to="ui-dropdowns">{this.props.t('Dropdowns')}</Link></li>
                                <li><Link to="ui-grid">{this.props.t('Grid')}</Link></li>
                                <li><Link to="ui-images">{this.props.t('Images')}</Link></li>
                                <li><Link to="ui-lightbox">{this.props.t('Lightbox')}</Link></li>
                                <li><Link to="ui-modals">{this.props.t('Modals')}</Link></li>
                                <li><Link to="ui-rangeslider">{this.props.t('Range Slider')}</Link></li>
                                <li><Link to="ui-roundslider">{this.props.t('Round Slider')}</Link></li>
                                <li><Link to="ui-session-timeout">{this.props.t('Session Timeout')}</Link></li>
                                <li><Link to="ui-progressbars">{this.props.t('Progress Bars')}</Link></li>
                                <li><Link to="ui-sweet-alert">{this.props.t('Sweet Alerts')}</Link></li>
                                <li><Link to="ui-tabs-accordions">{this.props.t('Tabs & Accordions')}</Link></li>
                                <li><Link to="ui-typography">{this.props.t('Typography')}</Link></li>
                                <li><Link to="ui-video">{this.props.t('Video')}</Link></li>
                                <li><Link to="ui-general">{this.props.t('General')}</Link></li>
                                <li><Link to="ui-rating">{this.props.t('Rating')}</Link></li>
                                <li><Link to="ui-notifications">{this.props.t('Notifications')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="waves-effect">
                                <i className="ri-eraser-fill"></i>
                                <span className="badge badge-pill badge-danger float-right">6</span>
                                <span className="ml-1">{this.props.t('Forms')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="form-elements">{this.props.t('Elements')}</Link></li>
                                <li><Link to="form-validation">{this.props.t('Validation')}</Link></li>
                                <li><Link to="form-advanced">{this.props.t('Advanced Plugins')}</Link></li>
                                <li><Link to="form-editors">{this.props.t('Editors')}</Link></li>
                                <li><Link to="form-uploads">{this.props.t('File Upload')}</Link></li>
                                <li><Link to="form-xeditable">{this.props.t('X-editable')}</Link></li>
                                <li><Link to="form-wizard">{this.props.t('Wizard')}</Link></li>
                                <li><Link to="form-mask">{this.props.t('Mask')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-table-2"></i>
                                <span className="ml-1">{this.props.t('Tables')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="tables-basic">{this.props.t('Basic Tables')}</Link></li>
                                <li><Link to="tables-datatable">{this.props.t('Data Tables')}</Link></li>
                                <li><Link to="tables-responsive">{this.props.t('Responsive Table')}</Link></li>
                                <li><Link to="tables-editable">{this.props.t('Editable Table')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-bar-chart-line"></i>
                                <span className="ml-1">{this.props.t('Charts')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="charts-apex">{this.props.t('Apexcharts')}</Link></li>
                                <li><Link to="charts-chartjs">{this.props.t('Chartjs')}</Link></li>
                                <li><Link to="charts-knob">{this.props.t('Jquery Knob')}</Link></li>
                                <li><Link to="charts-sparkline">{this.props.t('Sparkline')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-brush-line"></i>
                                <span className="ml-1">{this.props.t('Icons')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="icons-remix">{this.props.t('Remix Icons')}</Link></li>
                                <li><Link to="icons-materialdesign">{this.props.t('Material Design')}</Link></li>
                                <li><Link to="icons-dripicons">{this.props.t('Dripicons')}</Link></li>
                                <li><Link to="icons-fontawesome">{this.props.t('Font awesome 5')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-map-pin-line"></i>
                                <span className="ml-1">{this.props.t('Maps')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="maps-google">{this.props.t('Google Maps')}</Link></li>
                                <li><Link to="maps-vector">{this.props.t('Vector Maps')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="ri-share-line"></i>
                                <span className="ml-1">{this.props.t('Multi Level')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="true">
                                <li><Link to="/#">{this.props.t('Level 1.1')}</Link></li>
                                <li><Link to="/#" className="has-arrow">{this.props.t('Level 1.2')}</Link>
                                    <ul className="sub-menu" aria-expanded="true">
                                        <li><Link to="/#">{this.props.t('Level 2.1')}</Link></li>
                                        <li><Link to="/#">{this.props.t('Level 2.2')}</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    return { ...state.Layout };
};

export default withRouter(
    connect(mapStatetoProps, {
        changeLayout,
        changeSidebarTheme,
        changeSidebarType,
        changeLayoutWidth,
        changePreloader,
    })(withNamespaces()(SidebarContent))
);
