import React, { Component } from 'react';
import { Col, Card, CardBody, Media, Row } from "reactstrap";
import Dictaphone from './dictaphone';
import base_url from "../base_url";

class ChiefComplaintWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveButton: false,
            current:"",
            recording:false,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleVoice = this.handleVoice.bind(this);
    }

    handleVoice(k){
        this.setState({current:this.state.current + k});
        this.setState({saveButton: true});
    }

    handleClick() {


        if (this.props.title === "Chief Complaint"){
            const url = base_url + "/update-complaint";
            fetch(url, {
            method: 'post', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") },
            body: JSON.stringify({mrn:this.props.mrn, complaint:this.state.current})
        }).then((Response) => Response.json()).then((Result) => {
            console.log(Result)});
        }
        else{
            const url = base_url + "/update-notes";
            fetch(url, {
            method: 'post', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") },
            body: JSON.stringify({mrn:this.props.mrn, notes:this.state.current})
        }).then((Response) => Response.json()).then((Result) => {
            console.log(Result)});
        }

        
        this.setState({ saveButton: false });
    }

    componentDidMount() {

        setTimeout(() => {this.setState({current:this.props.content});}, 2000);
        
    }

    render() {

        
        return (
            <React.Fragment>
                {
                    <Col md={this.props.size} style={{ backgroundColor: 'transparent', paddingLeft: 0, paddingRight: 0 }}>
                        <Card style={{ borderRadius: 12 }}>
                            {/* <CardHeader className="mt-0" style={{ backgroundColor: '#5664d2' }}>
                                <h5 className="mt-1" style={{ color: 'white' }}>{this.props.title} </h5>
                            </CardHeader> */}
                            <CardBody >

                            { this.props.title !== "Chief Complaint" && (
                            <Dictaphone listening = {this.state.recording} change = {this.handleVoice}/>)}
                                <Media>
                                    <Media style = {{marginLeft:"0vh", backgroundColor: 'transparent', flex: 0.95}} body className="overflow-hidden">
                                        <Row>
                                            <h6 className="mt-1" style={{marginLeft: "1.4vh"}}>{this.props.title} </h6> 


                                            {this.state.recording === true && this.props.title !== "Chief Complaint" && <i style = {{marginLeft:"1vh"}} onClick={() => this.setState({recording:false})} className={'ri-mic-line font-size-18'}></i>}

                                            {this.state.recording === false && this.props.title !== "Chief Complaint" && <i style = {{marginLeft:"1vh"}} onClick={() => this.setState({recording:true})} className={'ri-mic-off-fill font-size-18 color-grey'}></i>}
                                        </Row>
                                        {/* <h4 className="mb-0">{this.props.title} </h4> */}
                                        <textarea value = {this.state.current} onChange={e => {this.setState({ saveButton: true, current:e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)})}} style={{ width: "100%", "height": 73.5, "resize": "none", "border": "none", "outline": "none", color: '#505d69' }} className="font-size-14 mt-0"></textarea>

                                    </Media>

                                    
                                    <div className="text-primary">
                                        {this.state.saveButton && <i style={{position: "absolute", marginTop: -5}} onClick={this.handleClick} className={'ri-check-line' + " font-size-24"}></i>}
                                        
                                    </div>
                                    


                                   
                                </Media>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </React.Fragment>
        );
    }
}

export default ChiefComplaintWidget;