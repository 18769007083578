import React, { Component } from "react";

import { Alert, Button, Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container } from "reactstrap";
import Rating from "react-rating";
import RatingTooltip from "react-rating-tooltip";
import Select from "react-select";
import queryString from 'query-string';

// import images
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img7 from "../../assets/images/small/img-7.jpg";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo-full.png";

import base_url from "../base_url";


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const optionGroup = [
	{
		options: [
			{ label: "Emergency room", value: "Emergency room" },
			{ label: "Urgent Care", value: "Urgent Care" },
            { label: "Next available PCP appointment", value: "Next available PCP appointment" },
            { label: "Delayed seeking care", value: "Delayed seeking care" }
		]
	},
];

const optionGroup2 = [
	{
		options: [
			{ label: "Yes", value: "Yes" },
			{ label: "No", value: "No" },
		]
	},
];

class UiCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "UI Elements", link : "#" },
                { title : "Cards", link : "#" },
            ],

            recommend: "",
            selectedGroup: null,
            selectedValue: "",
            selectedGroup2: null,
            selectedValue2: "",
            question_missing: false,
            friendly: "",
            convenient: "",
            additional: "",
            future_tests: "",
            success: false,
            failure: false,

            tooltipContent: ["1 - Very Unlikely", "2", "3", "4", "5", "6", "7", "8", "9", "10 - Very Likely"],
            tooltipContent2: ["1 - Very Unfriendly", "2", "3", "4", "5", "6", "7", "8", "9", "10 - Very Friendly"],
            tooltipContent3: ["1 - Very Inconvenient", "2", "3", "4", "5", "6", "7", "8", "9", "10 - Very Convenient"],

            starStyle: {
                height: '28px',
                backgroundColor: 'transparent',
                paddingLeft: '2px',
                paddingRight: '2px',
                color: '#F58220',
                lineHeight: '28px',
                marginLeft: '5px',
                marginRight: '5px',
            },


            styleConfig: {
                counterStyle: {
                  height: '28px',
                  backgroundColor: '#F58220',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  color: '#FFF',
                  lineHeight: '28px',
                },
                starContainer: {
                  fontSize: '24px',
                  backgroundColor: 'transparent',
                  height: '28px',
                },
                statusStyle: {
                  height: '28px',
                  backgroundColor: '#F58220',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  color: '#FFF',
                  lineHeight: '28px',
                  minWidth: '100px',
                  fontSize: '18px',
                  textAlign: 'center',
                },
                tooltipStyle: {
                  fontSize: '14px',
                  padding: '5px',
                }
            }
        };
    }

    handleSelectGroup = selectedGroup => {
		this.setState({ selectedGroup, selectedValue: selectedGroup.value });
    };

    handleSelectGroup2 = selectedGroup2 => {
		this.setState({ selectedGroup2, selectedValue2: selectedGroup2.value });
    };


    handleSubmit = () => {

        if (this.state.selectedValue2 === ""){
            this.setState({question_missing: true})
        }
        else {
            let url = window.location.href;
            let mrn = ""
            let ind = 0

            try {
                ind = url.indexOf("=") + 1
                mrn = url.substring(ind)
            } catch (error) {
                mrn = ""
            }

            // console.log(mrn)


            let headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };


            fetch(base_url + "/feedback", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    recommend: this.state.recommend, 
                    convenient: this.state.convenient, 
                    friendly: this.state.friendly, 
                    alternative: this.state.selectedValue, 
                    comments: this.state.additional,
                    non_covid: this.state.selectedValue2,
                    future_tests: this.state.future_tests,
                    mrn: mrn 
                })
            })
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log(data)
                if (data.status === "success"){
                    
                    this.setState({
                        recommend: "",
                        convenient: "", 
                        friendly: "", 
                        alternative: "", 
                        comments: "",
                        future_tests: "",
                        success: true,
                        question_missing: false,
                    })

                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 2000)
                }
                else if (data.status === "failed"){
                    this.setState({
                        failure: true
                    })
                }
            });
        }

        
    }
    
    
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                    
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={8}>
                                
                                <Card>
                                    <CardHeader style={{ backgroundColor: 'white', textAlign: 'center' }}>
                                        <img className="pt-4" alt="Next Medical" width="200" src={logo} />
                                    </CardHeader>
                                    <CardBody style={{textAlign: 'center'}}>
                                        <CardTitle className="mt-2"><h4>Please give us your feedback!</h4></CardTitle>
                                        <CardText className="mt-3">Doing so will help us continue to expand on our mission of making healthcare more accessible for everyone.</CardText>
                                        
                                        <br />
                                        
                                        <h6 className="mt-0">How likely are you to recommend Next Medical to a friend?</h6>
                                        <Col style={{backgroundColor: 'transparent'}}>
                                            <div className="p-1 text-center">
                                            <RatingTooltip
                                                max={10}
                                                onChange={rate => this.setState({ recommend: rate })}
                                                tooltipContent={this.state.tooltipContent}
                                                styleConfig = {this.state.styleConfig}
                                                ActiveComponent={
                                                <i
                                                    key={"active_1"}
                                                    className="mdi mdi-star text-primary"
                                                    style={this.state.starStyle}
                                                />
                                                }
                                                InActiveComponent={
                                                <i
                                                    key={"active_01"}
                                                    className="mdi mdi-star-outline text-muted"
                                                    style={this.state.starStyle}
                                                />
                                                }
                                            />
                                            </div>
                                        </Col>

                                        <br />
                                        <br />
                                        
                                        <h6 className="mt-0">If Next Medical was not available, where would you have gone?</h6>
                                        <Row className="mt-3">
                                            <Col lg={4}></Col>
                                            <Col lg={4} style={{textAlign: 'left'}}>
                                                <Select
                                                    styles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    value={this.state.selectedGroup}
                                                    onChange={this.handleSelectGroup}
                                                    options={optionGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                        </Row>

                                        <br />

                                        <h6 className="mt-3">How friendly was your nurse?</h6>
                                        <Col style={{backgroundColor: 'transparent'}}>
                                            <RatingTooltip
                                                max={10}
                                                onChange={rate => this.setState({ friendly: rate })}
                                                tooltipContent={this.state.tooltipContent2}
                                                styleConfig = {this.state.styleConfig}
                                                ActiveComponent={
                                                <i
                                                    key={"active_1"}
                                                    className="mdi mdi-star text-primary"
                                                    style={this.state.starStyle}
                                                />
                                                }
                                                InActiveComponent={
                                                <i
                                                    key={"active_01"}
                                                    className="mdi mdi-star-outline text-muted"
                                                    style={this.state.starStyle}
                                                />
                                                }
                                            />
                                        </Col>

                                        <br />
                                        <br />

                                        <h6 className="mt-0">How convenient was your appointment time?</h6>
                                        <Col style={{backgroundColor: 'transparent'}}>
                                            <RatingTooltip
                                                max={10}
                                                onChange={rate => this.setState({ convenient: rate })}
                                                tooltipContent={this.state.tooltipContent3}
                                                styleConfig = {this.state.styleConfig}
                                                ActiveComponent={
                                                <i
                                                    key={"active_1"}
                                                    className="mdi mdi-star text-primary"
                                                    style={this.state.starStyle}
                                                />
                                                }
                                                InActiveComponent={
                                                <i
                                                    key={"active_01"}
                                                    className="mdi mdi-star-outline text-muted"
                                                    style={this.state.starStyle}
                                                />
                                                }
                                            />
                                        </Col>

                                        <br />
                                        <br />

                                        <h6 className="mt-0">Would you use Next Medical home visits for your non-COVID related healthcare needs?</h6>
                                        <Row className="mt-3" style={{backgroundColor: 'transparent'}}>
                                            <Col lg={5}></Col>
                                            <Col lg={2} style={{textAlign: 'left'}}>
                                                <Select
                                                    styles={{
                                                        menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                        }),
                                                        control: (provided, state) => ({
                                                        ...provided,
                                                        borderColor: this.state.question_missing
                                                            ? "#ff3d60"
                                                            : "#ced4da",
                                                        }),
                                                    }}
                                                    value={this.state.selectedGroup2}
                                                    onChange={this.handleSelectGroup2}
                                                    options={optionGroup2}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                        
                                        

                                        <br />
                                        <br />

                                        <h6 className="mt-0">Which health tests would you like us to add in the future?</h6>
                                        <Row>
                                            <Col lg={2}></Col>
                                            <Col lg={8}>
                                                <textarea onChange={(event) => this.setState({future_tests: event.target.value})} id="basicpill-address-input1" className="form-control mt-2" rows="2" placeholder="Tell us any tests that you would like."></textarea>
                                            </Col>
                                        </Row>

                                        <br />
                                        <br />
                                        
                                        <h6 className="mt-0">Do you have anything else you would like to tell us?</h6>
                                        <Row>
                                            <Col lg={2}></Col>
                                            <Col lg={8}>
                                                <textarea onChange={(event) => this.setState({additional: event.target.value})} id="basicpill-address-input1" className="form-control mt-2" rows="2" placeholder="Please leave us any additional feedback here."></textarea>
                                            </Col>
                                        </Row>

                                        <br />
                                        <br />

                                        <Button onClick={this.handleSubmit} color="primary" size="lg">Submit Form</Button>

                                        <br />
                                        <br />
                                        <br />

                                        <Alert hidden={!this.state.success} color="success">Your form was successfully submitted!</Alert>
                                        <Alert hidden={!this.state.failure} color="danger">Your form has either failed to submit or has already been submitted previously.</Alert>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default UiCards;
