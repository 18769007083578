import React, { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import {Alert, Card, CardBody, FormGroup, CardHeader, Col, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, UncontrolledTooltip, Button, Modal, Label, Row,ModalHeader,ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import base_url from "../base_url"


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
 
const Biller = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const handleUpdate = () => {
      let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
      if (base_url == "http://127.0.0.1:5000" || base_url == "http://3.135.192.56:8080"){
          headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }

      }
      else {
          headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") }
      }

      fetch(base_url + '/add-biller', {
        method: 'post', 
        headers: headers,
        body: JSON.stringify({biller_name: name, biller_email: email, doctor_email: window.sessionStorage.getItem("email")})
      })
        .then((Response) => Response.json())
        .then((Result) => {
            // console.log(Result)
            // console.log(Result)
            getBiller()
        })
        .catch((e) => {
          console.log(e)
        });
    }

    const getBiller = () => {
      let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
      if (base_url == "http://127.0.0.1:5000" || base_url == "http://3.135.192.56:8080"){
          headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }

      }
      else {
          headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") }
      }

      fetch(base_url + '/get-biller', {
        method: 'post',
        headers: headers,
        body: JSON.stringify({doctor_email: window.sessionStorage.getItem("email")})
      })
        .then((Response) => Response.json())
        .then((Result) => {
            // console.log(Result)
            setName(Result.data.biller_name)
            setEmail(Result.data.biller_email)
        })
        .catch((e) => {
          console.log(e)
        });

    }
    

    useEffect(() => {

      getBiller()

    }, [])

    return (
      <React.Fragment>
          <Col lg={12}>
              <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Biller</h4>
                    <Row>
                      <Col lg={5} sm={5}>
                        <FormGroup>
                          <Label htmlFor="validationCustom01">Name</Label>
                          <Input
                            name="name"
                            placeholder="John Appleseed"
                            type="text"
                            errorMessage="Enter Name"
                            onChange={(event) => {
                              setName(event.target.value);
                            }}
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter the full name",
                              },
                            }}
                            autoComplete="none"
                            value={name}
                            id="validationCustom01"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg={5} sm={5}>
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Email</Label>
                            <Input
                              name="email"
                              placeholder="email@email.com"
                              type="text"
                              errorMessage="Enter Email"
                              onChange={(event) => {
                                setEmail(event.target.value);
                              }}
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a valid email",
                                },
                              }}
                              autoComplete="none"
                              value={email}
                              id="validationCustom01"
                            />
                          </FormGroup>
                      </Col>


                      <Col lg={2} sm={2}>
                        <Col>
                          <Row>
                            <Label htmlFor="validationCustom01">Update</Label>
                          </Row>
                          <Row>
                            <Button color="primary" onClick={() => handleUpdate()}>Update</Button>
                          </Row>
                        </Col>
                      </Col>
                    </Row>

                  </CardBody>
                
              </Card>
          </Col>
      </React.Fragment>
    )
 
}

export default Biller;