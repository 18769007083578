import { Redirect } from "react-router-dom";
import React, { Component } from "react";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

// import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";

import base_url from "../base_url";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import AutoLogout from "../AutoLogout";
import SwrRequest from "../SwrRequest";

import "./orders.css"

// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { browserHistory } from "react-router";

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                // { title: "Home", link: "#" },
                // { title: "Patients", link: "#" },
            ],
            activeTab: "1",
            rows: [],
            reload_count: 0,

            data_today: [],
            data_week: [],
            data_month: [],
            data_alltime: [],
            data_upcoming: [],

            selectedDoctor: { label: "All", value: "All" },
            doctor_name: "All",
            doctor_names: [],

            sort_date: null,
            sort_name: null,
            sort_age: null,
            sort_sex: null,
            sort_complaint: null,
            sort_time: "asc",
            sort_doctor: null,
        };
        this.toggleTab = this.toggleTab.bind(this);
        this.getData = this.getData.bind(this);
        this.updateDetails = this.updateDetails.bind(this);
        this.handleSelectDoctor = this.handleSelectDoctor.bind(this);
        this.sort_data = this.sort_data.bind(this);
    }

    handleSelectDoctor = (selectedDoctor) => {
        // console.log(selectedDoctor)
        this.setState({ selectedDoctor: selectedDoctor });
        this.setState({ doctor_name: selectedDoctor.value });
    };

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    sort_data = (data) => {
      let arr = data;
      // let sort_date = this.state.sort_date

      if (this.state.sort_date === 'asc'){
        arr = arr.sort(
          function(a,b) {
              let year_a = parseInt(a.date.slice(7, 10))
              let month_a = parseInt(a.date.slice(3, 5)) - 1
              let day_a = parseInt(a.date.slice(0, 2))
              let date_a = new Date(year_a, month_a, day_a)

              let year_b = parseInt(b.date.slice(7, 10))
              let month_b = parseInt(b.date.slice(3, 5)) - 1
              let day_b = parseInt(b.date.slice(0, 2))
              let date_b = new Date(year_b, month_b, day_b)

              return date_a - date_b
          }
        );
      }
      else if (this.state.sort_date === 'desc'){
        arr = arr.sort(
          function(a,b) {
            let year_a = parseInt(a.date.slice(7, 10))
              let month_a = parseInt(a.date.slice(3, 5)) - 1
              let day_a = parseInt(a.date.slice(0, 2))
              let date_a = new Date(year_a, month_a, day_a)

              let year_b = parseInt(b.date.slice(7, 10))
              let month_b = parseInt(b.date.slice(3, 5)) - 1
              let day_b = parseInt(b.date.slice(0, 2))
              let date_b = new Date(year_b, month_b, day_b)

              return date_b - date_a
          }
        );
      }

      if (this.state.sort_name === 'asc'){
        arr = arr.sort(
          function(a,b) {
              return a.name.localeCompare(b.name)
          }
        );
      }
      else if (this.state.sort_name === 'desc'){
        arr = arr.sort(
          function(a,b) {
              return b.name.localeCompare(a.name)
          }
        );
      }

      if (this.state.sort_age === 'asc'){
        arr = arr.sort(
          function(a,b) {
              return a.age - b.age
          }
        );
      }
      else if (this.state.sort_age === 'desc'){
        arr = arr.sort(
          function(a,b) {
              return b.age - a.age
          }
        );
      }

      if (this.state.sort_sex === 'asc'){
        arr = arr.sort(
          function(a,b) {
              return a.sex.localeCompare(b.sex)
          }
        );
      }
      else if (this.state.sort_sex === 'desc'){
        arr = arr.sort(
          function(a,b) {
              return b.sex.localeCompare(a.sex)
          }
        );
      }

      if (this.state.sort_complaint === 'asc'){
        arr = arr.sort(
          function(a,b) {
              return a.complaint.localeCompare(b.complaint)
          }
        );
      }
      else if (this.state.sort_complaint === 'desc'){
        arr = arr.sort(
          function(a,b) {
              return b.complaint.localeCompare(a.complaint)
          }
        );
      }

      if (this.state.sort_time === 'asc'){
        arr = arr.sort(
          function(a,b) {
              return a.time_int - b.time_int
          }
        );
      }
      else if (this.state.sort_time === 'desc'){
        arr = arr.sort(
          function(a,b) {
              return b.time_int - a.time_int
          }
        );
      }

      if (this.state.sort_doctor === 'asc'){
        arr = arr.sort(
          function(a,b) {
              return a.doctor.localeCompare(b.doctor)
          }
        );
      }
      else if (this.state.sort_doctor === 'desc'){
        arr = arr.sort(
          function(a,b) {
              return b.doctor.localeCompare(a.doctor)
          }
        );
      }

      // console.log(arr)
      return arr;

    }

    updateDetails(data) {
        var today_date = new Date();
        var dd = String(today_date.getDate()).padStart(2, "0");
        var mm = String(today_date.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today_date.getFullYear();

        var today = new Date(yyyy, mm, dd);

        let data_today_temp = [];
        let data_week_temp = [];
        let data_month_temp = [];
        let data_alltime_temp = [];
        let data_upcoming_temp = [];

        // console.log(data)
        // this.sort_data()

        for (let i = 0; i < data["data"].length; i++) {
            let tempDic = {};
            tempDic["name"] = data["data"][i].name;
            tempDic["age"] = data["data"][i].age;
            tempDic["sex"] = data["data"][i].sex;
            tempDic["complaint"] = data["data"][i].complaint;
            tempDic["date"] = data["data"][i].date;
            tempDic["doctor"] = data["data"][i].doctor_name;
            tempDic["time_int"] = parseInt(data["data"][i].time);

            let tempTime = data["data"][i].time;
            let tempString = "";
            if (tempTime >= 1200 && tempTime < 2400) {
                if (parseInt(tempTime.slice(0, 2)) === 12) {
                    tempString =
                        tempTime.slice(0, 2) + ":" + tempTime.slice(2, 4) + " pm";
                } else {
                    tempString =
                        parseInt(tempTime.slice(0, 2)) -
                        12 +
                        ":" +
                        tempTime.slice(2, 4) +
                        " pm";
                }
            } else {
                if (tempTime.toString().length === 3) {
                    tempString =
                        tempTime.toString().slice(0, 1) +
                        ":" +
                        tempTime.toString().slice(1, 3) +
                        " am";
                } else {
                    tempString =
                        tempTime.toString().slice(0, 2) +
                        ":" +
                        tempTime.toString().slice(2, 4) +
                        " am";
                }
            }

            tempDic["time"] = tempString;
            if (data["data"][i]["visit_status"] === 0) {
                tempDic["details"] = (
                    <Link to={"/dashboard/" + data["data"][i].mrn}>
            <Button
              disabled
              style={{
                backgroundColor: "grey",
                borderColor: "grey",
              }}
            >
              Details
            </Button>
          </Link>
                );
            } else {
                tempDic["details"] = (
                    <Link to={"/dashboard/" + data["data"][i].mrn}>
            <Button
              style={{
                backgroundColor: "#0D55B0",
                borderColor: "#0D55B0",
              }}
            >
              Details
            </Button>
          </Link>
                );
            }

            let date_arr = tempDic["date"].split("/");
            let itemDate = new Date(date_arr[2], date_arr[0], date_arr[1]);
            // console.log(itemDate, today)
            const factor = 1000 * 60 * 60 * 24;
            let time_diff = (today - itemDate) / factor;

            if (time_diff === 0) {
                data_today_temp.push(tempDic);
            }

            if (time_diff >= 0 && time_diff <= 7) {
                data_week_temp.push(tempDic);
            }

            if (time_diff >= 0 && time_diff <= 30) {
                data_month_temp.push(tempDic);
            }

            if (time_diff < 0) {
                data_upcoming_temp.push(tempDic);
            }

            data_alltime_temp.push(tempDic);
        }
        // console.log(currentData)

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        if (
            base_url === "http://127.0.0.1:5000" ||
            base_url === "http://3.135.192.56:8080"
        ) {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
        } else {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: window.sessionStorage.getItem("token"),
            };
        }

        fetch(base_url + "/get-doctors", {
                method: "post",
                headers: headers,

                body: JSON.stringify({
                    practice_email: window.sessionStorage.getItem("email"),
                }),
            })
            .then((Response) => Response.json())
            .then((Result) => {
                let doctor_names = Result.data;
                doctor_names[0].options.push({ label: "All", value: "All" });
                this.setState({ doctor_names: Result.data });
                // this.setState({"selectedDoctor": {"label": "All", "value": "All"}})
            })
            .catch((e) => {
                console.log(e);
            });

        this.setState({
            rows: data_alltime_temp,
            data_today: data_today_temp,
            data_week: data_week_temp,
            data_month: data_month_temp,
            data_alltime: data_alltime_temp,
            data_upcoming: data_upcoming_temp,
        });
    }

    getData() {
        var today_date = new Date();
        var dd = String(today_date.getDate()).padStart(2, "0");
        var mm = String(today_date.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today_date.getFullYear();

        var today = new Date(yyyy, mm, dd);

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        if (
            base_url === "http://127.0.0.1:5000" ||
            base_url === "http://3.135.192.56:8080"
        ) {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
        } else {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: window.sessionStorage.getItem("token"),
            };
        }

        setTimeout(() => {
            console.log("fetched");

            fetch(base_url + "/get-table", {
                    method: "post",
                    headers: headers,

                    body: JSON.stringify({
                        doctor_email: window.sessionStorage.getItem("email"),
                    }),
                })
                .then((Response) => Response.json())
                .then((Result) => {
                    // console.log(Result)
                    // let currentData = [];

                    let data_today_temp = [];
                    let data_week_temp = [];
                    let data_month_temp = [];
                    let data_alltime_temp = [];
                    let data_upcoming_temp = [];

                    for (let i = 0; i < Result["data"].length; i++) {
                        let tempDic = {};
                        tempDic["name"] = Result["data"][i].name;
                        tempDic["age"] = Result["data"][i].age;
                        tempDic["sex"] = Result["data"][i].sex;
                        tempDic["complaint"] = Result["data"][i].complaint;
                        tempDic["date"] = Result["data"][i].date;
                        tempDic["doctor"] = Result["data"][i].doctor_name;

                        let tempTime = Result["data"][i].time;
                        let tempString = "";
                        if (tempTime >= 1200 && tempTime < 2400) {
                            if (parseInt(tempTime.slice(0, 2)) === 12) {
                                tempString =
                                    tempTime.slice(0, 2) + ":" + tempTime.slice(2, 4) + " pm";
                            } else {
                                tempString =
                                    parseInt(tempTime.slice(0, 2)) -
                                    12 +
                                    ":" +
                                    tempTime.slice(2, 4) +
                                    " pm";
                            }
                        } else {
                            if (tempTime.toString().length === 3) {
                                tempString =
                                    tempTime.toString().slice(0, 1) +
                                    ":" +
                                    tempTime.toString().slice(1, 3) +
                                    " am";
                            } else {
                                tempString =
                                    tempTime.toString().slice(0, 2) +
                                    ":" +
                                    tempTime.toString().slice(2, 4) +
                                    " am";
                            }
                        }

                        tempDic["time"] = tempString;
                        if (Result["data"][i]["visit_status"] === 0) {
                            tempDic["details"] = (
                                <Link to={"/dashboard/" + Result["data"][i].mrn}>
                  <Button
                    disabled
                    style={{
                      backgroundColor: "grey",
                      borderColor: "grey",
                    }}
                  >
                    Details
                  </Button>
                </Link>
                            );
                        } else {
                            tempDic["details"] = (
                                <Link to={"/dashboard/" + Result["data"][i].mrn}>
                  <Button
                    style={{
                      backgroundColor: "#0D55B0",
                      borderColor: "#0D55B0",
                    }}
                  >
                    Details
                  </Button>
                </Link>
                            );
                        }

                        let date_arr = tempDic["date"].split("/");
                        let itemDate = new Date(date_arr[2], date_arr[0], date_arr[1]);
                        // console.log(itemDate, today)
                        const factor = 1000 * 60 * 60 * 24;
                        let time_diff = (today - itemDate) / factor;

                        if (time_diff === 0) {
                            data_today_temp.push(tempDic);
                        }

                        if (time_diff >= 0 && time_diff <= 7) {
                            data_week_temp.push(tempDic);
                        }

                        if (time_diff >= 0 && time_diff <= 30) {
                            data_month_temp.push(tempDic);
                        }

                        if (time_diff < 0) {
                            data_upcoming_temp.push(tempDic);
                        }

                        data_alltime_temp.push(tempDic);
                    }
                    // console.log(currentData)

                    this.setState({
                        rows: data_alltime_temp,
                        data_today: data_today_temp,
                        data_week: data_week_temp,
                        data_month: data_month_temp,
                        data_alltime: data_alltime_temp,
                        data_upcoming: data_upcoming_temp,
                    });
                });

            fetch(base_url + "/get-doctors", {
                    method: "post",
                    headers: headers,

                    body: JSON.stringify({
                        practice_email: window.sessionStorage.getItem("email"),
                    }),
                })
                .then((Response) => Response.json())
                .then((Result) => {
                    let doctor_names = Result.data;
                    doctor_names[0].options.push({
                        label: "All",
                        value: "All",
                    });
                    this.setState({ doctor_names: Result.data });
                    this.setState({
                        selectedDoctor: { label: "All", value: "All" },
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 500);
    }

    componentDidMount() {
        const isLoggedIn = window.sessionStorage.getItem("isLoggedIn");
        if (isLoggedIn !== "true") {
            return <Redirect to="/login" />;
        }
        this.getData();
        document
            .getElementsByClassName("pagination")[0]
            .classList.add("pagination-rounded");
    }

    render() {
        const isLoggedIn = window.sessionStorage.getItem("isLoggedIn");
        if (isLoggedIn !== "true") {
            return <Redirect to="/login" />;
        }

        let patient_data = [];
        let final_patient_data = [];
        if (this.state.activeTab === "1") {
            patient_data = this.state.data_today;
        } else if (this.state.activeTab === "2") {
            patient_data = this.state.data_week;
        } else if (this.state.activeTab === "3") {
            patient_data = this.state.data_month;
        } else if (this.state.activeTab === "4") {
            patient_data = this.state.data_alltime;
        } else if (this.state.activeTab === "5") {
            patient_data = this.state.data_upcoming;
        }

        if (this.state.doctor_name !== "All") {
            for (let i = 0; i < patient_data.length; i++) {
                if (patient_data[i].doctor === this.state.doctor_name) {
                    final_patient_data.push(patient_data[i]);
                }
            }
        } else {
            final_patient_data = patient_data;
            // console.log(final_patient_data)
        }

        final_patient_data = this.sort_data(final_patient_data)

        // final_patient_data = final_patient_data.sort(
        //   function(a,b) {
        //       return a.time_int - b.time_int
        //   }
        // );

        // console.log(final_patient_data)

        const data = {
            columns: [
                // {
                //     label: <div className="custom-control custom-checkbox"> <Input type="checkbox" className="custom-control-input" id="ordercheck" /><Label className="custom-control-label" htmlFor="ordercheck">&nbsp;</Label></div>,
                //     field: "checkbox",
                // 
                //     width: 28
                // },
                {
                    label: <Button 
                            id="btn" 
                            onClick={() => {
                              if (this.state.sort_date === "asc"){
                                this.setState({sort_date: "desc"})
                              }
                              else {
                                this.setState({sort_date: "asc"})
                              }
                              this.setState({sort_name: null, sort_age: null, sort_sex: null, sort_complaint: null, sort_time: null, sort_doctor: null})
                            }} 
                            color="primary">Apt Date</Button>,
                    field: "date",
                    width: 135,
                },
                {
                    label: <Button 
                            id="btn" 
                            onClick={() => {
                              if (this.state.sort_name === "asc"){
                                this.setState({sort_name: "desc"})
                              }
                              else {
                                this.setState({sort_name: "asc"})
                              }
                              this.setState({sort_date: null, sort_age: null, sort_sex: null, sort_complaint: null, sort_time: null, sort_doctor: null})
                            }} 
                            color="primary">Name</Button>,
                    field: "name",
                    width: 78,
                },
                {
                    label: <Button 
                            id="btn" 
                            onClick={() => {
                              if (this.state.sort_age === "asc"){
                                this.setState({sort_age: "desc"})
                              }
                              else {
                                this.setState({sort_age: "asc"})
                              }
                              this.setState({sort_date: null, sort_name: null, sort_sex: null, sort_complaint: null, sort_time: null, sort_doctor: null})
                            }} 
                            color="primary">Age</Button>,
                    field: "age",
                    width: 120,
                },
                {
                    label: <Button 
                            id="btn" 
                            onClick={() => {
                              if (this.state.sort_sex === "asc"){
                                this.setState({sort_sex: "desc"})
                              }
                              else {
                                this.setState({sort_sex: "asc"})
                              }
                              this.setState({sort_date: null, sort_name: null, sort_age: null, sort_complaint: null, sort_time: null, sort_doctor: null})
                            }} 
                            color="primary">Sex</Button>,
                    field: "sex",
                    width: 120,
                },
                {
                    label: <Button 
                            id="btn" 
                            onClick={() => {
                              if (this.state.sort_complaint === "asc"){
                                this.setState({sort_complaint: "desc"})
                              }
                              else {
                                this.setState({sort_complaint: "asc"})
                              }
                              this.setState({sort_date: null, sort_name: null, sort_age: null, sort_sex: null, sort_time: null, sort_doctor: null})
                            }} 
                            color="primary">Chief Complaint</Button>,
                    field: "complaint",
                    width: 48,
                },

                {
                    label: <Button 
                            id="btn" 
                            onClick={() => {
                              if (this.state.sort_time === "asc"){
                                this.setState({sort_time: "desc"})
                              }
                              else {
                                this.setState({sort_time: "asc"})
                              }
                              this.setState({sort_date: null, sort_name: null, sort_age: null, sort_sex: null, sort_complaint: null, sort_doctor: null})
                            }} 
                            color="primary">Visit Time</Button>,
                    field: "time",
                    width: 110,
                },
                {
                    label: <Button 
                            id="btn" 
                            onClick={() => {
                              if (this.state.sort_doctor === "asc"){
                                this.setState({sort_doctor: "desc"})
                              }
                              else {
                                this.setState({sort_doctor: "asc"})
                              }
                              this.setState({sort_date: null, sort_name: null, sort_age: null, sort_sex: null, sort_complaint: null, sort_time: null})
                            }} 
                            color="primary">Doctor Name</Button>,
                    field: "doctor",
                    width: 110,
                },
                {
                    label: "Details",
                    field: "details",
                    width: 110,
                },
            ],
            rows: final_patient_data,
        };

        return (
            <React.Fragment>
        <AutoLogout />
        <SwrRequest updateDetails={this.updateDetails} />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Appointments"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="pt-0">
                    <Nav 
                        className="mb-2" 
                        // style={{
                        //     backgroundColor: "transparent",
                        //     flex: 1,
                        //     alignSelf: "center",
                        //     justifyContent: "flex-end",
                        // }}
                        >
                        <NavItem 
                            // style={{
                            //     alignItems: "flex-end",
                            //     marginLeft: "auto",
                            // }}
                        >
                            <Col>
                                <Link
                                    to={{
                                    pathname: "/calendar",
                                    }}
                                >
                                    <Button style = {{marginLeft:"-1.4vh"}} color="primary" className="p-2 mt-3 mb-2" >
                                    <i className="mdi mdi-plus mr-2"></i> Add Patient
                                    </Button>
                                </Link>
                            </Col>
                        </NavItem>
                    </Nav>
                    <Nav tabs className="nav-tabs-custom mb-4">
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("5");
                          }}
                          className={classnames(
                            {
                              active: this.state.activeTab === "5",
                            },
                            "font-weight-bold p-3"
                          )}
                        >
                          Upcoming
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                          className={classnames(
                            {
                              active: this.state.activeTab === "1",
                            },
                            "font-weight-bold p-3"
                          )}
                        >
                          Today
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                          className={classnames(
                            {
                              active: this.state.activeTab === "2",
                            },
                            "p-3 font-weight-bold"
                          )}
                        >
                          Last Week
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("3");
                          }}
                          className={classnames(
                            {
                              active: this.state.activeTab === "3",
                            },
                            " p-3 font-weight-bold"
                          )}
                        >
                          Last Month
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("4");
                          }}
                          className={classnames(
                            {
                              active: this.state.activeTab === "4",
                            },
                            " p-3 font-weight-bold"
                          )}
                        >
                          All Time
                        </NavLink>
                      </NavItem>
                      <NavItem
                        style={{
                          backgroundColor: "transparent",
                          flex: 1,
                          alignSelf: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Col
                          xl={3}
                          lg={4}
                          md={6}
                          sm={12}
                          style={{
                            alignItems: "flex-end",
                            marginLeft: "auto",
                          }}
                        >
                          <Select
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            menuPortalTarget={document.body}
                            value={this.state.selectedDoctor}
                            onChange={(selectedGroup) => {
                              this.handleSelectDoctor(selectedGroup);
                            }}
                            options={this.state.doctor_names}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      </NavItem>
                    </Nav>
                    <MDBDataTable sortable={false} onSort={(s) => console.log(s)} disableRetreatAfterSorting={true} responsive data={data} className="mt-4" />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
        );
    }
}

export default Orders;