import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Alert,
  Label,
  Input,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
// import CalendarCard from "./CalendarCard";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";

// import ChiefComplaintWidget from "../Dashboard/ChiefComplaintWidget";

//Import Calendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/bootstrap/main.css";

import PlacesAutocomplete from "react-places-autocomplete"; // getLatLng, // geocodeByAddress,

// import BigCalendar from 'react-big-calendar'
// import moment from 'moment'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import base_url from "../base_url";

import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";

import AutoLogout from "../AutoLogout";

import Lottie from "react-lottie";
import animationData from "./loading-animation.json";

import SwrRequest from "../SwrRequest";

// console.log("Hello", base_url)

// const doctor_names = [
//     {
//         label: "Select Doctor",
//         options:[
//             {label: "Veer Gadodia", value: "Veer Gadodia"},
//             {label: "Jane Gadodia", value: "Jane Gadodia"},
//             {label: "Bob Gadodia", value: "Bob Gadodia"}
//         ]
//     }
// ]

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionGroup: [],
      date: [],
      breadcrumbItems: [
        // { title: "Home", link: "#" },
        // { title: "Calendar", link: "#" },
      ],
      isAlertOpen: false,
      modal_static: false,
      modal_patient: false,
      calendarWeekends: true,
      calendarEvents: [],
      modal_data: { "": { name: "" } },
      modal_mrn: "",
      reports: [
        {
          icon: "ri-stack-line",
          title: "Number of Sales",
          value: "1452",
          rate: "2.4%",
          desc: "From previous period",
        },
      ],
      default_date: new Date(),
      time: "",
      dateStr: "",
      timeStr: "",
      dateScreen: true,
      editScreen: false,
      editScreenData: {
        "address": ""
      },

      visit_date: 0,
      visit_month: 0,
      visit_year: 0,
      patient_name: "",
      email: "",
      phone: "",
      address: "",
      notes: "",
      doctor_email: window.sessionStorage.getItem("email"),
      age: 0,
      sex: "",
      insurance: "",
      chief_complaint: "",

      selectedGroup: null,

      urine: 0,
      strep: 0,
      flu: 0,
      covid: 0,
      ecg: 0,
      viral: 0,
      spirometry: 0,
      blood: 0,

      current_date: "",
      current_month: "",
      current_year: "",
      // doctor_name: window.sessionStorage.getItem("name"),

      customchk: false,
      customchk1: false,
      customchk2: false,
      customchk3: false,
      customchk4: false,
      customchk5: false,
      customchk6: false,
      customchk7: false,

      livechk: false,
      live: 0,

      nurseErrorMsg: false,
      doctorTimeError: false,
      nurseTimeDisabled: true,

      visit_time: 0,
      nurse_email: "",
      nurse_time: "",
      nurse_time_range: "",
      houseMember: 0,

      selectedDoctor: null,
      doctor_names: [],
      doctor_name: "",
      doctorNameError: false,

      selectedPatientName: null,
      patientNames: [],
      patient_id: "",
      addNewPatientTextbox: false,
      patient_name: "",

      mobile: 0,

      apartment_number: "",

      selectallchk: false,

      loadingAnimation: false,
    };
    this.handleDateClick.bind(this);
    this.handleDefault.bind(this);
    this.handleToggle.bind(this);
    this.handleDateScreen.bind(this);
    this.handleAddHouseMember = this.handleAddHouseMember.bind(this);
    this.handleEditScreen.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleEditSelectAll = this.handleEditSelectAll.bind(this);
    this.handleEditScreenData.bind(this);
    this.handleEditScreenSubmit.bind(this);
    this.delete_mrn = this.delete_mrn.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleEditCheckbox.bind(this);
    this.get_data = this.get_data.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
    this.handleSelectPatientName = this.handleSelectPatientName.bind(this);
  }

  addedToEmr = (mrn) => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (
      base_url === "http://127.0.0.1:5000" ||
      base_url === "http://3.135.192.56:8080"
    ) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: window.sessionStorage.getItem("token"),
      };
    }

    fetch(base_url + "/add-to-emr", {
      method: "post",
      headers: headers,

      body: JSON.stringify({ mrn: mrn }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        // console.log(Result)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleSelectPatientName = (selectedGroup) => {
    if (selectedGroup.value === "Add New Patient") {
      this.setState({ addNewPatientTextbox: true });
    } else {
      this.setState({ nurseTimeDisabled: true });
      this.setState({ selectedPatientName: selectedGroup });
      this.setState({
        name: selectedGroup.value.patient_name,
        address: selectedGroup.value.address,
        apartment_number: selectedGroup.value.apartment_number,
        age: selectedGroup.value.age,
        sex: selectedGroup.value.sex,
        phone: selectedGroup.value.phone,
        patient_id: selectedGroup.value.patient_id,
      });

      this.setState({ addNewPatientTextbox: true });
      this.handleSelect(selectedGroup.value.address);
    }
  };

  handleSelectGroup = (selectedGroup) => {
    console.log(selectedGroup);
    let time = selectedGroup.value.time;
    if (time % 100 === 0) {
      time += 100;
    } else {
      if (time % 100 >= 15) {
        time += 45;
        time += 40;
      } else {
        time += 45;
      }
    }

    let minutes = time % 100;
    let hours = parseInt(time / 100);

    if (hours < 10) {
      hours = "0" + hours.toString();
    }
    if (minutes < 10) {
      minutes = "0" + minutes.toString();
    }
    let timeString = hours.toString() + ":" + minutes.toString();

    // console.log(typeof(timeString), time)

    this.setState({ timeStr: timeString });
    this.setState({ visit_time: time });

    this.setState({ nurse_time: selectedGroup.value.time });
    this.setState({ nurse_email: selectedGroup.value.email });
    this.setState({ selectedGroup });
  };

  handleSelectDoctor = (selectedDoctor) => {
    // console.log(selectedDoctor);
    this.setState({ selectedDoctor: selectedDoctor });
    this.setState({ doctor_name: selectedDoctor.value });
  };

  editEvent = async () => {
    // console.log("Edit button pressed", this.state.modal_mrn)
    this.setState({ modal_static: false });
    this.setState({ loadingAnimation: true });
    // console.log("pressed");

    const d = {
      email: window.sessionStorage.getItem("email"),
      mrn: this.state.modal_mrn,
    };
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (
      base_url === "http://127.0.0.1:5000" ||
      base_url === "http://3.135.192.56:8080"
    ) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: window.sessionStorage.getItem("token"),
      };
    }

    await fetch(base_url + "/get-db-data", {
      method: "post",
      headers: headers,
      body: JSON.stringify(d),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        // console.log(Result);

        const visit_date = Result["data"].visit_date;
        const visit_month = Result["data"].visit_month;
        const visit_year = Result["data"].visit_year;
        const visit_time = Result["data"].visit_time;

        let tempString = "";
        if (visit_time.toString().length === 3) {
          tempString =
            "0" +
            visit_time.toString().slice(0, 1) +
            ":" +
            visit_time.toString().slice(1, 3);
        } else {
          tempString =
            visit_time.toString().slice(0, 2) +
            ":" +
            visit_time.toString().slice(2, 4);
        }

        const name = Result["data"].patient_name;
        const mrn = Result["data"].mrn;
        const email = Result["data"].email;
        const phone = Result["data"].phone;
        const address = Result["data"].address;
        const age = Result["data"].age;
        const sex = Result["data"].sex === 0 ? "Male" : "Female";
        const chiefcomplaint = Result["data"].chief_complaint;
        const urine = Result["data"].urine;
        const strep = Result["data"].strep;
        const flu = Result["data"].flu;
        const covid = Result["data"].covid;
        const ecg = Result["data"].ecg;
        const viral = Result["data"].viral;
        const spirometry = Result["data"].spirometry;
        const blood = Result["data"].blood;
        const apartment_number = Result["data"].apartment_number;

        // const nurse_name = Result["data"].nurse_name
        const nurse_time = Result["data"].nurse_time;

        const customchk = ecg === 1 ? true : false;
        const customchk1 = spirometry === 1 ? true : false;
        const customchk2 = strep === 1 ? true : false;
        const customchk3 = flu === 1 ? true : false;
        const customchk4 = covid === 1 ? true : false;
        const customchk5 = viral === 1 ? true : false;
        const customchk6 = blood === 1 ? true : false;
        const customchk7 = urine === 1 ? true : false;

        const notes = Result["data"].notes;
        const dob_month = Result["data"].dob_month;
        const dob_date = Result["data"].dob_date;
        const dob_year = Result["data"].dob_year;
        const insurance = Result["data"].insurance;
        const exact_nurse_time = Result["data"].exact_nurse_time;
        const doctor_name = Result["data"].doctor_name;

        let selectallchk;

        if (
          customchk === true &&
          customchk2 === true &&
          customchk3 === true &&
          customchk4 === true &&
          customchk5 === true &&
          customchk6 === true &&
          customchk7 === true &&
          customchk1 === true
        ) {
          selectallchk = true;
        } else {
          selectallchk = false;
        }

        let temp_data = {};
        temp_data["name"] = name;
        temp_data["time_str"] = tempString;
        temp_data["email"] = email;
        temp_data["phone"] = phone;
        temp_data["address"] = address;
        temp_data["age"] = age;
        temp_data["sex"] = sex;
        temp_data["chief_complaint"] = chiefcomplaint;

        temp_data["urine"] = urine;
        temp_data["strep"] = strep;
        temp_data["flu"] = flu;
        temp_data["covid"] = covid;
        temp_data["ecg"] = ecg;
        temp_data["viral"] = viral;
        temp_data["spirometry"] = spirometry;
        temp_data["blood"] = blood;
        temp_data["mrn"] = mrn;

        temp_data["customchk"] = customchk;
        temp_data["customchk1"] = customchk1;
        temp_data["customchk2"] = customchk2;
        temp_data["customchk3"] = customchk3;
        temp_data["customchk4"] = customchk4;
        temp_data["customchk5"] = customchk5;
        temp_data["customchk6"] = customchk6;
        temp_data["customchk7"] = customchk7;

        temp_data["selectallchk"] = selectallchk;

        temp_data["visit_date"] = visit_date;
        temp_data["visit_month"] = visit_month;
        temp_data["visit_year"] = visit_year;
        temp_data["visit_time"] = visit_time;

        temp_data["notes"] = notes;
        temp_data["dob_date"] = 24;
        temp_data["dob_month"] = 6;
        temp_data["dob_year"] = 2020;
        temp_data["insurance"] = insurance;

        // temp_data["nurse_name"] = nurse_name;
        temp_data["nurse_time"] = nurse_time;
        temp_data["doctorTimeError"] = false;
        temp_data["exact_nurse_time"] = exact_nurse_time;

        temp_data["apartment_number"] = apartment_number;
        temp_data["selectedDoctor"] = {
          label: doctor_name,
          value: doctor_name,
        };
        temp_data["doctor_name"] = doctor_name;

        this.setState({ editScreenData: temp_data });
      });

    this.setState({ dateScreen: false });
    this.setState({ editScreen: true });
    this.setState({ loadingAnimation: false });
    // setTimeout(() => {this.setState({loadingAnimation: false}); this.setState({editScreen: true}) }, 1000)
  };

  handleAdd() {
    // console.log(this.state.visit_date);
    // console.log(this.state.visit_month);
    // console.log(this.state.visit_year);
    // console.log(this.state.age)
    // console.log(this.state.nurse_time, this.state.visit_time);
    if (this.state.houseMember === 0 && this.state.selectedGroup === null) {
      this.setState({ nurseErrorMsg: true });
    } else if (this.state.nurse_time >= this.state.visit_time) {
      // console.log("here");
      this.setState({ doctorTimeError: true });
    } else {
      const diff = Math.abs(
        parseInt(this.state.nurse_time / 100) -
        parseInt(this.state.visit_time / 100)
      );
      if (
        Math.abs(this.state.nurse_time - this.state.visit_time) - 40 * diff <
        45
      ) {
        // console.log("this", (Math.abs(this.state.nurse_time - this.state.visit_time) - (40 * diff)))
        // console.log(diff)
        this.setState({ doctorTimeError: true });
        return null;
      }

      if (this.state.doctor_name === "") {
        this.setState({ doctorNameError: true });
        return;
      }

      this.setState({ modal_patient: false });
      // console.log("Start animation");
      this.setState({ loadingAnimation: true });

      // console.log(this.state);

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      this.setState({ current_date: dd });
      this.setState({ current_month: mm });
      this.setState({ current_year: yyyy });
      this.setState({ patient_name: this.state.name });

      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      if (
        base_url === "http://127.0.0.1:5000" ||
        base_url === "http://3.135.192.56:8080"
      ) {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
      } else {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: window.sessionStorage.getItem("token"),
        };
      }

      console.log(this.state);

      fetch(base_url + "/add", {
        method: "post",
        headers: headers,
        body: JSON.stringify(this.state),
      })
        .then((Response) => Response.json())
        .then((Result) => {
          console.log(Result);

          this.setState({
            patient_name: "",
            phone: "",
            age: "",
            address: "",
            sex: "",
            chief_complaint: "",

            customchk: false,
            customchk1: false,
            customchk2: false,
            customchk3: false,
            customchk4: false,
            customchk5: false,
            customchk6: false,
            customchk7: false,
            selectallchk: false,

            flu: 0,
            strep: 0,
            urine: 0,
            blood: 0,
            covid: 0,
            viral: 0,
            spirometry: 0,
            ecg: 0,

            selectedGroup: null,
            nurseErrorMsg: false,
            doctorTimeError: false,
            doctorNameError: false,

            livechk: false,
            live: 0,

            apartment_number: "",
            houseMember: 0,
            timeStr: "",
            visit_time: 0,
            doctor_name: "",

            addNewPatientTextbox: "",
            selectedPatientName: null,
            patient_id: "",
          });

          this.setState({ loadingAnimation: false });
          this.get_data();
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loadingAnimation: false });
        });
    }
    // setTimeout(() => { this.setState({loadingAnimation: false}); window.location.reload(false); }, 1500);
  }

  delete_mrn() {
    // console.log(this.state)
    this.setState({ modal_static: false });
    this.setState({ loadingAnimation: true });

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (
      base_url === "http://127.0.0.1:5000" ||
      base_url === "http://3.135.192.56:8080"
    ) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: window.sessionStorage.getItem("token"),
      };
    }
    fetch(base_url + "/delete", {
      method: "post",
      headers: headers,

      body: JSON.stringify({ mrn: this.state.modal_mrn }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        // console.log(Result);
        this.setState({ loadingAnimation: false });
        this.get_data();
        // window.location.reload(false);
      });
  }

  updateDetails(data) {
    let currentData = [];

    if (data["status"] === "success") {
      for (let i = 0; i < data["data"].length; i++) {
        let tempDic = {};
        this.state.modal_data[data["data"][i].mrn] = data["data"][i];
        var month = data["data"][i].date.slice(0, 2);
        var day = data["data"][i].date.slice(3, 5);
        var year = data["data"][i].date.slice(6, 10);

        var name = data["data"][i].name;

        let tempTime = parseInt(data["data"][i].time);
        // console.log(tempTime)
        let tempString = "";
        if (tempTime >= 1200 && tempTime < 2400) {
          if (parseInt(tempTime.toString().slice(0, 2)) === 12) {
            tempString =
              tempTime.toString().slice(0, 2) +
              ":" +
              tempTime.toString().slice(2, 4) +
              " PM";
          } else {
            tempString =
              parseInt(tempTime.toString().slice(0, 2)) -
              12 +
              ":" +
              tempTime.toString().slice(2, 4) +
              " PM";
          }
        } else {
          if (tempTime.toString().length === 3) {
            // console.log("Here")
            tempString =
              tempTime.toString().slice(0, 1) +
              ":" +
              tempTime.toString().slice(1, 3) +
              " AM";
          } else {
            tempString =
              tempTime.toString().slice(0, 2) +
              ":" +
              tempTime.toString().slice(2, 4) +
              " AM";
          }
        }

        // console.log("tempstirng", tempString)

        this.state.modal_data[data["data"][i].mrn].parsed_time = tempString;

        tempDic["title"] = name + " Scheduled Visit";
        tempDic["start"] = year + "-" + month + "-" + day;
        tempDic["id"] = data["data"][i].mrn;

        if (this.state.modal_data[data["data"][i].mrn].mobile === 0) {
          if (this.state.modal_data[data["data"][i].mrn].visit_status === 0) {
            tempDic["backgroundColor"] = "grey";
          } else {
            tempDic["backgroundColor"] = "#0D55B0";
          }
        } else {
          tempDic["backgroundColor"] = "orange";
        }

        tempDic["time"] = data["data"][i].time;
        tempDic["nurse_time"] = data["data"][i].exact_nurse_time;

        // console.log(tempDic['start'])
        currentData.push(tempDic);
      }
    }

    // Sort current data in ascending order by time
    // currentData = currentData.sort(function(a, b) {
    //     return a.time - b.time;
    // });

    this.setState({
      calendarEvents: currentData,
    });

    // GET VALID NURSE TIMES
    var today = new Date();
    var dd = parseInt(String(today.getDate()).padStart(2, "0"));
    var mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
    var yyyy = parseInt(today.getFullYear());
    var h = today.getHours().toString();
    var m = today.getMinutes().toString();

    if (m.length === 1) {
      m = "0" + m;
    }

    var curr_time = parseInt(h + m);
    // console.log("curr_time", curr_time)

    if (
      this.state.visit_month !== mm ||
      this.state.visit_date !== dd ||
      this.state.visit_year !== yyyy
    ) {
      curr_time = 0;
    }

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (
      base_url === "http://127.0.0.1:5000" ||
      base_url === "http://3.135.192.56:8080"
    ) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: window.sessionStorage.getItem("token"),
      };
    }

    fetch(base_url + "/get-valid-times", {
      method: "post",
      headers: headers,

      body: JSON.stringify({
        address: this.state.address,
        current_date: this.state.visit_date,
        current_month: this.state.visit_month,
        current_year: this.state.visit_year,
        current_time: curr_time,
      }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        // console.log(Result);
        // console.log(Result.data)
        this.setState({ optionGroup: Result.data });
        this.setState({ nurseTimeDisabled: false });
        // this.setState({loadingAnimation: false})
        // this.get_data()
        // window.location.reload(false);
      });
  }

  get_data() {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (
      base_url === "http://127.0.0.1:5000" ||
      base_url === "http://3.135.192.56:8080"
    ) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: window.sessionStorage.getItem("token"),
      };
    }

    fetch(base_url + "/get-table", {
      method: "post",
      headers: headers,

      body: JSON.stringify({
        doctor_email: window.sessionStorage.getItem("email"),
      }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        console.log("Result", Result);
        // let currentData = [...this.state.calendarEvents];
        let currentData = [];

        if (Result["status"] === "success") {
          console.log(Result);
          for (let i = 0; i < Result["data"].length; i++) {
            let tempDic = {};
            this.state.modal_data[Result["data"][i].mrn] = Result["data"][i];

            // console.log(Result["data"][i]);

            var month = Result["data"][i].date.slice(0, 2);
            var day = Result["data"][i].date.slice(3, 5);
            var year = Result["data"][i].date.slice(6, 10);

            var name = Result["data"][i].name;

            let tempTime = parseInt(Result["data"][i].time);
            // console.log(tempTime)
            let tempString = "";
            if (tempTime >= 1200 && tempTime < 2400) {
              if (parseInt(tempTime.toString().slice(0, 2)) === 12) {
                tempString =
                  tempTime.toString().slice(0, 2) +
                  ":" +
                  tempTime.toString().slice(2, 4) +
                  " PM";
              } else {
                tempString =
                  parseInt(tempTime.toString().slice(0, 2)) -
                  12 +
                  ":" +
                  tempTime.toString().slice(2, 4) +
                  " PM";
              }
            } else {
              if (tempTime.toString().length === 3) {
                // console.log("Here")
                tempString =
                  tempTime.toString().slice(0, 1) +
                  ":" +
                  tempTime.toString().slice(1, 3) +
                  " AM";
              } else {
                tempString =
                  tempTime.toString().slice(0, 2) +
                  ":" +
                  tempTime.toString().slice(2, 4) +
                  " AM";
              }
            }

            // console.log("tempstirng", tempString)

            this.state.modal_data[
              Result["data"][i].mrn
            ].parsed_time = tempString;

            tempDic["title"] = name + " Scheduled Visit";
            tempDic["start"] = year + "-" + month + "-" + day;
            tempDic["id"] = Result["data"][i].mrn;
            if (
              this.state.modal_data[Result["data"][i].mrn].visit_status === 0
            ) {
              tempDic["backgroundColor"] = "grey";
            } else {
              tempDic["backgroundColor"] = "#0D55B0";
            }
            tempDic["time"] = Result["data"][i].time;
            tempDic["nurse_time"] = Result["data"][i].exact_nurse_time;

            // console.log(tempDic['start'])
            currentData.push(tempDic);
          }
        }

        // Sort current data in ascending order by time
        // currentData = currentData.sort(function(a, b) {
        //     return a.time - b.time;
        // });

        this.setState({
          calendarEvents: currentData,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    fetch(base_url + "/patient-dropdown", {
      method: "post",
      headers: headers,

      body: JSON.stringify({
        doctor_email: window.sessionStorage.getItem("email"),
      }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        // console.log(Result);
        this.setState({ patientNames: Result.data });
      })
      .catch((e) => {
        console.log(e);
      });

    fetch(base_url + "/get-doctors", {
      method: "post",
      headers: headers,

      body: JSON.stringify({
        practice_email: window.sessionStorage.getItem("email"),
      }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        if (Result.data[0].options.length >= 1) {
          this.setState({
            selectedDoctor: Result.data[0].options[0],
            doctor_name: Result.data[0].options[0].value,
          });
          // console.log(Result.data[0].options[0].value)
        }
        this.setState({ doctor_names: Result.data });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentDidMount() {
    // let patient_modal = false
    // if (this.props.location !== undefined){
    //     patient_modal = this.props.location.state
    // }
    // const { modal_patient } = this.props.location.state
    // console.log("herrrihjeorhouferjf", this.props.match.params)

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var hours = today.getHours().toString();
    var minutes = today.getMinutes().toString();

    today = mm + "/" + dd + "/" + yyyy;
    this.setState({ dateStr: today });

    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }
    let currentTime = hours + ":" + minutes;
    // console.log(currentTime)
    // this.setState({ timeStr: currentTime })

    let visit_time = hours + minutes;
    // this.setState({visit_time: visit_time})

    const isLoggedIn = window.sessionStorage.getItem("isLoggedIn");
    if (isLoggedIn != "true") {
      return <Redirect to="/login" />;
    }

    // console.log(headers)

    this.get_data();
  }

  handleDateClick = (date) => {
    this.setState({ modal_patient: true });
    if (date.date < new Date()) {
      this.setState({ default_date: new Date() });

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;
      this.setState({ dateStr: today });
    } else {
      this.setState({ default_date: date.date });

      var dd = String(date.date.getDate()).padStart(2, "0");
      var mm = String(date.date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.date.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;
      this.setState({ dateStr: today });
    }
    console.log(date.date);
    this.setState({ visit_date: date.date.getDate() });
    this.setState({ visit_month: date.date.getMonth() + 1 });
    this.setState({ visit_year: date.date.getFullYear() });
  };

  clearData = () => {
    this.setState({
      patient_name: "",
      phone: "",
      age: "",
      address: "",
      sex: "",
      chief_complaint: "",

      customchk: false,
      customchk1: false,
      customchk2: false,
      customchk3: false,
      customchk4: false,
      customchk5: false,
      customchk6: false,
      customchk7: false,
      selectallchk: false,

      flu: 0,
      strep: 0,
      urine: 0,
      blood: 0,
      covid: 0,
      viral: 0,
      spirometry: 0,
      ecg: 0,

      selectedGroup: null,
      nurseErrorMsg: false,
      doctorTimeError: false,

      apartment_number: "",

      houseMember: 0,
      timeStr: "",
      visit_time: 0,
      doctor_name: "",

      addNewPatientTextbox: "",
      selectedPatientName: null,
      patient_id: "",

      houseMember: 0,
    });
  };

  handleAddHouseMember = () => {
    const date = this.state.modal_data[this.state.modal_mrn].date.split("/");
    const mm = date[0];
    const dd = date[1];
    const yy = date[2];

    this.setState({ houseMember: 1 });
    this.setState({
      dateStr: this.state.modal_data[this.state.modal_mrn].date,
    });
    this.setState({
      dateStr: this.state.modal_data[this.state.modal_mrn].date,
    });

    let times = this.state.modal_data[this.state.modal_mrn].parsed_time.split(
      " "
    );
    let hours = parseInt(times[0].split(":")[0]);
    let minutes = parseInt(times[0].split(":")[1]);

    console.log(times, hours, minutes);
    if (times[1] === "PM" && hours !== 12) hours += 12;

    if (hours < 10) {
      hours = "0" + hours.toString();
    }
    if (minutes < 10) {
      minutes = "0" + minutes.toString();
    }
    let timeStr = hours.toString() + ":" + minutes.toString();

    console.log(timeStr);

    this.setState({ timeStr: timeStr });
    this.setState({
      visit_time: this.state.modal_data[this.state.modal_mrn].time,
    });
    this.setState({
      apartment_number: this.state.modal_data[this.state.modal_mrn]
        .apartment_number,
    });

    this.setState({
      address: this.state.modal_data[this.state.modal_mrn].address,
    });
    this.setState({
      nurse_time: this.state.modal_data[this.state.modal_mrn].exact_nurse_time,
    });
    this.setState({
      nurse_time_range: this.state.modal_data[this.state.modal_mrn].nurse_time,
    });
    this.setState({
      nurse_email: this.state.modal_data[this.state.modal_mrn].nurse_email,
    });

    const doctor_name = this.state.modal_data[this.state.modal_mrn].doctor_name;
    this.setState({ doctor_name: doctor_name });

    const selectedDoctor = { label: doctor_name, value: doctor_name };
    this.setState({ selectedDoctor: selectedDoctor });

    console.log(this.state.modal_data[this.state.modal_mrn].phone);

    this.setState({
      phone: this.state.modal_data[this.state.modal_mrn].phone,
    });

    const covid = this.state.modal_data[this.state.modal_mrn].covid;
    const strep = this.state.modal_data[this.state.modal_mrn].strep;
    const flu = this.state.modal_data[this.state.modal_mrn].flu;
    const ecg = this.state.modal_data[this.state.modal_mrn].ecg;
    const spirometry = this.state.modal_data[this.state.modal_mrn].spirometry;
    const urine = this.state.modal_data[this.state.modal_mrn].urine;
    const blood = this.state.modal_data[this.state.modal_mrn].blood;

    this.setState({ covid });
    this.setState({ flu });
    this.setState({ strep });
    this.setState({ ecg });
    this.setState({ spirometry });
    this.setState({ urine });
    this.setState({ blood });

    this.setState({ customchk4: covid === 1 ? true : false });
    this.setState({ customchk2: strep === 1 ? true : false });
    this.setState({ customchk: ecg === 1 ? true : false });
    this.setState({ customchk1: spirometry === 1 ? true : false });
    this.setState({ customchk7: urine === 1 ? true : false });
    this.setState({ customchk6: blood === 1 ? true : false });
    this.setState({ customchk3: flu === 1 ? true : false });

    this.setState({ visit_date: dd });
    this.setState({ visit_month: mm });
    this.setState({ visit_year: yy });

    this.setState({ modal_patient: true });
    this.setState({ modal_static: false });
  };

  handleDefault = (date) => {
    this.setState({ nurseTimeDisabled: true });
    this.setState({ selectedGroup: null });
    let dateTime = { date: date };
    this.handleDateClick(dateTime);
  };

  handleSelectAll() {
    console.log("Selected");
    if (!this.state.selectallchk) {
      this.setState({
        customchk: true,
        customchk1: true,
        customchk2: true,
        customchk3: true,
        customchk4: true,
        customchk5: true,
        customchk6: true,
        customchk7: true,
        urine: 1,
        strep: 1,
        flu: 1,
        // viral: 1,
        covid: 1,
        // ecg: 1,
        // spirometry: 1,
        // blood: 1,
      });
    } else {
      this.setState({
        customchk: false,
        customchk1: false,
        customchk2: false,
        customchk3: false,
        customchk4: false,
        customchk5: false,
        customchk6: false,
        customchk7: false,
        urine: 0,
        strep: 0,
        flu: 0,
        // viral: 0,
        covid: 0,
        // ecg: 0,
        // spirometry: 0,
        // blood: 0,
      });
    }

    this.setState({
      selectallchk: !this.state.selectallchk,
    });
  }

  handleEditSelectAll() {
    let tempEditScreenData = this.state.editScreenData;
    tempEditScreenData.selectallchk = !tempEditScreenData.selectallchk;
    if (tempEditScreenData.selectallchk) {
      tempEditScreenData.customchk = true;
      tempEditScreenData.customchk1 = true;
      tempEditScreenData.customchk2 = true;
      tempEditScreenData.customchk3 = true;
      tempEditScreenData.customchk4 = true;
      tempEditScreenData.customchk5 = true;
      tempEditScreenData.customchk6 = true;
      tempEditScreenData.customchk7 = true;

      tempEditScreenData.urine = 1;
      tempEditScreenData.strep = 1;
      tempEditScreenData.flu = 1;
      // tempEditScreenData.viral = 1
      tempEditScreenData.covid = 1;
      // tempEditScreenData.ecg = 1
      // tempEditScreenData.blood = 1
      // tempEditScreenData.spirometry = 1
    } else {
      tempEditScreenData.customchk = false;
      tempEditScreenData.customchk1 = false;
      tempEditScreenData.customchk2 = false;
      tempEditScreenData.customchk3 = false;
      tempEditScreenData.customchk4 = false;
      tempEditScreenData.customchk5 = false;
      tempEditScreenData.customchk6 = false;
      tempEditScreenData.customchk7 = false;

      tempEditScreenData.urine = 0;
      tempEditScreenData.strep = 0;
      tempEditScreenData.flu = 0;
      // tempEditScreenData.viral = 0
      tempEditScreenData.covid = 0;
      // tempEditScreenData.ecg = 0
      // tempEditScreenData.blood = 0
      // tempEditScreenData.spirometry = 0
    }

    this.setState({
      editScreenData: tempEditScreenData,
    });
  }

  handleDateScreen = (bool) => {
    this.setState({ dateScreen: bool });
  };

  handleEditScreenSubmit = () => {
    // console.log(this.state.editScreenData.exact_nurse_time, this.state.editScreenData.visit_time )
    const diff = Math.abs(
      parseInt(this.state.editScreenData.exact_nurse_time / 100) -
      parseInt(this.state.editScreenData.exact_nurse_time / 100)
    );
    if (
      Math.abs(
        this.state.editScreenData.exact_nurse_time -
        this.state.editScreenData.visit_time
      ) -
      40 * diff <
      45
    ) {
      // console.log("this", (Math.abs(this.state.editScreenData.exact_nurse_time - this.state.editScreenData.visit_time) - (40 * diff)))
      // console.log("HERE")
      let temp = this.state.editScreenData;
      temp["doctorTimeError"] = true;
      this.setState({ editScreenData: temp });
      return null;
    }

    this.setState({ editScreen: false });
    this.setState({ loadingAnimation: true });

    const temp = this.state.editScreenData;
    temp["patient_name"] = temp["name"];
    // temp["doctor_name"] = window.sessionStorage.getItem("name");

    if (temp["sex"] === "Male") {
      temp["sex"] = 0;
    } else {
      temp["sex"] = 1;
    }

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (
      base_url === "http://127.0.0.1:5000" ||
      base_url === "http://3.135.192.56:8080"
    ) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: window.sessionStorage.getItem("token"),
      };
    }

    console.log(this.state.editScreenData);

    fetch(base_url + "/edit", {
      method: "post",
      headers: headers,

      body: JSON.stringify(temp),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        // console.log(Result);
        this.setState({ loadingAnimation: false });
        this.get_data();
        // window.location.reload(false);
      });
  };

  handleEditCheckbox = (key, test) => {
    let tempEditScreenData = this.state.editScreenData;
    tempEditScreenData[key] = !tempEditScreenData[key];
    tempEditScreenData.selectallchk = false;
    if (tempEditScreenData[key] === true) {
      tempEditScreenData[test] = 1;
    } else {
      tempEditScreenData[test] = 0;
    }
    this.setState({ editScreenData: tempEditScreenData });
  };

  handleEditScreenData = (key, val) => {
    let temp_data = this.state.editScreenData;
    temp_data[key] = val;
    this.setState({ editScreenData: temp_data });
  };

  handleEditScreen = (bool) => {
    this.setState({ editScreen: bool });
  };

  handleUpdateTime = (time) => {
    this.setState({ time: time });
  };

  updateTime = (time) => {
    console.log(time);
  };

  handleToggle = (id) => {
    this.setState({ modal_static: true });
    // console.log(id);
  };

  handleSubmit() {
    console.log(this.state.patient_name);
    console.log(this.state.phone);
    console.log(this.state.timeStr);
    console.log(this.state.address);
    console.log(this.state.age);
    console.log(this.state.sex);
    console.log(this.state.chief_complaint);

    // console.log(this.state.urine)

    console.log(
      this.state.urine,
      this.state.covid,
      this.state.strep,
      this.state.blood,
      this.state.spirometry,
      this.state.ecg,
      this.state.viral,
      this.state.flu
    );
  }

  handleChange = (address) => {
    this.setState({ nurseTimeDisabled: true });
    this.setState({ selectedGroup: null });
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ selectedGroup: null });
    this.setState({ address });

    console.log(
      this.state.visit_month,
      this.state.visit_date,
      this.state.visit_year
    );

    var today = new Date();
    var dd = parseInt(String(today.getDate()).padStart(2, "0"));
    var mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
    var yyyy = parseInt(today.getFullYear());
    var h = today.getHours().toString();
    var m = today.getMinutes().toString();

    if (m.length === 1) {
      m = "0" + m;
    }

    var curr_time = parseInt(h + m);
    // console.log("curr_time", curr_time)

    if (
      this.state.visit_month !== mm ||
      this.state.visit_date !== dd ||
      this.state.visit_year !== yyyy
    ) {
      curr_time = 0;
    }

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (
      base_url === "http://127.0.0.1:5000" ||
      base_url === "http://3.135.192.56:8080"
    ) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: window.sessionStorage.getItem("token"),
      };
    }

    fetch(base_url + "/get-valid-times", {
      method: "post",
      headers: headers,

      body: JSON.stringify({
        address: address,
        current_date: this.state.visit_date,
        current_month: this.state.visit_month,
        current_year: this.state.visit_year,
        current_time: curr_time,
      }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        console.log(Result);
        // console.log(Result.data)
        this.setState({ optionGroup: Result.data });
        this.setState({ nurseTimeDisabled: false });
        // this.setState({loadingAnimation: false})
        // this.get_data()
        // window.location.reload(false);
      });
  };

  handleChangeEdit = (address) => {
    let tempEditScreenData = this.state.editScreenData;
    tempEditScreenData.address = address;
    this.setState({ editScreenData: tempEditScreenData });
  };

  handleSelectEdit = (address) => {
    let tempEditScreenData = this.state.editScreenData;
    tempEditScreenData.address = address;
    this.setState({ editScreenData: tempEditScreenData });
  };

  tempFunction = (date) => {
    console.log(date);
  };

  updateSex = (name) => {
    // console.log(name)

    let url =
      "https://gender-api.com/get?name=" + name + "&key=AbTvXnkqqToRaEaJgo";

    fetch(url, {
      method: "GET",
    })
      .then((Response) => Response.json())
      .then((Result) => {
        // console.log(Result)
        try {
          if (Result.accuracy > 90) {
            if (Result.gender === "male") {
              this.setState({ sex: 0 });
            } else {
              this.setState({ sex: 1 });
            }
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const isLoggedIn = window.sessionStorage.getItem("isLoggedIn");
    if (isLoggedIn != "true") {
      return <Redirect to="/login" />;
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <React.Fragment>
        <AutoLogout />
        <SwrRequest updateDetails={this.updateDetails} />
        <Row>
          <Col
            xl={12}
            style={{
              backgroundColor: "transparent",
              marginRight: -25,
            }}
          >
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title="Calendar"
                  breadcrumbItems={this.state.breadcrumbItems}
                />

                <Card style={{ alignItems: "center" }}>
                  {this.state.loadingAnimation && (
                    <Lottie
                      style={{
                        position: "absolute",
                        zIndex: 10,

                        marginTop: "20%",
                      }}
                      options={defaultOptions}
                      height={200}
                      width={200}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  )}

                  <CardBody>
                    {/* <div>
                                            <Link to="#" onClick={() => this.setState({ modal_static: true, isAlertOpen: false })} className="btn btn-success mb-2"><i className="mdi mdi-plus mr-2"></i> Add Customer</Link>
                                        </div> */}
                    <FullCalendar
                      defaultView="dayGridWeek"
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                      ]}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      customButtons={{
                        custom1: {
                          text: "Add Patient",
                          click: () => {
                            this.handleDateClick({
                              date: new Date(),
                            });
                          },
                        },
                      }}
                      header={{
                        left: "prev,next custom1",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay",
                      }}
                      // dateClick={this.state.dateScreen === true ? (this.handleDateClick) : false}
                      dateClick={this.handleDateClick}
                      selectable={this.state.dateScreen}
                      events={this.state.calendarEvents}
                      eventOrder={"nurse_time"}
                      eventClick={(e) => {
                        // console.log(this.state.modal_data);
                        this.setState({
                          modal_mrn: e.event.id,
                        });
                        // console.log(e.event.id)
                        this.setState({
                          modal_static: true,
                        });
                      }}
                    />

                    <div style={{ clear: "both" }}></div>
                  </CardBody>
                </Card>

                <Modal
                  isOpen={this.state.modal_patient}
                  toggle={this.tog_static}
                  backdrop="static"
                  centered
                  size="xl"
                >
                  <ModalHeader
                    toggle={() => {
                      this.setState({
                        modal_patient: false,
                        addNewPatientTextbox: false,
                      });
                      this.clearData();
                    }}
                  >
                    <p className="mb-2">Create Appointment</p>
                    {/* <Label style={{ fontWeight: 'normal', fontSize: 16 }}>Date: </Label> */}
                    {this.state.houseMember === 0 && (
                      <DatePicker
                        minDate={new Date()}
                        id="date-picker-style"
                        className="form-control"
                        value={this.state.default_date}
                        selected={this.state.default_date}
                        onChange={this.handleDefault}
                      />
                    )}
                    {this.state.houseMember === 1 && (
                      <Label
                        style={{
                          fontWeight: "normal",
                          fontSize: 16,
                        }}
                      >
                        Date: {this.state.dateStr}
                      </Label>
                    )}
                    {/* <Label style={{ fontWeight: 'normal', fontSize: 16 }}>Date: {this.state.dateStr}</Label> */}
                  </ModalHeader>

                  <ModalBody>
                    <AvForm
                      style={{ marginLeft: "3.5vh" }}
                      onValidSubmit={this.handleAdd}
                    >
                      <Row>
                        <Col lg={3}>
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Name</Label>
                            {this.state.addNewPatientTextbox === true ? (
                              <AvField
                                name="name"
                                placeholder="John Appleseed"
                                type="text"
                                errorMessage="Enter Name"
                                onChange={(event) => {
                                  this.setState({
                                    name: event.target.value,
                                    patient_name: event.target.value,
                                  });
                                }}
                                className="form-control"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter the full name",
                                  },
                                }}
                                autoComplete="none"
                                value={this.state.name}
                                id="validationCustom01"
                              />
                            ) : (
                                <Select
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  value={this.state.selectedPatientName}
                                  onChange={this.handleSelectPatientName}
                                  options={this.state.patientNames}
                                  classNamePrefix="select2-selection"
                                />
                              )}
                          </FormGroup>
                        </Col>

                        <Col lg={4}>
                          <FormGroup>
                            <Label htmlFor="validationCustom04">Address</Label>
                            {this.state.houseMember === 1 && (
                              <p>{this.state.address}</p>
                            )}
                            {this.state.houseMember === 0 && (
                              <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder:
                                          "184 Ryan Street, Marlboro, NJ, 07746",
                                        className: "form-control",
                                        autoComplete: "nofill",
                                        onBlur: (event) =>
                                          this.handleSelect(event.target.value),
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: "lightgrey",
                                            cursor: "pointer",
                                            width: "100%",
                                            padding: 10,
                                            borderBottom: "1px solid #ced4da",
                                          }
                                          : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                            width: "100%",
                                            padding: 10,
                                            borderBottom: "1px solid #ced4da",
                                          };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg={2}>
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Apt #</Label>
                            {this.state.houseMember === 0 && (
                              <AvField
                                name="apartment_number"
                                autoComplete="nofill"
                                placeholder=""
                                type="text"
                                errorMessage="Enter Apartment #"
                                onChange={(event) =>
                                  this.setState({
                                    apartment_number: event.target.value,
                                  })
                                }
                                className="form-control"
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                                value={this.state.apartment_number}
                                id="validationCustom01"
                              />
                            )}
                            {this.state.houseMember === 1 && (
                              <p>{this.state.apartment_number}</p>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg={3}>
                          <FormGroup className="select2-container">
                            <Label>Nurse Time</Label>
                            {this.state.houseMember === 0 && (
                              <Select
                                isDisabled={this.state.nurseTimeDisabled}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                value={this.state.selectedGroup}
                                onChange={this.handleSelectGroup}
                                options={this.state.optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            )}
                            {this.state.houseMember === 1 && (
                              <p>{this.state.nurse_time_range}</p>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <br />
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <FormGroup>
                            <Label htmlFor="email">Doctor Time</Label>
                            <Input
                              onChange={(event) => {
                                console.log(event.target.value)
                                this.setState({
                                  visit_time: parseInt(
                                    event.target.value.split(":")[0] + event.target.value.split(":")[1]
                                    ),
                                });
                                // console.log(Math.ceil(parseInt(event.target.value.split(":")[1]) / 5) * 5)
                              }}
                              className="form-control"
                              type="time"
                              defaultValue={this.state.timeStr}
                              id="example-time-input"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={2}>
                          <FormGroup className="select2-container">
                            <Label htmlFor="email">Doctor Name</Label>
                            <Select
                              // isDisabled={this.state.nurseTimeDisabled}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                singleValue: styles => ({ ...styles, color: '#505d69' }),
                              }}
                              menuPortalTarget={document.body}
                              value={this.state.selectedDoctor}
                              onChange={this.handleSelectDoctor}
                              options={this.state.doctor_names}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={3}>
                          <FormGroup>
                            <Label htmlFor="validationCustom03">
                              Phone Number
                            </Label>
                            <AvField
                              name="phonenumber"
                              placeholder="5165879925"
                              type="number"
                              onChange={(event) =>
                                this.setState({
                                  phone: event.target.value,
                                })
                              }
                              errorMessage="Enter Phone Number"
                              className="form-control"
                              value={this.state.phone}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a phone number",
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage:
                                    "The phone number must be must 10 digits",
                                },
                                maxLength: {
                                  value: 10,
                                  errorMessage:
                                    "The phone number must be must 10 digits",
                                },
                              }}
                              id="validationCustom03"
                              autoComplete="nofill"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={2}>
                          <Label htmlFor="validationCustom03">Age</Label>
                          <AvField
                            name="age"
                            placeholder="10"
                            type="number"
                            onChange={(event) =>
                              this.setState({
                                age: event.target.value,
                              })
                            }
                            errorMessage="Enter Age"
                            className="form-control"
                            value={this.state.age}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter an age.",
                              },
                            }}
                            id="validationCustom03"
                          />
                        </Col>

                        <Col lg={2}>
                          {/* <Label htmlFor="email">Sex</Label> */}
                          <FormGroup>
                            <Label htmlFor="validationCustom07">Sex</Label>
                            <select
                              className="custom-select"
                              value={["Male", "Female"][this.state.sex]}
                              onChange={(event) => {
                                if (event.target.value === "Male") {
                                  this.setState({
                                    sex: 0,
                                  });
                                } else {
                                  this.setState({
                                    sex: 1,
                                  });
                                }
                              }}
                            >
                              <option>Select Sex</option>
                              <option>Male</option>
                              <option>Female</option>
                            </select>
                          </FormGroup>
                        </Col>

                        {/* <Col lg={3}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Notes</Label>
                                                        <AvField
                                                            name="notes"
                                                            placeholder="The house with a red porch."

                                                            type="text"
                                                            onChange={event => this.setState({ "notes": event.target.value })}
                                                            value={this.state.notes}
                                                            errorMessage="Enter Notes"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                        />
                                                    </FormGroup>
                                                </Col> */}
                      </Row>

                      {/* <Row><br /></Row>

                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom09">Chief Complaint</Label>
                                                        <AvField
                                                            onChange={event => this.setState({ chief_complaint: event.target.value })}
                                                            name="chiefcomplaint"
                                                            value= {this.state.chief_complaint}
                                                            placeholder="Feeling a headache since last morning."
                                                            type="text"
                                                            errorMessage="Enter Chief Complaint"
                                                            className="form-control"
                                                            validate={{ 
                                                                required: { value: true, errorMessage: 'Please enter the chief complaint'}, 
                                                            }}
                                                            id="validationCustom09"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}

                      

                      <div className="custom-control custom-checkbox mb-3">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="CustomCheck1"
                          onChange={() => false}
                          checked={this.state.livechk}
                        />
                        <Label
                          className="custom-control-label"
                          onClick={() => {
                            this.setState({
                              livechk: !this.state.livechk,
                            });
                            if (this.state.livechk === false) {
                              this.setState({
                                live: 1,
                              });
                            } else {
                              this.setState({
                                live: 0,
                              });
                            }
                          }}
                        >
                          Live Visit
                        </Label>
                      </div>

                      <Row>
                        <br />
                      </Row>

                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Label htmlFor="validationCustom09">
                              Optional Tests
                            </Label>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="CustomCheck1"
                                onChange={() => false}
                                checked={this.state.selectallchk}
                              />
                              <Label
                                className="custom-control-label"
                                onClick={this.handleSelectAll}
                              >
                                Select All
                              </Label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6} md={6} sm={6} xs={6}>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="CustomCheck1"
                                onChange={() => false}
                                checked={this.state.customchk4}
                              />
                              <Label
                                className="custom-control-label"
                                onClick={() => {
                                  this.setState({
                                    customchk4: !this.state.customchk4,
                                    selectallchk: false,
                                  });
                                  if (this.state.customchk4 === false) {
                                    this.setState({
                                      covid: 1,
                                    });
                                  } else {
                                    this.setState({
                                      covid: 0,
                                    });
                                  }
                                }}
                              >
                                COVID
                              </Label>
                            </div>

                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="CustomCheck1"
                                onChange={() => false}
                                checked={this.state.customchk2}
                              />
                              <Label
                                className="custom-control-label"
                                onClick={() => {
                                  this.setState({
                                    customchk2: !this.state.customchk2,
                                    selectallchk: false,
                                  });
                                  if (this.state.customchk2 === false) {
                                    this.setState({
                                      strep: 1,
                                    });
                                  } else {
                                    this.setState({
                                      strep: 0,
                                    });
                                  }
                                }}
                              >
                                Strep
                              </Label>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="CustomCheck1"
                                onChange={() => false}
                                checked={this.state.customchk3}
                              />
                              <Label
                                className="custom-control-label"
                                onClick={() => {
                                  this.setState({
                                    customchk3: !this.state.customchk3,
                                    selectallchk: false,
                                  });
                                  if (this.state.customchk3 === false) {
                                    this.setState({
                                      flu: 1,
                                    });
                                  } else {
                                    this.setState({
                                      flu: 0,
                                    });
                                  }
                                }}
                              >
                                Flu
                              </Label>
                            </div>
                            {/* <div className="custom-control custom-checkbox mb-3">
                                                            <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.customchk} />
                                                            <Label className="custom-control-label" onClick={() => { this.setState({ customchk: !this.state.customchk, selectallchk: false }); if (this.state.customchk === false) { this.setState({ ecg: 1 }) } else { this.setState({ ecg: 0 }) } }} >ECG</Label>
                                                        </div>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.customchk7} />
                                                            <Label className="custom-control-label" onClick={() => { this.setState({ customchk7: !this.state.customchk7, selectallchk7: false }); if (this.state.customchk7 === false) { this.setState({ urine: 1 }) } else { this.setState({ urine: 0 }) } }} >Urine Test</Label>
                                                        </div> */}
                          </FormGroup>
                        </Col>

                        <Col lg={6} md={6} sm={6} xs={6}>
                          <FormGroup>
                            {/* <div className="custom-control custom-checkbox mb-3">
                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.customchk3} />
                                                        <Label className="custom-control-label" onClick={() => { this.setState({ customchk3: !this.state.customchk3, selectallchk: false }); if (this.state.customchk3 === false) { this.setState({ flu: 1 }) } else { this.setState({ flu: 0 }) } }} >Flu</Label>
                                                    </div> */}
                            {/* <div className="custom-control custom-checkbox mb-3">
                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.customchk5} />
                                                        <Label className="custom-control-label" onClick={() => { this.setState({ customchk5: !this.state.customchk5, selectallchk: false }); if (this.state.customchk5 === false) { this.setState({ viral: 1 }) } else { this.setState({ viral: 0 }) } }} >Viral Panel</Label>
                                                    </div> */}

                            {/* <div className="custom-control custom-checkbox mb-3">
                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.customchk1} />
                                                        <Label className="custom-control-label" onClick={() => { this.setState({ customchk1: !this.state.customchk1, selectallchk: false }); if (this.state.customchk1 === false) { this.setState({ spirometry: 1 }) } else { this.setState({ spirometry: 0 }) } }} >Spirometry</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mb-3">
                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.customchk6} />
                                                        <Label className="custom-control-label" onClick={() => { this.setState({ customchk6: !this.state.customchk6, selectallchk: false }); if (this.state.customchk6 === false) { this.setState({ blood: 1 }) } else { this.setState({ blood: 0 }) } }} >Blood Draw</Label>
                                                    </div> */}
                          </FormGroup>
                        </Col>
                      </Row>
                      <ModalFooter
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        {/* <Label style={{marginRight: '21%', color: 'red'}}>No nurse is available at the selected time. Please select an alternate time.</Label> */}
                        {this.state.nurseErrorMsg === true && (
                          <Alert color="danger">
                            Please select a nurse visit time range.
                          </Alert>
                        )}
                        {this.state.doctorNameError === true && (
                          <Alert color="danger">Please select a doctor.</Alert>
                        )}
                        {this.state.doctorTimeError === true && (
                          <Alert color="danger">
                            Doctor visit time must be scheduled at least 45 mins
                            after the nurse visit window.
                          </Alert>
                        )}
                        <Button
                          style={{ marginLeft: 10 }}
                          color="primary"
                          type="submit"
                        >
                          Add
                        </Button>
                      </ModalFooter>
                    </AvForm>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={this.state.editScreen}
                  toggle={this.tog_static}
                  backdrop="static"
                  centered
                  size="xl"
                >
                  <ModalHeader
                    toggle={() => {
                      this.setState({
                        editScreen: false,
                      });
                      this.clearData();
                    }}
                  >
                    <h4 className="mb-2">Edit Appointment</h4>
                    <Label
                      style={{
                        fontWeight: "normal",
                        fontSize: 16,
                      }}
                    >
                      Date: {this.state.editScreenData.visit_month}/
                      {this.state.editScreenData.visit_date}/
                      {this.state.editScreenData.visit_year}
                    </Label>
                  </ModalHeader>
                  <ModalBody>
                    <AvForm
                      style={{ marginLeft: "3.5vh" }}
                      onValidSubmit={this.handleEditScreenSubmit}
                    >
                      <Row>
                        <Col lg={3}>
                          {/* <Label htmlFor="email">Name</Label>
                                                    <p>Veer Gadodia</p> */}

                          <FormGroup>
                            <Label htmlFor="validationCustom01">Name</Label>
                            <AvField
                              name="name"
                              placeholder="John Appleseed"
                              type="text"
                              errorMessage="Enter Name"
                              onChange={(event) => {
                                let tempEditScreenData = this.state
                                  .editScreenData;
                                tempEditScreenData.name = event.target.value;
                                this.setState({
                                  editScreenData: tempEditScreenData,
                                });
                              }}
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter the full name",
                                },
                              }}
                              value={this.state.editScreenData.name}
                              id="validationCustom01"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={4}>
                          <FormGroup>
                            <Label htmlFor="validationCustom04">Address</Label>
                            <p>{this.state.editScreenData.address}</p>

                            {/* <PlacesAutocomplete
                              value={this.state.editScreenData.address}
                              onChange={this.handleChangeEdit}
                              onSelect={this.handleSelectEdit}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder:
                                        "184 Ryan Street, Marlboro, NJ, 07746",
                                      className: "form-control",
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "lightgrey",
                                            cursor: "pointer",
                                            width: "100%",
                                            padding: 10,
                                            borderBottom: "1px solid #ced4da",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                            width: "100%",
                                            padding: 10,
                                            borderBottom: "1px solid #ced4da",
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete> */}
                          </FormGroup>
                        </Col>

                        <Col lg={2}>
                          {/* <Label htmlFor="email">Name</Label>
                                                    <p>Veer Gadodia</p> */}

                          <FormGroup>
                            <Label htmlFor="validationCustom01">Apt #</Label>
                            {/* <p>{this.state.editScreenData.apartment_number}</p> */}
                            <AvField
                              name="apartment_numer"
                              placeholder=""
                              type="text"
                              errorMessage="Enter Apartment #"
                              onChange={(event) => {
                                let tempEditScreenData = this.state.editScreenData;
                                tempEditScreenData.apartment_number = event.target.value;
                                this.setState({
                                  editScreenData: tempEditScreenData,
                                });
                              }}
                              className="form-control"
                              validate={{
                                required: {
                                  value: false,
                                },
                              }}
                              value={this.state.editScreenData.apartment_number}
                              id="validationCustom01"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={3}>
                          <FormGroup className="select2-container">
                            <Label>Nurse Time</Label>
                            <p>{this.state.editScreenData.nurse_time}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <br />
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <FormGroup>
                            <Label htmlFor="email">Doctor Time</Label>
                            <Input
                              onChange={(event) => {
                                let tempEditScreenData = this.state
                                  .editScreenData;

                                tempEditScreenData.visit_time = parseInt(
                                  event.target.value.split(":")[0] +
                                  event.target.value.split(":")[1]
                                );
                                this.setState({
                                  editScreenData: tempEditScreenData,
                                });
                              }}
                              className="form-control"
                              type="time"
                              defaultValue={this.state.editScreenData.time_str}
                              id="example-time-input"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={2}>
                          <FormGroup className="select2-container">
                            <Label htmlFor="email">Doctor Name</Label>
                            <Select
                              // isDisabled={this.state.nurseTimeDisabled}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              menuPortalTarget={document.body}
                              value={this.state.editScreenData.selectedDoctor}
                              onChange={(selectedDoctor) => {
                                // console.log(selectedDoctor)
                                let tempEditScreenData = this.state
                                  .editScreenData;
                                tempEditScreenData.selectedDoctor = selectedDoctor;
                                tempEditScreenData.doctor_name =
                                  selectedDoctor.value;
                                // console.log(tempEditScreenData.doctor_name)
                                // console.log(tempEditScreenData);
                                this.setState({
                                  editScreenData: tempEditScreenData,
                                });
                              }}
                              options={this.state.doctor_names}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={3}>
                          <FormGroup>
                            <Label htmlFor="validationCustom03">
                              Phone Number
                            </Label>
                            <AvField
                              name="phonenumber"
                              placeholder="5165879925"
                              type="number"
                              onChange={(event) => {
                                let tempEditScreenData = this.state
                                  .editScreenData;
                                tempEditScreenData.phone = event.target.value;
                                this.setState({
                                  editScreenData: tempEditScreenData,
                                });
                              }}
                              errorMessage="Enter Phone Number"
                              className="form-control"
                              value={this.state.editScreenData.phone}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a phone number",
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage:
                                    "The phone number must be must 10 digits",
                                },
                                maxLength: {
                                  value: 10,
                                  errorMessage:
                                    "The phone number must be must 10 digits",
                                },
                              }}
                              id="validationCustom03"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg={2}>
                          <Label htmlFor="validationCustom03">Age</Label>
                          <AvField
                            name="age"
                            placeholder="10"
                            type="number"
                            onChange={(event) => {
                              let tempEditScreenData = this.state
                                .editScreenData;
                              tempEditScreenData.age = event.target.value;
                              this.setState({
                                editScreenData: tempEditScreenData,
                              });
                            }}
                            errorMessage="Enter Age"
                            className="form-control"
                            value={this.state.editScreenData.age}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter an age.",
                              },
                            }}
                            id="validationCustom03"
                          />
                        </Col>

                        <Col lg={2}>
                          {/* <Label htmlFor="email">Sex</Label> */}
                          <FormGroup>
                            <Label htmlFor="validationCustom07">Sex</Label>
                            <select
                              className="custom-select"
                              value={this.state.editScreenData.sex}
                              onChange={(event) => {
                                let tempEditScreenData = this.state
                                  .editScreenData;
                                tempEditScreenData.sex = event.target.value;
                                this.setState({
                                  editScreenData: tempEditScreenData,
                                });
                              }}
                            >
                              <option>Select Sex</option>
                              <option>Male</option>
                              <option>Female</option>
                            </select>
                          </FormGroup>
                        </Col>

                        {/* <Col lg={5}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom09">Chief Complaint</Label>
                                                        <AvField
                                                            onChange={event => {
                                                                let tempEditScreenData = this.state.editScreenData;
                                                                tempEditScreenData.chief_complaint = event.target.value;
                                                                this.setState({ editScreenData: tempEditScreenData })
                                                            }}
                                                            name="chiefcomplaint"
                                                            value= {this.state.editScreenData.chief_complaint}
                                                            placeholder="Feeling a headache since last morning."
                                                            type="text"
                                                            errorMessage="Enter Chief Complaint"
                                                            className="form-control"
                                                            validate={{ 
                                                                required: { value: true, errorMessage: 'Please enter the chief complaint'}, 
                                                            }}
                                                            id="validationCustom09"
                                                        />
                                                    </FormGroup>
                                                </Col> */}

                        {/* <Col lg={3}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Notes</Label>
                                                        <AvField
                                                            name="notes"
                                                            placeholder="The house with a red porch."

                                                            type="text"
                                                            onChange={event => this.setState({ "notes": event.target.value })}
                                                            value={this.state.notes}
                                                            errorMessage="Enter Notes"
                                                            className="form-control"
                                                            id="validationCustom05"
                                                        />
                                                    </FormGroup>
                                                </Col> */}
                      </Row>

                      <Row>
                        <br />
                      </Row>

                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Label htmlFor="validationCustom09">
                              Optional Tests
                            </Label>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="CustomCheck1"
                                onChange={() => false}
                                checked={this.state.editScreenData.selectallchk}
                              />
                              <Label
                                className="custom-control-label"
                                onClick={this.handleEditSelectAll}
                              >
                                Select All
                              </Label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6} md={6} sm={6} xs={6}>
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="CustomCheck1"
                                onChange={() => false}
                                checked={this.state.editScreenData.customchk4}
                              />
                              <Label
                                className="custom-control-label"
                                onClick={() =>
                                  this.handleEditCheckbox("customchk4", "covid")
                                }
                              >
                                COVID
                              </Label>
                            </div>

                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="CustomCheck1"
                                onChange={() => false}
                                checked={this.state.editScreenData.customchk2}
                              />
                              <Label
                                className="custom-control-label"
                                onClick={() =>
                                  this.handleEditCheckbox("customchk2", "strep")
                                }
                              >
                                Strep
                              </Label>
                            </div>

                            <div className="custom-control custom-checkbox mb-3">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="CustomCheck1"
                                onChange={() => false}
                                checked={this.state.editScreenData.customchk3}
                              />
                              <Label
                                className="custom-control-label"
                                onClick={() =>
                                  this.handleEditCheckbox("customchk3", "flu")
                                }
                              >
                                Flu
                              </Label>
                            </div>
                            {/* 
                                                        

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.editScreenData.customchk2} />
                                                            <Label className="custom-control-label" onClick={() => this.handleEditCheckbox("customchk2", "strep")} >Strep</Label>
                                                        </div> */}
                            {/* <div className="custom-control custom-checkbox mb-3">
                                                            <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.editScreenData.customchk} />
                                                            <Label className="custom-control-label" onClick={() => this.handleEditCheckbox("customchk", "ecg")} >ECG</Label>
                                                        </div>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.editScreenData.customchk7} />
                                                            <Label className="custom-control-label" onClick={() => this.handleEditCheckbox("customchk7", "urine")} >Urine Test</Label>
                                                        </div> */}
                          </FormGroup>
                        </Col>

                        <Col lg={6} md={6} sm={6} xs={6}>
                          <FormGroup>
                            {/* <div className="custom-control custom-checkbox mb-3">
                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.editScreenData.customchk5} />
                                                        <Label className="custom-control-label" onClick={() => this.handleEditCheckbox("customchk5", "viral")} >Viral Panel</Label>
                                                    </div> */}
                            {/* <div className="custom-control custom-checkbox mb-3">
                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.editScreenData.customchk2} />
                                                        <Label className="custom-control-label" onClick={() => this.handleEditCheckbox("customchk2", "strep")} >Strep</Label>
                                                    </div> */}

                            {/* <div className="custom-control custom-checkbox mb-3">
                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.editScreenData.customchk1} />
                                                        <Label className="custom-control-label" onClick={() => this.handleEditCheckbox("customchk1", "spirometry")} >Spirometry</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mb-3">
                                                        <Input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.editScreenData.customchk6} />
                                                        <Label className="custom-control-label" onClick={() => this.handleEditCheckbox("customchk6", "blood")} >Blood Draw</Label>
                                                    </div> */}
                          </FormGroup>
                        </Col>
                      </Row>
                      <ModalFooter>
                        {this.state.editScreenData.doctorTimeError === true && (
                          <Alert color="danger">
                            Doctor visit time must be scheduled at least 45 mins
                            after the nurse visit window.
                          </Alert>
                        )}
                        <Button
                          style={{ marginLeft: 10 }}
                          color="primary"
                          type="submit"
                        >
                          Save
                        </Button>
                      </ModalFooter>
                    </AvForm>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={this.state.modal_static}
                  toggle={this.tog_static}
                  backdrop="static"
                  centered
                  size="lg"
                >
                  <ModalHeader
                    toggle={() =>
                      this.setState({
                        modal_static: false,
                      })
                    }
                  >
                    Visit Details
                  </ModalHeader>
                  <ModalBody>
                    <AvForm
                      style={{ marginLeft: "3.5vh" }}
                      onValidSubmit={this.addCustomer}
                    >
                      <Row>
                        <Col lg={3}>
                          <Label htmlFor="email">Name</Label>
                          <p>
                            {this.state.modal_data[this.state.modal_mrn].name}
                          </p>
                        </Col>

                        <Col lg={3}>
                          <Label htmlFor="email">Date</Label>
                          <p>
                            {this.state.modal_data[this.state.modal_mrn].date}
                          </p>
                        </Col>

                        <Col lg={3}>
                          <Label htmlFor="email">Nurse Time</Label>
                          <p>
                            {
                              this.state.modal_data[this.state.modal_mrn]
                                .nurse_time
                            }
                          </p>
                        </Col>

                        <Col lg={3}>
                          <Label htmlFor="email">Doctor Time</Label>
                          <p>
                            {
                              this.state.modal_data[this.state.modal_mrn]
                                .parsed_time
                            }
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <br />
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <Label htmlFor="email">Age</Label>
                          <p>
                            {this.state.modal_data[this.state.modal_mrn].age}{" "}
                            yrs
                          </p>
                        </Col>

                        <Col lg={3}>
                          <Label htmlFor="email">Sex</Label>
                          <p>
                            {this.state.modal_data[this.state.modal_mrn].sex}
                          </p>
                        </Col>

                        {/* <Col lg={3}>
                                                    <Label htmlFor="email">Complaint</Label>
                                                    <p>{this.state.modal_data[this.state.modal_mrn].complaint}</p>
                                                </Col> */}

                        <Col lg={3}>
                          <Label htmlFor="email">Nurse</Label>
                          <p>
                            {
                              this.state.modal_data[this.state.modal_mrn]
                                .nurse_name
                            }
                          </p>
                        </Col>

                        <Col lg={3}>
                          <Label htmlFor="email">Doctor</Label>
                          <p>
                            {
                              this.state.modal_data[this.state.modal_mrn]
                                .doctor_name
                            }
                          </p>
                        </Col>

                        {/* <Col lg={3}>

                                                    <Label htmlFor="email">Status</Label>
                                                    <p>{["Pending", "Completed"][this.state.modal_data[this.state.modal_mrn].visit_status]}</p>
                                                </Col> */}
                      </Row>
                      <ModalFooter>
                        {this.state.modal_data[this.state.modal_mrn].mobile ===
                          1 && (
                            <Button
                              style={{
                                backgroundColor: "orange",
                                borderColor: "orange",
                                marginRight: 10,
                              }}
                              onClick={() =>
                                this.addedToEmr(this.state.modal_mrn)
                              }
                            >
                              Added Patient to EMR
                            </Button>
                          )}
                        {this.state.modal_data[this.state.modal_mrn]
                          .visit_status === 0 && (
                            <Button
                              disabled
                              style={{
                                backgroundColor: "grey",
                                borderColor: "grey",
                              }}
                            >
                              Details
                            </Button>
                          )}
                        {this.state.modal_data[this.state.modal_mrn]
                          .visit_status === 1 && (
                            <Link to={"/dashboard/" + this.state.modal_mrn}>
                              <Button type="submit" color="primary">
                                Details
                            </Button>
                            </Link>
                          )}
                        {/* <Button
                          style={{ marginLeft: 10 }}
                          color="primary"
                          onClick={this.handleAddHouseMember}
                        >
                          Add Family Member
                        </Button> */}
                        <Button
                          style={{ marginLeft: 10 }}
                          color="primary"
                          onClick={this.editEvent}
                        >
                          Edit
                        </Button>
                        <Button
                          style={{ marginLeft: 10 }}
                          color="danger"
                          onClick={this.delete_mrn}
                        >
                          Delete
                        </Button>
                      </ModalFooter>
                    </AvForm>
                  </ModalBody>
                </Modal>
              </Container>
            </div>
          </Col>
          {/* <Col xl={3} md={12} style={{ backgroundColor: 'transparent', paddingTop: 0, paddingBottom: 70 }}>
                        <div className="page-content">
                            <Container fluid >
                                <Col>
                                    <CalendarCard handleEditScreenSubmit={this.handleEditScreenSubmit} handleSelectAll={this.handleSelectAll} handleEditScreenData={this.handleEditScreenData} editScreenData={this.state.editScreenData} handleEditScreen={this.handleEditScreen} editScreen={this.state.editScreen} dateScreen={this.state.dateScreen} date={this.state.default_date} handleDateScreen={this.handleDateScreen} dateStr={this.state.dateStr} handleDateClick={this.handleDateClick} handleUpdateTime={this.handleUpdateTime} width={12} />
                                </Col>
                            </Container>
                        </div>
                    </Col> */}
        </Row>
      </React.Fragment>
    );
  }
}

export default Calendar;
