import { Redirect } from "react-router-dom";
import React, { Component } from "react";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

import Select from "react-select";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";

import base_url from "../base_url";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import AutoLogout from "../AutoLogout";
import SwrRequest from "../SwrRequest";

class Patients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                // { title: "Home", link: "#" },
                // { title: "Patients", link: "#" },
            ],
            activeTab: "1",
            rows: [],
            reload_count: 0,

            selectedDoctor: { label: "All", value: "All" },
            doctor_name: "All",
            doctor_names: [],
        };
        this.toggleTab = this.toggleTab.bind(this);
        this.getData = this.getData.bind(this);
        // this.updateDetails = this.updateDetails.bind(this);
        this.handleSelectDoctor = this.handleSelectDoctor.bind(this);
        this.deletePatient = this.deletePatient.bind(this);
    }

    handleSelectDoctor = (selectedDoctor) => {
        // console.log(selectedDoctor)
        this.setState({ selectedDoctor: selectedDoctor });
        this.setState({ doctor_name: selectedDoctor.value });
    };

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    deletePatient(id) {
        console.log(id);
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        if (
            base_url === "http://127.0.0.1:5000" ||
            base_url === "http://3.135.192.56:8080"
        ) {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
        } else {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: window.sessionStorage.getItem("token"),
            };
        }

        fetch(base_url + "/delete-patient", {
            method: "post",
            headers: headers,

            body: JSON.stringify({
                patient_id: id,
            }),
        })
            .then((Response) => Response.json())
            .then((Result) => {
                console.log(Result);
            });

        this.getData();
    }

    getData() {
        var today_date = new Date();
        var dd = String(today_date.getDate()).padStart(2, "0");
        var mm = String(today_date.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today_date.getFullYear();

        var today = new Date(yyyy, mm, dd);

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        if (
            base_url === "http://127.0.0.1:5000" ||
            base_url === "http://3.135.192.56:8080"
        ) {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
        } else {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: window.sessionStorage.getItem("token"),
            };
        }

        setTimeout(() => {
            console.log("fetched");

            fetch(base_url + "/get-patients", {
                method: "post",
                headers: headers,

                body: JSON.stringify({
                    doctor_email: window.sessionStorage.getItem("email"),
                }),
            })
                .then((Response) => Response.json())
                .then((Result) => {
                    let data_arr = [];
                    console.log(Result);

                    for (let i = 0; i < Result["data"].length; i++) {
                        let tempDic = {};
                        tempDic["name"] = Result["data"][i].patient_name;
                        tempDic["age"] = Result["data"][i].age;
                        tempDic["sex"] =
                            Result["data"][i].sex === 0 ? "Male" : "Female";
                        tempDic["phone"] = Result["data"][i].phone;
                        tempDic["address"] = Result["data"][i].address;
                        tempDic["actions"] = (
                            <>
                                <Link
                                // to={"/dashboard/" + Result["data"][i].patient_id}
                                >
                                    <Button
                                        disabled
                                        size="sm"
                                        className="font-weight-bold p-2"
                                        color="primary"
                                    >
                                        Analytics
                                    </Button>
                                </Link>
                                <Link to="#">
                                    <Button
                                        onClick={() => {
                                            this.deletePatient(
                                                Result["data"][i].patient_id
                                            );
                                        }}
                                        color="danger"
                                        size="sm"
                                        className="font-weight-bold p-2 ml-3"
                                    >
                                        Remove
                                    </Button>
                                </Link>
                            </>
                        );

                        data_arr.push(tempDic);
                    }

                    this.setState({
                        rows: data_arr,
                    });
                });
        }, 500);
    }

    componentDidMount() {
        const isLoggedIn = window.sessionStorage.getItem("isLoggedIn");
        if (isLoggedIn !== "true") {
            return <Redirect to="/login" />;
        }
        this.getData();
        document
            .getElementsByClassName("pagination")[0]
            .classList.add("pagination-rounded");
    }

    render() {
        const isLoggedIn = window.sessionStorage.getItem("isLoggedIn");
        if (isLoggedIn !== "true") {
            return <Redirect to="/login" />;
        }

        const data = {
            columns: [
                {
                    label: "Name",
                    field: "name",
                    width: 78,
                },
                {
                    label: "Age",
                    field: "age",
                    width: 120,
                },
                {
                    label: "Sex",
                    field: "sex",
                    width: 120,
                },
                {
                    label: "Phone",
                    field: "phone",
                    width: 120,
                },
                {
                    label: "Address",
                    field: "address",
                    width: 120,
                },
            ],
            rows: this.state.rows,
        };

        return (
            <React.Fragment>
                <AutoLogout />
                {/* <SwrRequest updateDetails={this.updateDetails} /> */}
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Patients"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="pt-0">
                                        <MDBDataTable
                                            onSort={(s) => console.log(s)}
                                            disableRetreatAfterSorting={true}
                                            responsive
                                            data={data}
                                            className="mt-4"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Patients;
