import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Accounts";
import { useHistory } from "react-router-dom";

export default () => {
  const [status, setStatus] = useState(false);
  let history = useHistory();
  const { getSession, logout } = useContext(AccountContext);

  useEffect(() => {
    logout();
    window.sessionStorage.clear();
    history.push("/login");

    getSession().then((session) => {
      setStatus(true);
    });
  }, []);

  return null;
};
