import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
// import classnames from 'classnames';

import { AvForm, AvField } from "availity-reactstrap-validation";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";
import base_url from "../base_url"
import Select from "react-select";
//Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';

class DoctorsList extends Component {
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [],
            activeTab: '1',
            add_doctor_modal: false,
            doctor_name: "",
            doctor_email: "",
            practice_email: window.sessionStorage.getItem("email"),
            table_data:[],
            delete_doctor_email: "",
            delete_doctor_name: "",
            delete_doctor_modal: false,

            selectedType: "",
            type: "",
            typeOptions: [
                {"options": [
                    {"label": "Doctor", "value": "doctor"},
                    {"label": "Staff", "value": "staff"},
                ]}
            ]
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.handleDeleteDoctor = this.handleDeleteDoctor.bind(this);
    }
    
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleSelectedType = (selectedType) => {
        this.setState({ selectedType: selectedType });
        this.setState({ type: selectedType.value });
    };

    componentDidMount(){
        document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
        let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        if (base_url === "http://127.0.0.1:5000"){
            headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }

        }
        else {
            headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") }
        }

        fetch(base_url + '/get-doctors', {

            method: 'post', headers: headers,

            body: JSON.stringify({ practice_email: window.sessionStorage.getItem("email") })
        }).then((Response) => Response.json()).then((Result) => {
            this.setState({table_data:Result["table-data"]});
            console.log(Result)
        });
    }

    handleAddDoctor = () => {
        let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        if (base_url === "http://127.0.0.1:5000"){
            headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }

        }
        else {
            headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") }
        }

        console.log("type", this.state.type)

        fetch(base_url + '/add-doctor', {

            method: 'post', headers: headers,

            body: JSON.stringify({ practice_email: this.state.practice_email, name:this.state.doctor_name, doctor_email:this.state.doctor_email, type: this.state.type})
        }).then((Response) => Response.json()).then((Result) => {
            
            console.log(Result)
            this.setState({doctor_email: "", doctor_name: ""})

            fetch(base_url + '/get-doctors', {

            method: 'post', headers: headers,

            body: JSON.stringify({ practice_email: window.sessionStorage.getItem("email") })
        }).then((Response) => Response.json()).then((Result) => {
            this.setState({table_data:Result["table-data"]});
            console.log(Result)
        });


            this.setState({add_doctor_modal:false});
        });
    }

    handleDeleteDoctor(email){
        let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        if (base_url === "http://127.0.0.1:5000"){
            headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }

        }
        else {
            headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': window.sessionStorage.getItem("token") }
        }

        fetch(base_url + '/delete-doctor', {
        
            method: 'post', headers: headers,

            body: JSON.stringify({ practice_email: this.state.practice_email, doctor_email:email})
        }).then((Response) => Response.json()).then((Result) => {
            
            this.setState({delete_doctor_modal: false});
            console.log(Result)
            fetch(base_url + '/get-doctors', {

            method: 'post', headers: headers,

            body: JSON.stringify({ practice_email: window.sessionStorage.getItem("email") })
        }).then((Response) => Response.json()).then((Result) => {
            this.setState({table_data:Result["table-data"]});
            console.log(Result)
        });


            this.setState({add_doctor_modal:false});
        });

    }
    
    render() {
        let temp_data = []

        this.state.table_data.map((doctor) => {
            temp_data.push({
                billingName: doctor.name,
                total: doctor.email,
                type: doctor.type,
                action: (<>
                        <Link to="#" className="text-danger" id="delete1">
                            { (this.state.table_data.length === 1 
                                // || window.sessionStorage.getItem("email") === doctor.email
                            ) ? 
                                <Button disabled onClick={() => {this.setState({delete_doctor_email: doctor.email, delete_doctor_name: doctor.name, delete_doctor_modal: true})}} color="danger" size="sm" className="font-weight-bold p-2">Remove</Button> : 
                                <Button onClick={() => {this.setState({delete_doctor_email: doctor.email, delete_doctor_name: doctor.name, delete_doctor_modal: true})}} color="danger" size="sm" className="font-weight-bold p-2">Remove</Button>}
                        </Link>
                </>)
            })
            
        })

        const data = {
            columns: [
              {
                label: "Name",
                field: "billingName",
                sort: "asc",
                width: 100
              },
              {
                label: "Email",
                field: "total",
                sort: "asc",
                width: 100
              },
              {
                label: "Type",
                field: "type",
                sort: "asc",
                width: 100
              },
              {
                label: "Remove",
                field: "action",
                width: 100
              },
            ],
            rows: temp_data,

            /*[
              {
                billingName: "Walter Brown",
                total: "dgadodia@gmail.com",
                action : <>
                
                        <Link to="#" className="text-danger" id="delete1"><Button color="danger" size="sm" className="font-weight-bold p-2">Remove
                        </Button></Link>
                </>
              },
              {
                billingName: "Jimmy Barker",
                total: "veer@joinnextmed.com",
                action : <>
                
                        <Link to="#" className="text-danger" id="delete1"><Button color="danger" size="sm" className="font-weight-bold p-2">Remove
                            
                        </Button></Link>
                            
                </>
              },
            ]*/
        }
        return (
            <React.Fragment>
                    <Container fluid>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="pt-0">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            <NavItem>
                                                <NavLink onClick={() => { this.setState({add_doctor_modal: true}); }} className="font-weight-bold p-3"><Button color="primary" style={{marginLeft: -15}}><i className="mdi mdi-plus mr-2"></i>Add</Button></NavLink>
                                            </NavItem>
                                            {/* <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('2'); }} className={classnames({ active: this.state.activeTab === '2' }, "p-3 font-weight-bold")}>Active</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('3'); }} className={classnames({ active: this.state.activeTab === '3' }, " p-3 font-weight-bold")}>Unpaid</NavLink>
                                            </NavItem> */}
                                        </Nav>
                                        <MDBDataTable responsive data={data} className="mt-4" />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container> 

                    <Modal
                        isOpen={this.state.add_doctor_modal}
                        toggle={this.tog_static}
                        backdrop="static"
                        centered
                        size="lg"
                        
                    >
                        <ModalHeader toggle={() => this.setState({ add_doctor_modal: false })}>
                            Add Member
                        </ModalHeader>
                        <ModalBody>
                            <AvForm style={{ marginLeft: "3.5vh" }} onValidSubmit={this.handleAddDoctor}>
                                <Row>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label htmlFor="email">Name</Label>
                                            <AvField
                                                name="name"
                                                placeholder="John Appleseed"
                                                type="text"
                                                onChange={event => {
                                                    this.setState({ "doctor_name": event.target.value });
                                                }}
                                                className="form-control"
                                                validate={{ 
                                                    required: { value: true, errorMessage: 'Please enter the full name'},
                                                }}
                                                autoComplete="none"
                                                value = {this.state.doctor_name}
                                                id="validationCustom01"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label htmlFor="email">Email</Label>
                                            <AvField
                                                name="email"
                                                placeholder="example@gmail.com"
                                                type="text"
                                                onChange={event => {
                                                    this.setState({ "doctor_email": event.target.value });
                                                }}
                                                className="form-control"
                                                validate={{ 
                                                    required: { value: true, errorMessage: 'Please enter an email'},
                                                    email: {value: true, errorMessage: 'Please enter a valid email'}
                                                }}
                                                autoComplete="new-password"
                                                value = {this.state.doctor_email}
                                                id="validationCustom01"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label htmlFor="email">Type</Label>
                                            <Select
                                                styles={{
                                                    menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                    }),
                                                }}
                                                menuPortalTarget={document.body}
                                                value={this.state.selectedType}
                                                onChange={this.handleSelectedType}
                                                options={this.state.typeOptions}
                                                classNamePrefix="select2-selection"
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row><br /></Row>
                                <ModalFooter>
                                        <Button color="primary">Add</Button>
                                </ModalFooter>

                            </AvForm>

                        </ModalBody>
                    </Modal>

                    <Modal
                        isOpen={this.state.delete_doctor_modal}
                        toggle={this.tog_static}
                        backdrop="static"
                        centered
                        size="md"
                        
                    >
                        <ModalHeader toggle={() => this.setState({ delete_doctor_modal: false })}>
                            <p style={{fontSize: 16}}>Are you sure you want to remove {this.state.delete_doctor_name}?</p>
                        </ModalHeader>
                        <ModalFooter>
                            <Button color="danger" onClick={() => this.handleDeleteDoctor(this.state.delete_doctor_email)}>Remove</Button>
                        </ModalFooter>
                    </Modal>
            </React.Fragment>
        );
    }
}

export default DoctorsList;