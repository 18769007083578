import React, { Component } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Account } from "./pages/Authentication/components/Accounts";

import Login from "./pages/Authentication/components/Login";
import SetPassword from "./pages/Authentication/components/SetPassword";
import Logout from "./pages/Authentication/components/Status";

import Register from "./pages/Authentication/components/Signup";
import ForgetPwd from "./pages/Authentication/components/ForgotPassword";
import Dashboard from "./pages/Dashboard/index";
import Calendar from "./pages/Calendar/Calendar";
import Orders from "./pages/Ecommerce/Orders";
import Patients from "./pages/Ecommerce/Patients";
import Wizard from "./pages/Forms/FormWizard";
import Admin from "./pages/Dashboard/Admin";
import Error404 from "./pages/Utility/Error404"
import Feedback from "./pages/Ui/UiCards"

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import { Redirect } from "react-router-dom";

// Import scss
import "./theme.scss";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getLayout = this.getLayout.bind(this);
    }

    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        const Layout = this.getLayout();

        return (
            <React.Fragment>
				<Router>
					<Account>
						<Switch>

							<Route path="/">
								<NonAuthLayout>
									<Feedback />
								</NonAuthLayout>
							</Route>

							{/* <Route path="/">
								<Redirect to= "/"/>
							</Route> */}
						
							<Route component={Error404} />
						</Switch>
					</Account>
				</Router>
			</React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layout: state.Layout,
    };
};

export default connect(mapStateToProps, null)(App);