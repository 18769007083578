import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import DoctorsList from "./DoctorsList";
import LinkBankAccount from "./LinkBankAccount";
import Biller from "./Biller";


import {
    Elements, 
    // CardElement, 
    // useStripe
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            reports: [
                {
                    icon: "ri-stack-line",
                    title: "Number of Sales",
                    value: "1452",
                    rate: "2.4%",
                    desc: "From previous period",
                },
                {
                    icon: "ri-store-2-line",
                    title: "Sales Revenue",
                    value: "$ 38452",
                    rate: "2.4%",
                    desc: "From previous period",
                },
                {
                    icon: "ri-briefcase-4-line",
                    title: "Average Price",
                    value: "$ 15.4",
                    rate: "2.4%",
                    desc: "From previous period",
                },
            ],
        };
    }

    render() {
        return (
            <Elements stripe={stripePromise}>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Admin"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />
                        {/* <Row>
                            <Col xl={8}>
                                <Row>
                                    <MiniWidgets reports={this.state.reports} />
                                </Row>
                                
                                <RevenueAnalytics/>
                            </Col>

                            <Col xl={4}>

                                <SalesAnalytics/>

                                <EarningReports/>

                            </Col>
                        </Row> */}

                        <Row>
                            <Col xl={12}>
                                <DoctorsList />
                            </Col>

                            {/* sources */}
                            {/* <Sources/> */}

                            {/* recent activity */}
                            {/* <RecentlyActivity/> */}

                            {/* revenue by locations */}
                            {/* <RevenueByLocations/> */}
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <Biller />
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <LinkBankAccount />
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
            </Elements>
        );
    }
}

export default Admin;
